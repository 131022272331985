import { PROCESS_TO_REMOTE_PROCESS_MAPPING } from '@/components/the-big-ones/ServicePlayer/constants';
import { time } from '@/helpers';
import { GenerateVideoImagePayload } from '@/helpers/functions';
import { findNotDisabledValue } from './helpers';

import PayloadMiddleware from './PayloadMiddleware';

interface Form {
  currentTime: number;

  quality: Quality;
  withDetections: boolean;
}

export class VideoImagePayloadMiddleware extends PayloadMiddleware {
  static DISABLED_QUALITIES = new Set([1080]);
  static GUARANTEED_QUALITY = 720;

  static DEFAULT_WITH_DETECTIONS = false;

  form: Form;

  constructor(
    service: PayloadMiddleware['service'],
    dateRange: PayloadMiddleware['dateRange'],
    playerState: PayloadMiddleware['playerState'],

    form: Partial<Form> = {}
  ) {
    super(service, dateRange, playerState);

    this.form = {
      currentTime: form.currentTime || playerState.currentTime,

      quality:
        form.quality ||
        findNotDisabledValue(
          playerState.quality,
          playerState.qualities,
          VideoImagePayloadMiddleware.DISABLED_QUALITIES,
          VideoImagePayloadMiddleware.GUARANTEED_QUALITY
        ),
      withDetections:
        form.withDetections ||
        VideoImagePayloadMiddleware.DEFAULT_WITH_DETECTIONS,
    };
  }

  buildPayload(): GenerateVideoImagePayload {
    const process = this.form.withDetections
      ? PROCESS_TO_REMOTE_PROCESS_MAPPING.default
      : PROCESS_TO_REMOTE_PROCESS_MAPPING.default;

    return {
      serviceId: this.service.id,
      dateRange: time.getDateRangeAsRemoteDateRange(this.dateRange),
      currentTime: this.form.currentTime,

      process,

      quality: this.form.quality,
    };
  }
}

export default VideoImagePayloadMiddleware;
