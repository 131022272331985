<template>
  <div class="range-label d-flex-r d-flex-a-c h-spacing-1">
    <EButton icon md @click="$emit('left-click', $event)">arrow-left</EButton>

    <slot />

    <EButton icon md @click="$emit('right-click', $event)">arrow-right</EButton>
  </div>
</template>

<script>
export default {
  name: 'RangeLabel',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$default-height: 32px

.range-label
  overflow: hidden

  +h-rounded($default-height)

// color
.range-label
  background-color: rgba($c-primary, .1)
</style>
