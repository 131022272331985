import firebase from 'firebase';
import { REMOTE_GRANULARITY_TO_FORMAT_MAPPING } from '@/granularity';
import { GroupByField } from './helpers';

type Type = 'duration' | 'hourOfDay' | 'number';

export class MetricDocQuerier {
  static FIELD_REGEXP = /(\.(\w+))$/g;

  aggregation: RemoteAggregation;
  field: string;

  type?: Type;

  constructor(aggregation: RemoteAggregation, field: string) {
    this.aggregation = aggregation;
    this.field = field;

    this.type = MetricDocQuerier.getTypeFromField(field);
  }

  buildPath(remoteGranularity: RemoteGranularity) {
    return remoteGranularity === 'RAW'
      ? this.field.toLowerCase()
      : `${this.aggregation}_${this.field}`.toLowerCase();
  }

  buildDocQuerier(
    remoteGranularity: RemoteGranularity,
    groupByField?: GroupByField
  ) {
    const DEFAULT_Y = 0;
    const DEFAULT_Y_GROUP = undefined;

    const xFormat = REMOTE_GRANULARITY_TO_FORMAT_MAPPING[remoteGranularity];
    const xFormatter = (string: string) => moment(string, xFormat, 'en');

    const path = this.buildPath(remoteGranularity);

    return (doc: firebase.firestore.QueryDocumentSnapshot) => {
      const docData = doc.data();

      let x: Timestamp | undefined = xFormatter(doc.id);
      if (!x.isValid()) x = undefined;
      let y;

      if (groupByField) {
        const yGroup = docData[groupByField];

        y = yGroup
          ? _.mapValues(yGroup, docData => _.get(docData, path))
          : DEFAULT_Y_GROUP;
      } else {
        y = _.get(docData, path);

        if (this.type === 'hourOfDay') {
          const startOfDay = moment(y).startOf('day');

          y = moment.duration(moment(y).diff(startOfDay)).asHours();
        }

        y = y || DEFAULT_Y;
      }

      return { x, y };
    };
  }

  static isHourOfDayProperty(value: string) {
    return value === 'start' || value === 'finish';
  }

  static isDurationProperty(value: string) {
    return value === 'duration';
  }

  static getTypeFromField(field: string) {
    const result = MetricDocQuerier.FIELD_REGEXP.exec(field);
    MetricDocQuerier.FIELD_REGEXP.lastIndex = 0;

    if (_.isNull(result)) return 'number';
    const property = result[2];
    if (_.isNil(property)) return;

    if (MetricDocQuerier.isDurationProperty(property)) return 'duration';
    if (MetricDocQuerier.isHourOfDayProperty(property)) return 'hourOfDay';
  }
}

export default MetricDocQuerier;
