import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('download', ['payload']),

    qualitiesAsOptions() {
      return _.map(this.payload.playerState.qualities, quality => ({
        text: `${quality}p`,
        value: quality,
      }));
    },
    playbackSpeedsAsOptions() {
      return _.map(this.payload.playerState.playbackSpeeds, playbackSpeed => ({
        text: playbackSpeed !== 1 ? `${playbackSpeed}x` : 'Normal',
        value: playbackSpeed,
      }));
    },
  },
  methods: {
    ...mapMutations('download', {
      setPlaybackSpeed: 'set-playback-speed',
      setQuality: 'set-quality',
      setWithDetections: 'set-with-detections',
    }),
  },
};
