<template>
  <div :style="indicatorStyle" :class="className">
    <div
      v-for="(tab, index) in tabs"
      :key="tab.key || tab.text"
      ref="tabs"
      :class="tabClassName(index)"
      @click="onTabClick(index)"
    >
      <icon v-if="tab.icon" :size="20" class="mr-2">{{ tab.icon }}</icon>
      <t sm>{{ tab.text }}</t>
    </div>

    <ResizeObserver @notify="updateMeasurement" />
  </div>
</template>

<script>
export default {
  name: 'TabsHeader',
  model: { prop: 'activeTab', event: 'active-tab-change' },
  props: {
    tabs: { type: Array, required: true },
    activeTab: { type: Number, required: true },

    rounded: { type: Boolean, default: true },
  },
  data() {
    return {
      rects: {
        colEl: { width: 0, left: 0 },
        el: { left: 0 },
      },

      isMounted: false,
    };
  },
  computed: {
    indicatorStyle() {
      if (!this.isMounted) return;

      const { colEl, el } = this.rects;

      return {
        '--width': `${colEl.width}px`,
        '--x': `${colEl.left - el.left - 4}px`,
      };
    },
    className() {
      const className = 'tabs-header';

      return [className, { [`${className}--rounded`]: this.rounded }];
    },
  },
  watch: {
    activeTab() {
      // <activeTab> can change before <$refs> do, leading to out of range
      // errors

      this.$nextTick(this.updateMeasurement);
    },
  },
  mounted() {
    this.updateMeasurement();

    this.isMounted = true;
  },
  updated() {
    this.updateMeasurement();
  },
  methods: {
    updateMeasurement() {
      const colEl = this.$refs.tabs[this.activeTab];
      const colElRect = colEl.getBoundingClientRect();
      const el = this.$el;
      const elRect = el.getBoundingClientRect();

      this.rects.colEl.left = colElRect.left;
      this.rects.colEl.width = colElRect.width;
      this.rects.el.left = elRect.left;
    },

    isActiveTab(index) {
      return index === this.activeTab;
    },

    onTabClick(index) {
      if (this.isActiveTab(index)) return;

      this.$emit('active-tab-change', index);
    },

    tabClassName(index) {
      const className = 'tabs-header__tab';

      return [
        className,
        {
          [`${className}--active`]: this.isActiveTab(index),
          [`${className}--with-icon`]: Boolean(this.tabs[index].icon),
        },
      ];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$padding: 4px

.tabs-header
  position: relative

  +d-flex-r(center, center, inline)

.tabs-header::before
  content: ''

  position: absolute
  left: $padding
  z-index: $z-index-base

  width: var(--width)

  box-shadow: $elevation-1

  transform: translateX(var(--x))

  transition: transform, width
  transition-duration: $duration-normal
  transition-timing-function: $ease-out-quint

.tabs-header__tab
  z-index: #{$z-index-base + 1}

  +button
  +d-flex-r(center)

+media-down(md)
  .tabs-header__tab > .e-icon
    display: none

+media-up(md)
  .tabs-header__tab--with-icon
    padding-left: $spacing-2

// - not rounded
.tabs-header:not(.tabs-header--rounded)
  $height: 32px

  +wrapper($height, $padding, false)

  &::before
    height: $height - $padding * 2

    border-radius: $b-radius-2

  .tabs-header__tab
    height: $height - $padding * 2

    +h-padding(($height - $padding * 2) / 2)

    & > .e-text
      font-weight: $f-weight-medium

// - rounded
.tabs-header--rounded
  $height: 40px

  +wrapper($height, $padding)

  &::before
    +h-rounded($height - $padding * 2)

  .tabs-header__tab
    +h-rounded($height - $padding * 2, true)

    & > .e-text
      font-weight: $f-weight-semibold

// color
.tabs-header::before
  background-color: var(--c-white)

.tabs-header__tab
  & > .e-icon,
  & > .e-text
    --color: var(--c-gray-2)

  +on-active
    & > .e-icon,
    & > .e-text
      --color: var(--c-primary)
</style>
