type Task = 'between' | 'per';
type TaskPayload = Aggregation | undefined;

type TaskCallback = (task: Task, payload: TaskPayload) => any;

export class Pipeline {
  static DESCRIPTOR_SEPARATOR = '|';
  static TASK_SEPARATOR = ':';

  descriptor: string;

  constructor(descriptor: Pipeline['descriptor']) {
    this.descriptor = descriptor;
  }

  forEach(callback: TaskCallback) {
    Pipeline.parseDescriptor(this.descriptor).forEach(task => {
      const [taskKey, taskPayload] = Pipeline.parseTask(task);
      if (taskKey.length === 0) return;

      callback(taskKey, taskPayload);
    });
  }

  static parseDescriptor = (descriptor: Pipeline['descriptor']) =>
    <string[]>_.split(descriptor, Pipeline.DESCRIPTOR_SEPARATOR);

  static parseTask = (task: string): [Task, TaskPayload] =>
    <[Task, TaskPayload]>_.split(task, Pipeline.TASK_SEPARATOR);
}

export default Pipeline;
