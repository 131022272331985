export const VARIANTS = {
  person: {
    className: 'person',

    kpiLabel: Object.freeze({
      es: 'Personas',
      en: 'People',
    }),
    legendLabel: Object.freeze({
      in: Object.freeze({
        es: 'Personas entrando',
        en: 'Entering people',
      }),
      out: Object.freeze({
        es: 'Personas saliendo',
        en: 'Leaving people',
      }),
    }),
  },

  machine: {
    className: 'truck',

    kpiLabel: Object.freeze({
      es: 'Camiones',
      en: 'Trucks',
    }),
    legendLabel: Object.freeze({
      in: Object.freeze({
        es: 'Camiones entrando',
        en: 'Entering trucks',
      }),
      out: Object.freeze({
        es: 'Camiones saliendo',
        en: 'Leaving trucks',
      }),
    }),
  },
};
