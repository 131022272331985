import { PROCESS_TO_REMOTE_PROCESS_MAPPING } from '@/components/the-big-ones/ServicePlayer/constants';
import { time } from '@/helpers';
import { GenerateVideoPayload } from '@/helpers/functions';
import { findNotDisabledValue } from './helpers';

import PayloadMiddleware from './PayloadMiddleware';

interface Form {
  type: 0 | 1;

  media: Media;

  date: Timestamp;
  timeRange: TRange;

  quality: Quality;
  playbackSpeed: PlaybackSpeed;
  withDetections: boolean;
}

export class VideoPayloadMiddleware extends PayloadMiddleware {
  static DISABLED_PLAYBACK_SPEEDS = new Set([]);
  static DISABLED_QUALITIES = new Set([1080]);

  static GUARANTEED_PLAYBACK_SPEED = 1;
  static GUARANTEED_QUALITY = 720;

  static DEFAULT_MEDIA: Media = 'mp4';
  static DEFAULT_TIME_RANGE: TRange = [0, 60 * 24 - 1];
  static DEFAULT_TYPE: Form['type'] = 0;
  static DEFAULT_WITH_DETECTIONS = true;

  form: Form;

  constructor(
    service: PayloadMiddleware['service'],
    dateRange: PayloadMiddleware['dateRange'],
    playerState: PayloadMiddleware['playerState'],

    form: Partial<Form> = {}
  ) {
    super(service, dateRange, playerState);

    this.form = {
      type: form.type || VideoPayloadMiddleware.DEFAULT_TYPE,

      media: form.media || VideoPayloadMiddleware.DEFAULT_MEDIA,

      date: form.date || dateRange.from,
      timeRange: form.timeRange || VideoPayloadMiddleware.DEFAULT_TIME_RANGE,

      quality:
        form.quality ||
        findNotDisabledValue(
          playerState.quality,
          playerState.qualities,
          VideoPayloadMiddleware.DISABLED_QUALITIES,
          VideoPayloadMiddleware.GUARANTEED_QUALITY
        ),
      playbackSpeed:
        form.playbackSpeed ||
        findNotDisabledValue(
          playerState.playbackSpeed,
          playerState.playbackSpeeds,
          VideoPayloadMiddleware.DISABLED_PLAYBACK_SPEEDS,
          VideoPayloadMiddleware.GUARANTEED_PLAYBACK_SPEED
        ),
      withDetections:
        form.withDetections || VideoPayloadMiddleware.DEFAULT_WITH_DETECTIONS,
    };
  }

  buildOriginalPayload() {
    const ORIGINAL: true = true;

    const { service, form } = this;
    const { date, timeRange } = form;

    const dateRange: RemoteDateRange = [
      moment(date)
        // @ts-ignore
        .tz(service.timezone, true)
        .add(timeRange[0], 'minutes')
        .format(),
      moment(date)
        // @ts-ignore
        .tz(service.timezone, true)
        .add(timeRange[1], 'minutes')
        .format(),
    ];

    return {
      serviceId: service.id,
      dateRange,

      process: PROCESS_TO_REMOTE_PROCESS_MAPPING.original,

      playbackSpeed: form.playbackSpeed,
      media: form.media,

      options: {
        original: ORIGINAL,
        original_step_unit: service.typeSettings?.original.unit || 'm',
        original_step_value: service.typeSettings?.original.duration || 1,

        timezone: service.timezone,

        ...service.typeSettings?.original,
      },
    };
  }

  buildTimelapsePayload() {
    const { service, form } = this;

    const process = form.withDetections
      ? PROCESS_TO_REMOTE_PROCESS_MAPPING.default
      : PROCESS_TO_REMOTE_PROCESS_MAPPING.withoutDetections;

    return {
      serviceId: service.id,
      dateRange: time.getDateRangeAsRemoteDateRange(this.dateRange),

      process,

      quality: form.quality,
      playbackSpeed: form.playbackSpeed,
      media: form.media,
    };
  }

  buildPayload(): GenerateVideoPayload {
    return this.form.type === 0
      ? this.buildTimelapsePayload()
      : this.buildOriginalPayload();
  }
}

export default VideoPayloadMiddleware;
