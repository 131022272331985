import i18n from '@/i18n';

declare global {
  type Granularity = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

  type RemoteGranularity =
    | 'MINUTELY'
    | 'HOURLY'
    | 'DAILY'
    | 'WEEKLY'
    | 'MONTHLY'
    | 'QUARTERLY'
    | 'YEARLY'
    | 'RAW'
    | 'TEN-MINUTELY';

  type PUnit =
    | 'seconds'
    | 'minutes'
    | 'hours'
    | 'days'
    | 'weeks'
    | 'months'
    | 'quarters'
    | 'years';

  type SUnit =
    | 'second'
    | 'minute'
    | 'hour'
    | 'day'
    | 'week'
    | 'month'
    | 'quarter'
    | 'year';
}

export const GRANULARITIES_AS_OPTIONS = Object.freeze([
  { text: i18n.tc('c.data-cell.minutely'), value: 0 }, // minutely
  { text: i18n.tc('c.data-cell.ten-minutely'), value: 8 }, // ten-minutely
  { text: i18n.tc('c.data-cell.hourly'), value: 1 }, // hourly
  { text: i18n.tc('c.data-cell.daily'), value: 2 }, // daily
  { text: i18n.tc('c.data-cell.weekly'), value: 3 }, // weekly
  { text: i18n.tc('c.data-cell.monthly'), value: 4 }, // monthly
  { text: i18n.tc('c.data-cell.quarterly'), value: 5 }, // quarterly
  { text: i18n.tc('c.data-cell.yearly'), value: 6 }, // yearly
  { text: i18n.tc('c.data-cell.raw'), value: 7 }, // raw
]);

export const granularitiesComparer = (a: Granularity, b: Granularity) => {
  if (a === 8 && b === 8) {
    a = 1;
    b = 1;
  } else if (a === 8 && b !== 8) {
    a = 1;

    if (b === 1) b = 2;
  } else if (a !== 8 && b === 8) {
    b = 1;

    if (a === 1) a = 2;
  }

  if (a === 7) return 1;
  if (b === 7) return -1;

  return b - a;
};

// START - granularity ---------------------------------------------------------
const GRANULARITIES: Set<Granularity> = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8]);

export const GRANULARITY_TO_REMOTE_GRANULARITY_MAPPING: Record<
  Granularity,
  RemoteGranularity
> = Object.freeze({
  0: 'MINUTELY',
  1: 'HOURLY',
  2: 'DAILY',
  3: 'WEEKLY',
  4: 'MONTHLY',
  5: 'QUARTERLY',
  6: 'YEARLY',
  7: 'RAW',
  8: 'TEN-MINUTELY',
});

export const GRANULARITY_TO_FORMAT_MAPPING: Record<
  Granularity,
  string
> = Object.freeze({
  0: 'hh:mm A', // minutely
  1: 'hh A', // hourly
  2: 'DD MMM YYYY', // daily
  3: 'YYYY-WW', // weekly
  4: 'YYYY-MM', // monthly
  5: 'YYYY-QQ', // quarterly
  6: 'YYYY', // yearly
  7: 'YYYY-MM-DD-HH-mm-ss-SSSSSS', // raw
  8: 'hh:mm A', // ten-minutely
});

export const GRANULARITY_TO_UNIT_MAPPING: Record<
  Granularity,
  SUnit
> = Object.freeze({
  0: 'minute', // minutely
  1: 'hour', // hourly
  2: 'day', // daily
  3: 'week', // weekly
  4: 'month', // monthly
  5: 'quarter', // quarterly
  6: 'year', // yearly
  7: 'minute', // raw
  8: 'minute', // ten-minutely
});

export const isGranularity = (value: any): value is Granularity =>
  GRANULARITIES.has(value);

export const asGranularity = (remoteGranularity: RemoteGranularity) =>
  REMOTE_GRANULARITY_TO_GRANULARITY_MAPPING[remoteGranularity];
// END - granularity -----------------------------------------------------------

// START - remote granularity --------------------------------------------------
const REMOTE_GRANULARITIES: Set<RemoteGranularity> = new Set([
  'MINUTELY',
  'HOURLY',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
  'RAW',
  'TEN-MINUTELY',
]);

export const REMOTE_GRANULARITY_TO_GRANULARITY_MAPPING: Record<
  RemoteGranularity,
  Granularity
> = Object.freeze({
  MINUTELY: 0,
  HOURLY: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
  QUARTERLY: 5,
  YEARLY: 6,
  RAW: 7,
  'TEN-MINUTELY': 8,
});

export const REMOTE_GRANULARITY_TO_FORMAT_MAPPING: Record<
  RemoteGranularity,
  string
> = Object.freeze({
  MINUTELY: 'YYYY-MM-DD-HH-mm',
  HOURLY: 'YYYY-MM-DD-HH',
  DAILY: 'YYYY-MM-DD',
  WEEKLY: 'YYYY-WW',
  MONTHLY: 'YYYY-MM',
  QUARTERLY: 'YYYY-QQ',
  YEARLY: 'YYYY',
  RAW: 'YYYY-MM-DD-HH-mm-ss-SSSSSS',
  'TEN-MINUTELY': 'YYYY-MM-DD-HH-mm',
});

export const REMOTE_GRANULARITY_TO_UNIT_MAPPING: Record<
  RemoteGranularity,
  PUnit
> = Object.freeze({
  MINUTELY: 'minutes',
  HOURLY: 'hours',
  DAILY: 'days',
  WEEKLY: 'weeks',
  MONTHLY: 'months',
  QUARTERLY: 'months',
  YEARLY: 'years',
  RAW: 'minutes',
  'TEN-MINUTELY': 'minutes',
});

export const isRemoteGranularity = (value: any): value is RemoteGranularity =>
  REMOTE_GRANULARITIES.has(value);

export const asRemoteGranularity = (granularity: Granularity) =>
  GRANULARITY_TO_REMOTE_GRANULARITY_MAPPING[granularity];
// END - remote granularity ----------------------------------------------------

export const asFormat = (value: any) => {
  if (isGranularity(value)) {
    return GRANULARITY_TO_FORMAT_MAPPING[value];
  }
  if (isRemoteGranularity(value)) {
    return REMOTE_GRANULARITY_TO_FORMAT_MAPPING[value];
  }

  return undefined;
};
