export default [
  {
    path: '/services',
    name: 'services-index',
    component: () =>
      import(
        /* webpackChunkName: "services-index" */ '../../views/services/index.vue'
      ),
    meta: {
      authenticated: true,
      layout: 'authenticated',
    },
  },
];
