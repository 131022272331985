import { STATUS } from '@/API/preprocessor/constants.ts';

export default {
  remoteUser(remoteUser: RemoteUser) {
    return Boolean(remoteUser);
  },

  remoteClient(remoteClient: RemoteClient) {
    return Boolean(remoteClient);
  },

  remoteProject(remoteProject: RemoteProject) {
    return Boolean(remoteProject);
  },

  remoteService(remoteService: RemoteService) {
    return (
      Boolean(remoteService) &&
      Boolean(remoteService.project_id) &&
      remoteService.status !== STATUS.deleted
    );
  },

  remoteManifest(remoteManifest: RemoteManifest) {
    return Boolean(remoteManifest);
  },

  remoteNotification(remoteNotification: RemoteNotification) {
    return Boolean(remoteNotification);
  },

  remoteTimestamp(remoteTimestamp: RemoteTimestamp) {
    return Boolean(remoteTimestamp);
  },
};
