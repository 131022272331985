import {
  AREAS_KEY,
  AREAS_TEMPLATES_KEY,
  TABS_KEY,
  TITLE_KEY,
} from './constants';

export const isPresetManifest = manifest => manifest && manifest['preset'];

export const isSingleManifest = manifest =>
  manifest &&
  manifest[TITLE_KEY] &&
  !manifest[AREAS_KEY] &&
  !manifest[AREAS_TEMPLATES_KEY] &&
  !manifest[TABS_KEY];

export const isCellManifest = manifest =>
  manifest &&
  !manifest[TITLE_KEY] &&
  !manifest[AREAS_KEY] &&
  !manifest[AREAS_TEMPLATES_KEY] &&
  !manifest[TABS_KEY];

export const isGridManifest = manifest =>
  manifest &&
  manifest[AREAS_KEY] &&
  manifest[AREAS_TEMPLATES_KEY] &&
  !manifest[TABS_KEY];

export const isTabsManifest = manifest =>
  manifest &&
  manifest[AREAS_KEY] &&
  !manifest[AREAS_TEMPLATES_KEY] &&
  (_.isArray(manifest[AREAS_KEY]) || manifest[TABS_KEY]);

export const isDashboardManifest = manifest => isGridManifest(manifest);
