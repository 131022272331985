import store from '@/store';
import API from '@/API';

const routes = [
  {
    path: '/projects',
    component: () =>
      import(
        /* webpackChunkName: "collection-index" */ '../../views/collection/index.vue'
      ),
    children: [
      {
        path: '/',
        name: 'projects-index',
        component: () =>
          import(
            /* webpackChunkName: "projects-index" */ '../../views/projects/index.vue'
          ),
        children: [
          {
            path: '/projects/:projectId',
            component: () =>
              import(
                /* webpackChunkName: "projects-show" */ '../../views/projects/show/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'projects-show',
                component: () =>
                  import(
                    /* webpackChunkName: "projects-show-summary" */ '../../views/projects/show/summary.vue'
                  ),
                meta: {
                  hidden: {
                    collection: { mobile: true },
                    map: { mobile: true },
                  },

                  alignment: 'left',
                  flex: 1.5,
                },
              },
              {
                path: 'services',
                component: () =>
                  import(
                    /* webpackChunkName: "projects-show-services-index" */ '../../views/projects/show/services/index.vue'
                  ),
                children: [
                  {
                    path: '',
                    name: 'projects-show-services-index',
                    component: () =>
                      import(
                        /* webpackChunkName: "projects-show-services-index-map" */ '../../views/projects/show/services/map.vue'
                      ),
                  },
                  {
                    path: 'grid',
                    name: 'projects-show-services-index-grid',
                    component: () =>
                      import(
                        /* webpackChunkName: "projects-show-services-index-grid" */ '../../views/projects/show/services/grid.vue'
                      ),
                    meta: {
                      hidden: {
                        map: { mobile: true },
                      },
                    },
                  },
                  {
                    path: ':serviceId',
                    name: 'projects-show-services-show',
                    component: () =>
                      import(
                        /* webpackChunkName: "projects-show-services-show" */ '../../views/projects/show/services/show.vue'
                      ),
                    meta: {
                      hidden: {
                        collection: { mobile: true },
                        map: { mobile: true },
                        controls: { forecast: true },
                      },

                      alignment: 'left',
                      flex: 3,

                      parent: 'projects-show',
                    },
                  },
                ],
                meta: {
                  hidden: {
                    controls: { forecast: true },
                  },
                },
              },
            ],
            meta: {
              parent: 'projects-index',
            },
          },
        ],
      },
    ],
    meta: {
      authenticated: true,
      layout: 'authenticated',
    },
  },
];

export const guards = {
  fetch: {
    async project({ projectId }) {
      let project = store.getters['collections/getProjectById'](projectId);
      // the project may not have been fetched yet
      if (!project) project = await API.project(projectId);

      return project;
    },

    async viewer() {
      let viewer = store.getters['collections/viewer'];
      // the viewer may not have been fetched yet
      if (!viewer) {
        const firebaseUser = store.state.authentication.user;

        if (firebaseUser) viewer = await API.user(firebaseUser.uid);
      }

      return viewer;
    },
  },

  index: {
    async beforeEnter(to, from, next) {
      const viewer = await guards.fetch.viewer();
      if (!viewer) return next();
      const { projectsIds } = viewer;
      if (projectsIds.length !== 1) return next();

      const backward = !_.isUndefined(to.query.backward) && to.query.backward;
      const forward = _.isUndefined(to.query.forward) || to.query.forward;

      if (!backward && forward) {
        const REDIRECT_ROUTE_NAME = 'projects-show';

        next({
          name: REDIRECT_ROUTE_NAME,
          params: { projectId: projectsIds[0] },
        });
      } else next();
    },
  },

  show: {
    async beforeEnter(to, from, next) {
      const project = await guards.fetch.project(to.params);
      if (!project) return next();
      const { manifest } = project;
      if (manifest && manifest.dashboard) return next();

      const backward = !_.isUndefined(to.query.backward) && to.query.backward;
      const forward = _.isUndefined(to.query.forward) || to.query.forward;

      if (!backward && forward) {
        const REDIRECT_ROUTE_NAME = 'projects-show-services-index';

        return next({
          name: REDIRECT_ROUTE_NAME,
          params: { projectId: project.id },
        });
      } else {
        next({ name: 'projects-index', query: { backward: true } });
      }
    },
  },

  services: {
    index: {
      async beforeEnter(to, from, next) {
        const project = await guards.fetch.project(to.params);
        if (!project) return next();
        const { servicesIds } = project;
        if (servicesIds.length !== 1) return next();

        const backward = !_.isUndefined(to.query.backward) && to.query.backward;
        const forward = _.isUndefined(to.query.forward) || to.query.forward;

        if (!backward && forward) {
          const REDIRECT_ROUTE_NAME = 'projects-show-services-show';

          return next({
            name: REDIRECT_ROUTE_NAME,
            params: { projectId: project.id, serviceId: servicesIds[0] },
          });
        } else {
          return next({
            name: 'projects-show',
            params: { projectId: project.id },
            query: { backward: true },
          });
        }
      },
    },
  },
};

export default routes;
