<template>
  <div :class="className">
    <div class="menu__header">
      <t semibold>{{ title }}</t>

      <div class="spacer" />

      <EButton navigation md @click="closeMenu">menu-close</EButton>
    </div>

    <div
      v-show="isMenuOpen && !isNotificationsOpen"
      class="menu__body v-spacing-3"
    >
      <div class="card card--flat d-flex-r d-flex-a-c p-3">
        <t v-if="client" semibold>{{ client.shortName }}</t>

        <div class="spacer" />

        <EButton navigation md disabled>cogwheel</EButton>
      </div>

      <MenuProjects class="card card--flat" />

      <MenuNotifications class="card card--flat p-3" />

      <MenuSignOut class="card card--flat p-3" />
    </div>

    <div v-show="isNotificationsOpen" class="menu__notifications">
      <NotificationsBody />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

// components
import MenuNotifications from './MenuNotifications.vue';
import MenuProjects from './MenuProjects.vue';
import MenuSignOut from './MenuSignOut.vue';
import NotificationsBody from '@/components/ui/NotificationsDrawer/Body/index.vue';

export default {
  name: 'Notifications',
  components: {
    MenuNotifications,
    MenuProjects,
    MenuSignOut,
    NotificationsBody,
  },
  computed: {
    ...mapState('ui', {
      isMenuOpen: state => state.menu.isOpen,
      isNotificationsOpen: state => state.notifications.isOpen,
    }),
    ...mapGetters('collections', ['client']),

    title() {
      if (this.isNotificationsOpen) return this.$t('c.menu.notifications');
      if (this.isMenuOpen) return this.$t('c.menu.menu');

      return '';
    },

    className() {
      const className = 'menu';

      return [
        className,
        {
          [`${className}--notifications-open`]: this.isNotificationsOpen,
          [`${className}--open`]: this.isMenuOpen,
        },
      ];
    },
  },
  methods: {
    ...mapActions('ui', ['closeMenu']),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$padding: $spacing-3

.menu
  transform: translateY(-100%)

  transition: transform $duration-normal $ease-in-out-quint

  +p-absolute($z-index-left-drawer)
  +shell-top(0)
  +size(100%)

  &--open
    transform: translateX(0%)

.menu__header
  height: $header-height
  padding-right: $padding
  padding-left: $padding * 2

  +d-flex-r(center)

// START - menu ----------------------------------------------------------------
.menu__body
  max-height: calc(100% - #{$header-height})

  +d-flex-c
  +padding-shell-bottom($padding)

  & > *
    flex-shrink: 0

  .menu-projects
    flex: 1

    overflow-y: auto
// END - menu ------------------------------------------------------------------

// START - notifications -------------------------------------------------------
.menu__notifications
  height: calc(100% - #{$header-height})

  overflow-y: auto
// END - notifications ---------------------------------------------------------

// color
.menu
  background-color: #F4F4F4 // FIX

.menu--notifications-open
  background-color: var(--c-white)
</style>
