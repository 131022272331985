export default {
  props: {
    size: { type: Number, default: undefined },
  },
  computed: {
    withSizeStyle() {
      return Object.assign({}, this.size && { '--size': `${this.size}px` });
    },
  },
};
