<template>
  <div
    :class="[
      'formulate-input-element',
      `formulate-input-element--${context.type}`,
    ]"
    :data-type="context.type"
  >
    <EButton
      :disabled="context.attributes.disabled"
      :selected="String(context.model) === context.value"
      v-bind="{ ...$options.buttonBind, ...size }"
      @click="context.model = context.value"
    >
      {{ context.label }}
    </EButton>
  </div>
</template>

<script>
export default {
  props: {
    context: { type: Object, required: true },
  },

  buttonBind: { outlined: true },

  computed: {
    size() {
      return _.pick(this.context.attributes, ['sm', 'md', 'lg']);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$type: 'odd-radio'

.formulate-input[data-type="#{$type}"]
  & > .formulate-input-wrapper > .formulate-input-group
    +d-flex-r(center)
    +h-spacing($spacing-2)

.formulate-input-element[data-type="#{$type}"]
  & + .formulate-input-label
    display: none
</style>
