<template>
  <t color="gray-2" xs class="active-services-label">
    {{ countAsString.active }}
    <template v-if="count.inactive > 0">
      /
      {{ countAsString.inactive }}
    </template>
  </t>
</template>

<script>
export default {
  name: 'ActiveCollectionLabel',
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  computed: {
    count() {
      return {
        active: _.filter(this.services, { status: 'active' }).length,
        inactive: _.filter(this.services, { status: 'inactive' }).length,
      };
    },
    countAsString() {
      return {
        active: this.$tc(`c.active-services-label.active`, this.count.active, [
          this.count.active,
        ]),
        inactive: this.$tc(
          `c.active-services-label.inactive`,
          this.count.inactive,
          [this.count.inactive]
        ),
      };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
