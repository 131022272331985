<template>
  <div class="download-drawer__video">
    <div class="v-spacing-4 p-4">
      <template v-if="hasOriginal">
        <div class="v-spacing-2">
          <FormulateInput
            v-model="form.type"
            type="odd-radio"
            name="type"
            :options="typesAsOptions"
            class="odd-radio--big"
          />
        </div>

        <OriginalForm v-if="form.type === '1'" :date-range="dateRange" />
      </template>

      <TimelapseForm
        v-if="(hasOriginal && form.type === '0') || !hasOriginal"
      />
    </div>

    <FormOverlay
      :status="status"
      @cancel="$emit('cancel')"
      @clear="clear"
      @retry="$emit('retry')"
    >
      <template v-if="status === 'pending'">
        {{ $t(`${$options.path}.message.pending`) }}
      </template>

      <template v-if="status === 'success'">
        {{ $t(`${$options.path}.message.success`) }}
      </template>

      <template v-if="status === 'failure'">
        {{ $t(`${$options.path}.message.failure`) }}
      </template>
    </FormOverlay>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Form from './../Form';
import { WithTime } from '@/mixins';

// components
import OriginalForm from './OriginalForm.vue';
import TimelapseForm from './TimelapseForm.vue';

export default {
  name: 'DownloadDrawerVideo',
  components: { OriginalForm, TimelapseForm },
  mixins: [Form, WithTime],
  data() {
    return {
      TYPES_TO_LABEL_MAPPING: Object.freeze([
        this.$t(`${this.$options.path}.type.timelapse`),
        this.$t(`${this.$options.path}.type.original`),
      ]),
    };
  },
  computed: {
    ...mapState('download', {
      service: state => state.payload.service,

      status: state => state.video.status,
      types: state => state.video.types,

      storeForm: state => ({ type: state.video.payload.type }),
    }),

    dimensionId() {
      return this.$route.params.projectId;
    },

    typesAsOptions() {
      return _.map(this.types, type => {
        const label = this.TYPES_TO_LABEL_MAPPING[type];

        return { id: label, label, value: String(type), lg: true };
      });
    },

    hasOriginal() {
      return _.has(this.service, 'typeSettings.original');
    },
  },
  methods: {
    ...mapMutations('download', {
      clear: 'clear-video',
      setPayload: 'set-video-payload',
    }),

    syncDown() {
      const { storeForm } = this;

      this.form = { type: String(storeForm.type) };
    },
    syncUp() {
      const { form } = this;

      this.setPayload({ type: Number(form.type) });
    },
  },

  path: 'c.download-drawer',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$z-index: $z-index-base

.download-drawer__video
  +p-relative($z-index)

  & > .download-drawer__form-overlay
    z-index: #{$z-index + 2}
</style>
