<template>
  <DoubleRange
    :value="range.value"
    v-bind="{ max, maxDifference, min, minDifference, step, ...$attrs }"
    class="double-range-with-label"
    @dragging-change="onDraggingChange"
    @input="onInput"
  >
    <template v-slot:start>
      <slot name="start" />
    </template>

    <DoubleRangeTooltip
      v-if="isMounted"
      ref="tooltip"
      :placement="placement"
      :target="target"
      :boundary="$el"
      display
    >
      <RangeLabel
        class="double-range-with-label__label"
        @left-click="
          range.value = [range.value[0] - step, range.value[1] - step]
        "
        @right-click="
          range.value = [range.value[0] + step, range.value[1] + step]
        "
      >
        <slot :value="range.value" />
      </RangeLabel>
    </DoubleRangeTooltip>

    <template v-slot:end>
      <slot name="end" />
    </template>
  </DoubleRange>
</template>

<script>
import RangeValue from './DoubleRange/RangeValue.ts';

// components
import DoubleRange from '@/components/formulate/DoubleRange/index.vue';
import DoubleRangeTooltip from '@/components/formulate/Range/Tooltip.vue';
import RangeLabel from '@/components/formulate/RangeLabel.vue';

const RANGE_CLASS_NAME = '.double-range__range';
const RANGE_THUMB_CLASS_NAME = '.range__thumb';

export default {
  name: 'DoubleRangeWithLabel',
  components: { DoubleRange, DoubleRangeTooltip, RangeLabel },
  props: {
    value: { type: Array, required: true },

    placement: { type: String, default: 'top' },
    max: { type: Number, default: 1 },
    maxDifference: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
    minDifference: { type: Number, default: 0 },
    step: { type: Number, default: 0.01 },
  },
  data() {
    return {
      range: new RangeValue(
        this.value,
        this.max,
        this.maxDifference,
        this.min,
        this.minDifference
      ),

      fixedDragging: false,
      difference: NaN,

      isMounted: false,
    };
  },
  computed: {
    target() {
      const left = this.$el.querySelector(`${RANGE_CLASS_NAME}--left`);
      const right = this.$el.querySelector(`${RANGE_CLASS_NAME}--right`);

      return {
        getBoundingClientRect: () => {
          const { width: lw, x: lx, y: ly } = left
            .querySelector(RANGE_THUMB_CLASS_NAME)
            .getBoundingClientRect();
          const { width: rw, x: rx, y: ry } = right
            .querySelector(RANGE_THUMB_CLASS_NAME)
            .getBoundingClientRect();

          const x = (lx + lw / 2 + rx + rw / 2) / 2;
          const y = (ly + ry) / 2;

          return {
            top: y,
            right: x,
            bottom: y,
            left: x,

            width: 0,
            height: 0,
          };
        },
      };
    },
  },
  watch: {
    value(newValue) {
      const { tooltip } = this.$refs;

      this.range.value = newValue;

      if (tooltip) tooltip.update();
    },

    'range.value'() {
      this.$emit('input', this.range.value);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    onDraggingChange({ target }) {
      const CLASS_NAME = '.double-range-with-label';

      this.fixedDragging = Boolean(target && target.closest(CLASS_NAME));
      this.difference = this.value[1] - this.value[0];
    },
    onInput(value) {
      if (this.fixedDragging) {
        const { difference, range } = this;

        range.value = range.sanitize([value[0], value[0] + difference], 'both');
      } else this.range.value = value;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$label-height: 32px

.double-range-with-label
  padding-top: $label-height + 10px // FIX
</style>
