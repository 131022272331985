export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },

    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
  },
};
