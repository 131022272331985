import { asRemoteGranularity } from '@/granularity';
import { time } from '@/helpers';

import DatasetArray from './model/DatasetArray';
import { RemoteDataset } from './model/Dataset';

export default async function(
  { from, to }: DateRange,
  granularity: Granularity,
  payload: { datasets: RemoteDataset | RemoteDataset[] }
) {
  let { datasets: remoteDatasets } = payload;
  if (!_.isArray(remoteDatasets)) remoteDatasets = [remoteDatasets];
  const datasets = DatasetArray.fromRemoteDatasets(remoteDatasets);

  const remoteGranularity = asRemoteGranularity(granularity);
  const { from: remoteFrom, to: remoteTo } = time
    .granulateDateRange({ from, to }, remoteGranularity)
    .next().value;

  await Promise.all([
    datasets.setup(),
    datasets.fetch({ from: remoteFrom, to: remoteTo }, remoteGranularity),
  ]);

  return datasets;
}
