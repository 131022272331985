import { createPopper } from '@popperjs/core';

const EVENT = 'fullscreenchange';

export default (reference, popper, boundary, options) => {
  const { offset, padding, placement } = options;

  popper = createPopper(reference, popper, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset },
      },
      {
        name: 'preventOverflow',
        options: { padding, boundary },
      },
    ],
  });

  const destroy = popper.destroy;
  const handler = document.addEventListener(EVENT, popper.forceUpdate);

  popper.destroy = function() {
    destroy(...arguments);

    document.removeEventListener(EVENT, handler);
  };

  return popper;
};
