import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('collections', ['getServiceById']),

    service() {
      const { serviceId } = this.$route.params;

      return this.getServiceById(serviceId);
    },
  },
};
