import manifest from './manifest';
import validators from './validators';
import { time } from '@/helpers';
import { buildNullPosition } from '@/components/the-big-ones/Map/helpers';

const QUALITY = 480;
const FILENAME_REGEXP = /(.*)(.jpg)/;

const REMOTE_NOTIFICATION_STATUS_MAPPING: {
  [key: string]: INotification['status'];
} = Object.freeze({
  ERROR: 'failure',
  PENDING: 'pending',
  READY: 'success',
});

const REMOTE_NOTIFICATION_TYPE_MAPPING: {
  [key: string]: INotification['type'];
} = Object.freeze({
  'video-concat': 'download-video',
  'video-frame': 'download-image',
});

const getRemoteProjectFirstLast = (remoteProject: RemoteProject) => {
  let firstTimestamp, lastTimestamp;

  if ((<RemoteProjectV2>remoteProject).first) {
    remoteProject = <RemoteProjectV2>remoteProject;

    // first data
    firstTimestamp =
      (remoteProject.first &&
        remoteProject.first.timestamp &&
        preprocessor.timestamp(remoteProject.first.timestamp)) ||
      null;
  } else {
    remoteProject = <RemoteProjectV1>remoteProject;

    // first data
    firstTimestamp = remoteProject['first_data']
      ? moment.min(
          Object.values(remoteProject['first_data']).map(service =>
            preprocessor.timestamp(service['timestamp_capture'])
          )
        )
      : null;
  }

  // last data
  lastTimestamp = remoteProject['last_data']
    ? moment.max(
        Object.values(remoteProject['last_data']).map(service =>
          preprocessor.timestamp(service['timestamp_capture'])
        )
      )
    : remoteProject['timestamp_capture']
    ? preprocessor.timestamp(remoteProject['timestamp_capture'])
    : null;

  return {
    first: { timestamp: firstTimestamp },
    last: { timestamp: lastTimestamp },
  };
};

const getRemoteProjectForecast = (remoteProject: RemoteProject) =>
  (remoteProject.forecast || []).map(day => ({
    temperature: {
      current: day.temperature,
      max: day['temperature_max_today'],
      min: day['temperature_min_today'],
    },
    timestamp: moment(day['forecast_for']),
    icon: day['weather_icon'],
  }));

const getRemoteServiceFirstLast = (remoteService: RemoteService) => {
  let firstTimestamp, lastTimestamp, lastThumbnail;
  const lastData = remoteService['end_data'] || remoteService['last_data'];

  if ((<RemoteServiceV2>remoteService).first) {
    remoteService = <RemoteServiceV2>remoteService;

    // first data
    firstTimestamp =
      remoteService.first &&
      ((remoteService.first.timestamp &&
        preprocessor.timestamp(remoteService.first.timestamp)) ||
        null);
  } else {
    remoteService = <RemoteServiceV1>remoteService;

    // first data
    const firstData = remoteService['first_data'];
    firstTimestamp = firstData
      ? preprocessor.timestamp(firstData['timestamp_capture'])
      : null;
  }

  // last data
  lastTimestamp = lastData
    ? preprocessor.timestamp(lastData['timestamp_capture'])
    : null;
  lastThumbnail =
    lastData &&
    lastTimestamp &&
    remoteService['last_data'] &&
    remoteService['last_data'].thumbs &&
    remoteService['last_data'].thumbs[QUALITY]
      ? {
          // even if inactive, we still have to extract the bucket from
          // last_data and not from end_data
          bucket: remoteService['last_data'].thumbs[QUALITY].bucket,
          // here, unlike above, it depends on the status
          filename: remoteService['end_data']
            ? `${remoteService.id}/${
                time.format.asRemoteDate(lastTimestamp) // date
              }/THUMBNAIL/${
                // filename + quality
                lastData.filename.replace(FILENAME_REGEXP, `$1-${QUALITY}$2`)
              }`
            : lastData.thumbs[QUALITY].filename,
        }
      : null;

  return {
    first: { timestamp: firstTimestamp },
    last: { timestamp: lastTimestamp, thumbnail: lastThumbnail },
  };
};

export const preprocessor = {
  user(remoteUser: RemoteUser): User | undefined {
    if (!validators.remoteUser(remoteUser)) return;

    const OC2Flag = Boolean((<RemoteUserV2>remoteUser).client); // FIX

    if (process.env.VUE_APP_OC2 || OC2Flag) {
      remoteUser = <RemoteUserV2>remoteUser;

      return {
        id: remoteUser.id,
        clientId: remoteUser.client.id,
        projectsIds: remoteUser['projects_ids'],
      };
    } else {
      remoteUser = <RemoteUserV1>remoteUser;

      return {
        id: remoteUser.id,
        clientId: remoteUser['client_id'],
        projectsIds: Object.keys(remoteUser.projects),
      };
    }
  },

  client(remoteClient: RemoteClient): Client | undefined {
    if (!validators.remoteClient(remoteClient)) return;

    return {
      id: remoteClient.id,
      name: remoteClient['name'],
      shortName: remoteClient['short_name'],
    };
  },

  project(remoteProject: RemoteProject): Project | undefined {
    if (!validators.remoteProject(remoteProject)) return;

    const { first, last } = getRemoteProjectFirstLast(remoteProject);
    const forecast = getRemoteProjectForecast(remoteProject);

    const OC2Flag = Boolean((<RemoteProjectV2>remoteProject)['services_ids']); // FIX

    if (process.env.VUE_APP_OC2 || OC2Flag) {
      remoteProject = <RemoteProjectV2>remoteProject;

      return {
        ...remoteProject,

        first,
        last,

        forecast,

        manifestId: remoteProject['manifest_id'],
        servicesIds: remoteProject['services_ids'],
      };
    } else {
      remoteProject = <RemoteProjectV1>remoteProject;

      const { lat, lon: lng } = remoteProject;

      return {
        id: remoteProject.id,
        name: remoteProject.name,

        location: {
          name: remoteProject.location || '',
          position:
            !_.isNil(lat) && !_.isNil(lng) ? { lat, lng } : buildNullPosition(),
        },

        first,
        last,

        forecast,

        status: last.timestamp
          ? time.timestampToStatus(last.timestamp)
          : 'inactive',

        manifestId: remoteProject.manifest_id,
        servicesIds: remoteProject['last_data']
          ? Object.keys(remoteProject['last_data'])
          : [],
      };
    }
  },

  service(remoteService: RemoteService): Service | undefined {
    const DEFAULT_TIMEZONE = 'America/Santiago'; // FIX

    if (!validators.remoteService(remoteService)) return;

    const { first, last } = getRemoteServiceFirstLast(remoteService);
    const timezone = remoteService.timezone || DEFAULT_TIMEZONE;
    const type = last.thumbnail ? 'camera' : 'data';

    const OC2Flag = Boolean((<RemoteServiceV2>remoteService)['project_id']); // FIX
    if (process.env.VUE_APP_OC2 || OC2Flag) {
      remoteService = <RemoteServiceV2>remoteService;

      return {
        ...remoteService,

        projectId: remoteService['project_id'],

        type,
        typeSettings: remoteService.type_settings,

        timezone,

        first,
        last,
      };
    } else {
      remoteService = <RemoteServiceV1>remoteService;

      return {
        id: remoteService.id,

        type,
        typeSettings: remoteService.type_settings,

        name: remoteService.name,
        location: {
          name: remoteService.location || '',
          position: remoteService.mapMarker
            ? {
                lat: remoteService.mapMarker.location.lat,
                lng: remoteService.mapMarker.location.lng,
              }
            : buildNullPosition(),
          angle: remoteService.mapMarker ? remoteService.mapMarker.angle : null,
        },
        timezone,

        first,
        last,

        status: last.timestamp
          ? time.timestampToStatus(last.timestamp)
          : 'inactive',

        projectId: remoteService['project_id'],
      };
    }
  },

  manifest,

  notification(
    remoteNotification: RemoteNotification
  ): INotification | undefined {
    if (!validators.remoteNotification(remoteNotification)) return;

    return {
      id: remoteNotification.id,
      type: REMOTE_NOTIFICATION_TYPE_MAPPING[remoteNotification.type],
      serviceName: remoteNotification.service,
      dateRange: remoteNotification.dateRange,
      uri: remoteNotification.url,

      timestamp: preprocessor.timestamp(remoteNotification.timestamp),
      status: REMOTE_NOTIFICATION_STATUS_MAPPING[remoteNotification.status],
      read: Boolean(remoteNotification.read), // it can be "undefined"...
    };
  },

  timestamp(remoteTimestamp: RemoteTimestamp): Timestamp {
    const { seconds } = remoteTimestamp;

    return moment(seconds * 1000);
  },
};

export default preprocessor;
