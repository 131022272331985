<template>
  <div class="tabs-bodies"><slot /></div>
</template>

<script>
import Grouper from './Grouper';

export default {
  name: 'TabsBodies',
  mixins: [Grouper],
  model: { prop: 'tab', event: 'change' },
  props: {
    tab: { type: Number, default: 0 },
  },
  methods: {
    getActiveItemKey() {
      return this.tab;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
