import PCancelable from 'p-cancelable';

import NotificationPromise from './NotificationPromise';

type DirectDownload = { uri: string };
type Download = DirectDownload | { uri?: undefined; notificationId: string };

export class DownloadPromise extends PCancelable<DirectDownload> {
  constructor(downloadCallback: () => PCancelable<Download>) {
    super((resolve, reject, onCancel) => {
      const promises: {
        generate?: PCancelable<Download>;
        notification?: PCancelable<{ uri: string }>;
      } = {};

      promises.generate = downloadCallback();

      promises.generate
        .then(response => {
          if ('uri' in response && !_.isUndefined(response.uri))
            resolve({ uri: response.uri });
          else if ('notificationId' in response) {
            promises.notification = new NotificationPromise(
              response.notificationId
            );

            promises.notification.then(resolve).catch(reject);
          } else reject();
        })
        .catch(reject);

      onCancel(() => {
        const { generate, notification } = promises;

        if (notification && !notification.isCanceled) notification.cancel();
        if (generate && !generate.isCanceled) generate.cancel();

        reject();
      });
    });
  }
}

export default DownloadPromise;
