<template>
  <div class="download-drawer-video-original-form v-spacing-4">
    <div class="box b-radius-2 v-spacing-4 p-3">
      <DownloadDrawerInputWrapper
        :label="$t(`${$options.path}.date.title`)"
        :description="$t(`${$options.path}.date.body`)"
      >
        <FormulateInput
          v-model="form.date"
          type="odd-select"
          name="date"
          :options="datesAsOptions"
          class="mt-3"
        />
      </DownloadDrawerInputWrapper>

      <DownloadDrawerTimeRangeInput
        v-model="form.timeRange"
        :service-id="serviceId"
        :date="dates[form.date]"
        :max-duration="maxDifference"
        :min-duration="minDifference"
        :step="step"
        class="mt-3"
      />
    </div>

    <DownloadDrawerPlaybackSpeedInput
      v-model="form.playbackSpeed"
      :options="playbackSpeedsAsOptions"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Form from './../Form';
import { playbackSpeedsAsOptions } from './../helpers.ts';

// components
import DownloadDrawerInputWrapper from '@/components/ui/DownloadDrawer/InputWrapper.vue';
import DownloadDrawerPlaybackSpeedInput from '@/components/ui/DownloadDrawer/inputs/PlaybackSpeed.vue';
import DownloadDrawerTimeRangeInput from '@/components/ui/DownloadDrawer/inputs/TimeRange/index.vue';

export default {
  name: 'DownloadDrawerVideoOriginalForm',
  components: {
    DownloadDrawerInputWrapper,
    DownloadDrawerPlaybackSpeedInput,
    DownloadDrawerTimeRangeInput,
  },
  mixins: [Form],
  props: {
    dateRange: { type: Object, required: true },
  },
  data() {
    return {
      max: this.$options.datetime.time.max,
      maxDifference: this.$options.datetime.time.maxDifference,
      min: this.$options.datetime.time.min,
      minDifference: this.$options.datetime.time.minDifference,
      step: this.$options.datetime.step,
    };
  },
  computed: {
    ...mapState('download', {
      serviceId: state => state.payload.service.id,

      playbackSpeeds: state => _.orderBy(state.video.playbackSpeeds),
      disabledPlaybackSpeeds: state => state.video.disabledPlaybackSpeeds,

      storeForm: state => ({
        date: state.video.payload.date,
        timeRange: state.video.payload.timeRange,

        playbackSpeed: state.video.payload.playbackSpeed,
      }),
    }),

    dates() {
      return Array.from(this.$time.dateRangeDays(this.dateRange));
    },
    datesAsOptions() {
      return _.map(this.dates, day => {
        const label = this.$time.format.asDate(day, true);

        return {
          id: label,

          label,
          value: day,
        };
      });
    },

    playbackSpeedsAsOptions() {
      const { playbackSpeeds, disabledPlaybackSpeeds } = this;

      return playbackSpeedsAsOptions(playbackSpeeds, disabledPlaybackSpeeds);
    },
  },
  methods: {
    ...mapMutations('download', { setPayload: 'set-video-payload' }),

    syncDown() {
      const { storeForm } = this;

      this.form = {
        date: _.findIndex(this.dates, date => storeForm.date.isSame(date)),
        timeRange: storeForm.timeRange,

        playbackSpeed: String(storeForm.playbackSpeed),
      };
    },
    syncUp() {
      const { form } = this;

      this.setPayload({
        date: this.dates[form.date],
        timeRange: form.timeRange,

        playbackSpeed: Number(form.playbackSpeed),
      });
    },
  },

  path: 'c.download-drawer',

  datetime: {
    duration: { max: 60, min: 5 },
    time: { max: 1439, maxDifference: 60, min: 0, minDifference: 1 },

    step: 1,
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.download-drawer-video-original-form
  .double-range-with-label .discrete-gradient-bar
    --height: 8px
</style>
