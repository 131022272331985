<template>
  <button
    :disabled="disabled"
    :style="style"
    v-bind="$attrs"
    :class="className"
    v-on="listeners"
  >
    <icon v-if="icon || navigation">
      <slot />
    </icon>

    <slot v-else />
  </button>
</template>

<script>
import _ from 'lodash';
import { Elevatable, WithColor, WithSize } from './mixins';
import {
  buildBooleanPropsFromSuffixes,
  buildClassNameFromProperty,
} from './mixins/helpers';

const PROPERTIES = Object.freeze({
  type: {
    prefix: 'e-button--',
    suffixes: ['filled', 'navigation', 'outlined'],
    override: true,
  },
  modifiers: {
    prefix: 'e-button--',
    suffixes: ['floating', 'icon', 'negative', 'player', 'selected'],
  },
  size: {
    prefix: 'e-button--',
    suffixes: ['sm', 'md', 'lg'],
  },
});

export default {
  name: 'EButton',
  mixins: [Elevatable, WithColor, WithSize],
  props: {
    ...buildBooleanPropsFromSuffixes(PROPERTIES.type.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.modifiers.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.size.suffixes),

    to: { type: [String, Object], default: undefined },

    disabled: { type: Boolean, default: false },
  },
  computed: {
    listeners() {
      const vm = this;

      return { ...this.$listeners, click: vm.onClick };
    },

    isActive() {
      if (!this.to) return;

      return (
        this.$route.name === this.to.name &&
        _.isEqual(this.$route.params, this.to.params)
      );
    },

    style() {
      return Object.assign({}, this.withColorStyle, this.withSizeStyle);
    },
    className() {
      const className = 'e-button';

      return [
        className,
        Object.assign(
          { [`${className}--active`]: this.isActive },
          this.elevatableClasses
        ),
        ...Object.keys(PROPERTIES).map(property =>
          buildClassNameFromProperty(this.$props, property, PROPERTIES)
        ),
      ];
    },
  },
  methods: {
    onClick(e) {
      if (this.to)
        this.$router.push(this.to).catch(error => {
          if (error && error.name === 'NavigationDuplicated') return undefined;
          else throw error;
        });

      this.$el.blur();

      this.$emit('click', e);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
=icon
  width: var(--size)
  min-width: initial
  height: var(--size)
  min-height: initial

  +h-padding(initial)

  & > .e-icon
    margin-right: initial

=rounded
  border-radius: $b-radius-circle

.e-button
  $default-font-family: $f-family-primary
  $default-font-size: $f-size-sm
  $default-font-weight: $f-weight-semibold

  $border-width: 1px
  $h-padding: #{$spacing-1 + $spacing-2}

  flex-shrink: 0

  font-family: var(--f-family, $default-font-family)
  font-size: var(--f-size, $default-font-size)
  font-style: var(--f-style, initial)
  font-weight: var(--f-weight, $default-font-weight)

  min-height: var(--size)

  border-radius: $b-radius-2
  border-style: solid
  border-width: $border-width

  +button
  +d-flex-r(center, center)
  +h-padding($h-padding)

  & > .e-icon
    --size: var(--icon-size)

  & > .e-icon:nth-child(1)
    margin-right: $spacing-2

  & > .e-icon:nth-child(2)
    margin-left: $spacing-2

// - size
$sm-size: 24px
$sm-icon-size: 16px
$md-size: 32px
$md-icon-size: 20px
$lg-size: 40px
$lg-icon-size: 24px

.e-button
  &--sm
    --size: #{$sm-size}
    --icon-size: #{$sm-icon-size}

  &--md
    --size: #{$md-size}
    --icon-size: #{$md-icon-size}

  &--lg
    --size: #{$lg-size}
    --icon-size: #{$lg-icon-size}

.e-button
  &.e-button--icon,
  &.e-button--navigation
    +icon

  &.e-button--navigation,
  &.e-button--player
    +rounded

  &:disabled
    cursor: default
    pointer-events: none

// color
.e-button
  $default-text-color: var(--c-secondary)
  $text-color: var(--color, $default-text-color)

  --text-color: #{$text-color}

  --background-color: #{$c-transparent}
  --hover-background-color: #{rgba($c-real-black, .04)}
  --focus-background-color: #{rgba($c-real-black, .12)}
  --border-color: #{$c-transparent}

  color: var(--text-color)

  background-color: var(--background-color)
  border-color: var(--border-color)

  & > .e-icon
    --color: var(--text-color)

.e-button:hover
  background-color: var(--hover-background-color)

.e-button:focus
  background-color: var(--focus-background-color)

.e-button
  &--active
    --text-color: var(--c-primary)

  &--filled
    --text-color: var(--c-white)

    --background-color: var(--c-primary)
    --hover-background-color: #{darken($c-primary, 4%)}
    --focus-background-color: #{darken($c-primary, 12%)}

  &--floating
    --background-color: var(--c-white)
    --hover-background-color: var(--c-gray-1)
    --focus-background-color: var(--c-gray-1)

  &--negative.e-button--filled
    --background-color: var(--c-red)
    --hover-background-color: #{darken($c-red, 12%)}

  &--negative:not(.e-button--filled)
    --text-color: var(--c-red)

  &--outlined
    --border-color: var(--c-gray-2)

  &--player
    --text-color: var(--c-white)

    --hover-background-color: #{rgba($c-real-white, .05)}
    --focus-background-color: #{rgba($c-real-white, .05)}

  &--selected.e-button--navigation
    --text-color: var(--c-primary)

  &--selected:not(.e-button--navigation)
    --text-color: var(--c-primary)

    --background-color: #{rgba($c-primary, .20)}
    --hover-background-color: #{rgba($c-primary, .20)}
    --focus-background-color: #{rgba($c-primary, .20)}
    --border-color: var(--c-primary)

  &:not(.e-button--selected).e-button
    &:disabled
      --text-color: var(--c-gray-1)

      --border-color: var(--background-color)

    &--filled:disabled, &--outlined:disabled
      --background-color: var(--c-gray-0)

    &--player:disabled
      --text-color: var(--c-gray-2)
</style>
