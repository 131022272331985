import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('collections', [
      'client',
      'getProjectById',
      'getServiceById',
    ]),

    project() {
      const { projectId } = this.$route.params;

      return projectId ? this.getProjectById(projectId) : null;
    },
    service() {
      const { serviceId } = this.$route.params;

      return serviceId ? this.getServiceById(serviceId) : null;
    },

    state() {
      const { name } = this.$route;

      if (name === 'projects-index' || name === 'services-index')
        return 'at-collection-index';
      else if (['projects-show', 'projects-show-services-index'].includes(name))
        return 'at-projects-show';
      else if (['projects-show-services-show'].includes(name))
        return 'at-projects-show-services-show';

      return 'at-unauthenticated';
    },

    ready() {
      return (
        Boolean(this.viewer) &&
        Boolean(this.client) &&
        (this.state !== 'at-projects-show' || Boolean(this.project)) &&
        (this.state !== 'at-projects-show-services-show' ||
          Boolean(this.service))
      );
    },
  },
};
