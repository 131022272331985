<template>
  <div class="menu-sign-out" @click="onSignOut">
    <EButton navigation md class="mr-3">power</EButton>

    <t color="gray-2">{{ $t('g.sign-out') }}</t>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('authentication', ['signOut']),

    async onSignOut() {
      await this.signOut();

      this.$router.push({ name: 'sign-in' }).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.menu-sign-out
  +d-flex-r(center)
</style>
