<template>
  <LeftDrawer
    class="notifications-drawer"
    :is-open="isNotificationsOpen"
    @close="closeNotifications"
  >
    <template v-slot:header>
      <icon color="primary" class="mr-2">bell</icon>
      <t color="primary" sm semibold>{{ $t('c.menu.notifications-drawer') }}</t>
    </template>

    <NotificationsDrawerBody />
  </LeftDrawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import LeftDrawer from '@/components/ui/LeftDrawer.vue';
import NotificationsDrawerBody from './Body/index.vue';

export default {
  name: 'NotificationsDrawer',
  components: { LeftDrawer, NotificationsDrawerBody },
  computed: {
    ...mapState('ui', {
      isNotificationsOpen: state => state.notifications.isOpen,
    }),
  },
  methods: {
    ...mapActions('ui', ['closeNotifications']),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
