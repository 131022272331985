import CRCellPreset from './CRCellPreset';

export class Occupancy extends CRCellPreset {
  static VALID_PROPERTIES = ['groupBy', 'kpiAggregation', 'kpiLabel', 'unit'];

  static DEFAULT_REMOTE_KPI_AGGREGATION = 'sum';

  groupBy;
  kpiAggregation;
  kpiLabel;
  unit;

  buildDatasetOptions(options) {
    // overriding <CRCellPreset.prototype.buildDatasetOptions> method

    const { color } = options;

    return { color };
  }

  buildKPIs() {
    return {
      aggregation:
        this.kpiAggregation || Occupancy.DEFAULT_REMOTE_KPI_AGGREGATION,
      label: this.kpiLabel,
    };
  }

  build(remotePayload) {
    const payload = this.parseRemotePayload(remotePayload);

    this.setup(payload);

    const type = 'LineChart';
    const kpis = this.buildKPIs();

    return {
      data_address_parameters: {
        datasets: this.buildDatasets(this.classNames, this.rois, this.legendBy),
        groupBy: this.groupBy,
      },

      type,
      type_parameters: { stacked: false, unit: this.unit },

      kpis,
    };
  }
}

export default Occupancy;
