<script>
import { WithColor, WithText } from './mixins';

const CLASS_NAME = 'e-text';

const buildStyle = ctx => ({
  ...WithColor.buildStyle(ctx.props),
  ...WithText.buildStyle(ctx.props),

  ...ctx.data.style,
  ...ctx.data.staticStyle,
});

const buildClassName = ctx => [
  CLASS_NAME,

  ...WithText.buildClassName(ctx.props),

  ctx.data.class,
  ctx.data.staticClass,
];

export default {
  name: 'EText',
  functional: true,
  props: { ...WithColor.props, ...WithText.props },

  render(createElement, ctx) {
    return createElement(
      'div',
      {
        props: ctx.data.attrs,
        style: buildStyle(ctx),
        class: buildClassName(ctx),
      },
      ctx.scopedSlots.default()
    );
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.e-text
  // default
  $default-font-family: $f-family-primary
  $default-font-size: $f-size-md
  $default-font-weight: $f-weight-regular
  $default-color: var(--c-secondary)

  display: inline-block

  font-family: var(--f-family, $default-font-family)
  font-size: var(--f-size, $default-font-size)
  font-style: var(--f-style, initial)
  font-weight: var(--f-weight, $default-font-weight)
  text-align: var(--t-align, initial)
  text-decoration: var(--t-decoration, initial)
  text-transform: var(--t-transform, initial)
  color: var(--color, $default-color)
</style>
