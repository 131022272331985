import { Machine, interpret } from 'xstate';

// https://en.wikipedia.org/wiki/Complete_graph
const COMPLETE_GRAPH = {
  TO_ALL_PROJECTS: 'allProjects',
  TO_ALL_SERVICES: 'allServices',
  TO_PROJECT: 'project',
  TO_SERVICE: 'service',
};

const machine = (initial = 'allProjects') =>
  Machine({
    id: 'projects-services-select',
    initial,
    states: {
      allProjects: {
        on: Object.assign({ TOGGLE: 'allServices' }, COMPLETE_GRAPH),
      },
      allServices: {
        on: Object.assign({ TOGGLE: 'allProjects' }, COMPLETE_GRAPH),
      },
      project: {
        on: Object.assign({}, COMPLETE_GRAPH),
      },
      service: {
        on: Object.assign({}, COMPLETE_GRAPH),
      },
    },
  });

export default {
  data() {
    return {
      machine: {
        service: interpret(machine(this.getInitialState())),
        state: machine.initialState,
      },
    };
  },
  created() {
    this.machine.service
      .onTransition(state => {
        this.machine.state = state;
      })
      .start();
  },
  methods: {
    send(event) {
      this.machine.service.send(event);
    },
  },
};
