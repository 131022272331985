<template>
  <div v-if="ready" class="header header--desktop">
    <GoDefaultLink>
      <t semibold capitalize>{{ client.shortName }}</t>
    </GoDefaultLink>

    <GoBackLink v-if="state !== 'at-collection-index'" class="mr-2" />
    <ProjectsServicesSelect />

    <div class="spacer mr-3" />

    <portal-target name="header" />

    <div class="d-flex-r d-flex-a-c h-spacing-3 ml-4">
      <NotificationsActivator v-bind="buttonBind" />

      <EButton v-bind="buttonBind" @click="onSignOut">power</EButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mixin from './mixin';

// components
import GoBackLink from '@/components/ui/Header/GoBackLink.vue';
import GoDefaultLink from '@/components/ui/Header/GoDefaultLink.vue';
import NotificationsActivator from '@/components/ui/NotificationsDrawer/Activator.vue';
import ProjectsServicesSelect from './ProjectsServicesSelect/index.vue';

export default {
  name: 'HeaderDesktop',
  components: {
    GoBackLink,
    GoDefaultLink,
    NotificationsActivator,
    ProjectsServicesSelect,
  },
  mixins: [mixin],
  data() {
    return {
      buttonBind: { navigation: true, md: true },
    };
  },
  methods: {
    ...mapActions('authentication', ['signOut']),

    async onSignOut() {
      await this.signOut();

      this.$router.push({ name: 'sign-in' }).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass
@import style.sass

// base
$projects-services-select-md-max-width: 256px

.header--desktop
  +d-flex-r(center)

  & > *
    flex-shrink: 0

  & > *:first-child
    flex-basis: $left-width

.header--desktop > .projects-services-select
  flex-shrink: 1

  max-width: $projects-services-select-md-max-width

// START - height --------------------------------------------------------------
.header--desktop
  height: $header-height
// END - height ----------------------------------------------------------------

// START - padding -------------------------------------------------------------
.header--desktop
  padding-right: $padding

.header--desktop > *:first-child
  padding-left: $padding
// END - padding ---------------------------------------------------------------

// color
.header--desktop
  background-color: var(--c-background)
</style>
