import tokens from 'odd-ds/dist/tokens.json';

export const buildBooleanPropsFromSuffixes = (suffixes, defaultSuffix) =>
  suffixes.reduce(
    (props, suffix) => ({
      ...props,
      [suffix]: { type: Boolean, default: suffix === defaultSuffix },
    }),
    {}
  );

export const getSuffixesFromTokens = regexp =>
  Object.keys(tokens).reduce((suffixes, property) => {
    const match = regexp.exec(property);
    regexp.lastIndex = 0;

    return match ? [...suffixes, match[1]] : suffixes;
  }, []);

export const buildClassNameFromProperty = ($props, property, PROPERTIES) => {
  const { prefix, suffixes } = PROPERTIES[property];

  let className;
  if (PROPERTIES[property].override) className = '';
  else className = [];

  for (const suffix of suffixes) {
    if ($props[_.camelCase(suffix)]) {
      const newClassName = _.isPlainObject(prefix)
        ? `${prefix[suffix]}`
        : `${prefix}${suffix}`;

      if (_.isString(className)) className = newClassName;
      else className.push(newClassName);
    }
  }

  return className;
};
