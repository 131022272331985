import { first, takeUntil } from 'rxjs/operators';
import API from '@/API';
import { $SignOut } from '@/store/buses';
import { deepVueSet } from './helpers';
import { STATUS } from '@/API/preprocessor/constants.ts';

const areCollectionsReady = collections => _.every(collections, 'ready');

const comparer = (a, b) => {
  // by type...
  if (a.type === 'camera' && b.type !== 'camera') return -1;
  if (a.type !== 'camera' && b.type === 'camera') return 1;

  // by activeness...
  if (a.status === STATUS.active && b.status === STATUS.inactive) return -1;
  if (a.status === STATUS.inactive && b.status === STATUS.active) return 1;

  // by timestamp...
  if (a.status === STATUS.inactive && b.status === STATUS.inactive)
    return b.last.timestamp.diff(a.last.timestamp);

  return 0;
};

const buildNullCollection = () => ({
  lastValue: null,
  observable$: null,
  subscription: null,

  ready: false,
});

const buildInitialState = () => ({
  user: buildNullCollection(),
  notifications: buildNullCollection(),

  client: buildNullCollection(),
  projects: {},
  services: {},
});

export const state = buildInitialState();

export const mutations = {
  'set-collection'(state, { path, observable$ }) {
    let collection = _.get(state, path);
    if (!collection)
      collection = deepVueSet(state, path, buildNullCollection());

    const { subscription } = collection;
    if (subscription) subscription.unsubscribe();
    collection.observable$ = observable$;
  },

  'set-collection-last-value'(state, { path, lastValue }) {
    const collection = _.get(state, path);

    // a collection is ready once it receives a value
    if (!collection.ready) collection.ready = true;

    collection.lastValue = lastValue;
  },

  'set-collection-subscription'(state, { path, subscription }) {
    const collection = _.get(state, path);

    collection.subscription = subscription;
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  setCollectionConnection(
    { commit, state },
    { path, observable$, callback, once }
  ) {
    commit('set-collection', { path, observable$ });

    const operator = once ? first() : takeUntil($SignOut);
    const subscription = observable$.pipe(operator).subscribe(value => {
      const { ready } = _.get(state, path);

      // if a callback has been given and the collection is about to be ready,
      // call it with the received value
      if (Boolean(callback) && !ready && Boolean(value)) callback(value);

      commit('set-collection-last-value', { path, lastValue: value });
    });

    commit('set-collection-subscription', { path, subscription });
  },

  setUserConnection({ dispatch }, { userId, ...payload }) {
    const path = 'user';
    const observable$ = API.user$(userId);

    dispatch('setCollectionConnection', { path, observable$, ...payload });
  },

  setUserNotificationsConnection({ dispatch }, { userId }) {
    const path = 'notifications';
    const observable$ = API.notifications$(userId);

    dispatch('setCollectionConnection', { path, observable$ });
  },

  setClientConnection({ dispatch }, { clientId, ...payload }) {
    const path = 'client';
    const observable$ = API.client$(clientId);

    dispatch('setCollectionConnection', { path, observable$, ...payload });
  },

  setProjectConnection({ dispatch }, { projectId, ...payload }) {
    const path = `projects.${projectId}`;
    const observable$ = API.project$(projectId);

    dispatch('setCollectionConnection', { path, observable$, ...payload });
  },

  setServiceConnection({ dispatch }, { serviceId, payload }) {
    const path = `services.${serviceId}`;
    const observable$ = API.service$(serviceId);

    dispatch('setCollectionConnection', { path, observable$, ...payload });
  },
};

export const getters = {
  viewer(state) {
    // we will refer to the currently signed-in user as "viewer"

    return state.user.lastValue;
  },

  // notifications
  getAllNotifications(state) {
    return state.notifications.lastValue || [];
  },

  // client
  client(state) {
    return state.client.lastValue;
  },

  // projects
  getProjectById(state) {
    return projectId => {
      const project = state.projects[projectId];

      return project ? project.lastValue : null;
    };
  },

  getProjectsByIds(state, getters) {
    return projectsIds => {
      const projects = _(projectsIds)
        .map(getters.getProjectById)
        .compact()
        .value();

      return projects.sort(comparer);
    };
  },

  getAllProjects(state, getters) {
    const projects = _(Object.keys(state.projects))
      .map(getters.getProjectById)
      .compact()
      .value();

    return projects.sort(comparer);
  },

  isProjectFetchedById(state, getters) {
    return (projectId, withServices = false) => {
      const collection = state.projects[projectId];
      const projectReady = collection && collection.ready;

      if (!withServices) return projectReady;

      const { servicesIds } = collection.lastValue;
      const projectServicesReady = getters.areServicesFetchedByIds(servicesIds);

      return projectReady && projectServicesReady;
    };
  },

  areProjectsFetchedByIds(state) {
    return projectsIds => {
      const collections = projectsIds.map(
        projectId => state.projects[projectId]
      );

      return areCollectionsReady(collections);
    };
  },

  areAllProjectsFetched(state) {
    const collections = state.projects;

    return areCollectionsReady(collections);
  },

  // services
  getServiceById(state) {
    return serviceId => {
      const service = state.services[serviceId];

      return service ? service.lastValue : null;
    };
  },

  getServicesByIds(state, getters) {
    return servicesIds => {
      const services = _(servicesIds)
        .map(getters.getServiceById)
        .compact()
        .filter('id') // FIX
        .value();

      return services.sort(comparer);
    };
  },

  getAllServices(state, getters) {
    const services = _(Object.keys(state.services))
      .map(getters.getServiceById)
      .compact()
      .value();

    return services.sort(comparer);
  },

  isServiceFetchedById(state) {
    return serviceId => {
      const collection = state.services[serviceId];

      return collection && collection.ready;
    };
  },

  areServicesFetchedByIds(state) {
    return servicesIds => {
      const collections = servicesIds.map(
        serviceId => state.services[serviceId]
      );

      return areCollectionsReady(collections);
    };
  },

  areAllServicesFetched(state) {
    const collections = state.services;

    return areCollectionsReady(collections);
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
