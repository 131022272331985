<template>
  <div v-click-outside="close" :class="className">
    <div class="input-select__header-wrapper">
      <div class="input-select__header" @click="onHeaderClick">
        <icon v-if="icon" :size="20" class="mr-2">{{ icon }}</icon>
        <t sm semibold>{{ value === -1 ? label : options[value].text }}</t>

        <template v-if="!hideControls">
          <div class="spacer" />

          <icon class="ml-2">chevron-down</icon>
        </template>
      </div>
    </div>

    <transition name="fade-normal">
      <div v-if="isOpen" class="input-select__body">
        <t color="gray-2" sm semibold class="mb-3">{{ label }}</t>

        <div class="input-select__options">
          <div
            v-for="(option, index) in options"
            :key="option.text"
            :class="optionClassName(index)"
            @click="onOptionClick(index)"
          >
            <slot name="option" :option="option">{{ option }}</slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputSelectV2',
  props: {
    value: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    className() {
      const className = 'input-select';

      return [
        className,
        {
          // props
          [`${className}--controls-hidden`]: this.hideControls,
          [`${className}--flat`]: this.flat,
          [`${className}--with-icon`]: Boolean(this.icon),

          // data
          [`${className}--open`]: this.isOpen,
        },
      ];
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },

    isActiveOption(index) {
      return index === this.value;
    },

    onHeaderClick() {
      if (this.disabled || this.hideControls) return;

      if (this.isOpen) this.close();
      else this.open();
    },
    onOptionClick(index) {
      this.close();

      if (!this.isActiveOption(index)) this.$emit('input', index);
    },

    optionClassName(index) {
      const className = 'input-select__option';

      return [
        className,
        {
          [`${className}--active`]: this.isActiveOption(index),
        },
      ];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$min-width: 196px
$max-height: 256px

$external-padding: 4px
$internal-padding: $spacing-3

$external-width: 100%
$external-height: 40px

$internal-width: calc(#{$external-width} - #{$external-padding * 2})
$internal-height: $external-height - $external-padding * 2

$border-radius: ($internal-height) / 2

.input-select
  +p-relative(#{$z-index-base + 1})
  +wrapper($external-height, $external-padding)

.input-select__header,
.input-select__body
  box-shadow: $elevation-1

.input-select__header
  min-width: $min-width

  +d-flex-r(center)
  +h-padding($internal-padding)
  +h-rounded($internal-height)

  & > .e-icon
    flex-shrink: 0

  // chevron-down
  & > .e-icon:last-child
    transition: transform $duration-normal $ease-in-out-quint

.input-select__body
  position: absolute
  top: $internal-height + $external-padding

  width: $internal-width
  padding: $internal-padding

  transition: opacity $duration-normal $ease-in-out-sine

.input-select__options
  max-height: $max-height

  overflow-y: auto

  +no-scrollbar

.input-select__header,
.input-select__option
  +button

.input-select__option
  padding: $spacing-2

  transition: background-color $duration-quickly

  & > .e-text
    --f-weight: #{$f-weight-semibold}

    transition: color $duration-quickly

// START - ellipsis ------------------------------------------------------------
.input-select
  width: 100%

.input-select__header-wrapper
  position: absolute

  width: $internal-width
  height: $internal-height

.input-select__header > .e-text
  overflow: hidden
  white-space: nowrap
// END - ellipsis --------------------------------------------------------------

.input-select
  &--controls-hidden
    .input-select__header
      min-width: initial

  &--flat
    .input-select__header,
    .input-select__body
      box-shadow: none

  &--with-icon
    .input-select__header
      padding-left: $spacing-2

  &--open
    // border-radius
    .input-select__header
      +border-top-radius($border-radius)
      +border-bottom-radius(initial)

    .input-select__body
      +border-bottom-radius($border-radius)

    // chevron-down
    .input-select__header > .e-icon:last-child
      transform: rotate(180deg)

// color
.input-select__header,
.input-select__body
  background-color: var(--c-background)

.input-select__header
  & > .e-icon,
  & > .e-text
    --color: var(--c-primary)

.input-select__option
  +on-active
    background-color: #F4F4F4 // FIX

.input-select
  &--flat
    .input-select__header
      background-color: $c-transparent
</style>
