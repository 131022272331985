<template>
  <div :class="className">
    <NotificationsDrawerBodyEmpty v-if="isEmpty" />

    <template v-else>
      <template v-if="notifications.new.length > 0">
        <div class="sub-header p-3">
          <t sm semibold>{{ $t('c.menu.new-notifications') }}</t>

          <UnreadNotificationsCount
            :count="notifications.new.length"
            sm
            class="ml-2"
          />
        </div>

        <NotificationsDrawerBodyNotification
          v-for="notification in notifications.new"
          :key="`${notification.id}-${notification.timestamp}`"
          :notification="notification"
          class="p-3"
        />
      </template>

      <template v-if="notifications.old.length > 0">
        <div class="sub-header p-3">
          <t sm semibold>{{ $t('c.menu.old-notifications') }}</t>
        </div>

        <NotificationsDrawerBodyNotification
          v-for="notification in notifications.old"
          :key="`${notification.id}-${notification.timestamp}`"
          :notification="notification"
          class="p-3"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// components
import NotificationsDrawerBodyEmpty from './Empty.vue';
import NotificationsDrawerBodyNotification from './Notification.vue';
import UnreadNotificationsCount from '@/components/ui/Menu/UnreadNotificationsCount.vue';

export default {
  name: 'NotificationsDrawerBody',
  components: {
    NotificationsDrawerBodyEmpty,
    NotificationsDrawerBodyNotification,
    UnreadNotificationsCount,
  },
  computed: {
    ...mapGetters('collections', ['getAllNotifications']),

    notifications() {
      const lastWeekPivot = moment()
        .subtract(7, 'days')
        .startOf('day');

      return {
        new: this.getAllNotifications.filter(
          ({ timestamp }) => lastWeekPivot.diff(timestamp) <= 0
        ),
        old: this.getAllNotifications.filter(
          ({ timestamp }) => lastWeekPivot.diff(timestamp) > 0
        ),
      };
    },

    isEmpty() {
      const { notifications } = this;

      return notifications.new.length === 0 && notifications.old.length === 0;
    },

    className() {
      const className = 'notifications-drawer-body';

      return [className, { [`${className}--empty`]: this.isEmpty }];
    },
  },
  methods: {
    ...mapActions('ui', ['closeNotifications']),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.notifications-drawer-body > .notification
  border-bottom-style: solid
  border-bottom-width: 1px

  &:last-child
    border-bottom: none

// - empty
.notifications-drawer-body--empty
  height: 100%

  & > .notifications-empty
    min-height: 100%

// color
.notifications-drawer-body > .notification
  border-bottom-color: #F4F4F4 // FIX
</style>
