<template>
  <div :style="style" :class="className">
    <template v-if="mq['sm-']">
      <HeaderMobile />
      <Menu />
    </template>

    <HeaderDesktop v-else />

    <div class="l-authenticated__body">
      <div v-if="ready" class="l-authenticated__slot-wrapper">
        <slot />
      </div>

      <HeightObserver
        class="l-authenticated__footer"
        @notify="newHeight => (closedHeight = newHeight)"
      >
        <BottomDrawer v-if="mq['sm-']" />

        <portal-target name="footer" />
      </HeightObserver>
    </div>

    <PortalTargetWithOverlay
      v-if="mq['md+']"
      name="overlay"
      slim
      class="l-authenticated__portal-target"
    />

    <DownloadDrawer />
    <Notifications v-if="mq['md+']" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { WithViewport } from '@/mixins';
import mixin from './mixin';

// components
import BottomDrawer from '@/components/ui/BottomDrawer/index.vue';
import DownloadDrawer from '@/components/ui/DownloadDrawer/index.vue';
import HeightObserver from '@/components/utils/HeightObserver.vue';
import Menu from '@/components/ui/Menu/index.vue';
import Notifications from '@/components/ui/NotificationsDrawer/index.vue';
import PortalTargetWithOverlay from '@/components/utils/PortalTargetWithOverlay.vue';
// - header
import HeaderDesktop from '@/components/ui/Header/desktop.vue';
import HeaderMobile from '@/components/ui/Header/mobile.vue';

export default {
  name: 'LAuthenticated',
  components: {
    BottomDrawer,
    DownloadDrawer,
    HeaderDesktop,
    HeaderMobile,
    HeightObserver,
    Menu,
    Notifications,
    PortalTargetWithOverlay,
  },
  mixins: [mixin, WithViewport],
  data() {
    return {
      closedHeight: 0,
    };
  },
  computed: {
    ...mapState('authentication', { firebaseUser: 'user' }),
    ...mapState('ui', {
      isBottomDrawerHidden: state => state.bottomDrawer.isHidden,
    }),
    ...mapGetters('collections', ['areAllProjectsFetched']),

    ready() {
      return this.viewer && this.areAllProjectsFetched;
    },

    style() {
      return { '--closed-height': `${this.closedHeight}px` };
    },
    className() {
      const className = 'l-authenticated';

      return [
        className,
        { [`${className}--showing-bottom-drawer`]: !this.isBottomDrawerHidden },
      ];
    },
  },
  created() {
    const { uid: userId } = this.firebaseUser;

    this.setUserConnection({
      userId,
      callback: user => {
        this.setClientConnection({ clientId: user.clientId, once: true });

        user.projectsIds.forEach(projectId =>
          this.setProjectConnection({
            projectId,
            callback: project =>
              project.servicesIds.forEach(serviceId =>
                this.setServiceConnection({ serviceId })
              ),
            once: true,
          })
        );
      },
      once: true,
    });
    this.setUserNotificationsConnection({ userId });
  },
  methods: {
    ...mapActions('collections', [
      'setUserConnection',
      'setUserNotificationsConnection',

      'setClientConnection',
      'setProjectConnection',
      'setServiceConnection',
    ]),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

.l-authenticated
  height: 100%

.l-authenticated__slot-wrapper
  height: 100%

  +p-relative($z-index-base)

  & > *
    height: 100%

+media-down(md)
  .l-authenticated__body
    height: calc(100% - #{$header-height})

    +p-relative(#{$z-index-base + 1})

  .l-authenticated__slot-wrapper
    height: calc(100% - var(--closed-height))

    background-color: var(--c-background)

    overflow-y: auto

    +no-scrollbar

  .l-authenticated__footer
    +p-fixed-shell-bottom($z-index-base, 0)

  .l-authenticated
    &--showing-bottom-drawer
      .bottom-drawer
        +p-relative(#{$z-index-base + 2})

      .l-authenticated__slot-wrapper
        background-color: initial

+media-up(md)
  .l-authenticated
    display: grid
    grid-template-rows: $header-height calc(100% - #{$header-height})
    grid-template-columns: 1fr
    grid-template-areas: "top" "body"

  .l-authenticated > .header--desktop
    grid-area: top

    box-shadow: $elevation-1

    +p-relative(#{$z-index-base + 1})

  .l-authenticated__body
    grid-area: body

    height: 100%

    +p-relative($z-index-base)

  .l-authenticated__portal-target
    grid-area: body

    z-index: #{$z-index-base + 3}
</style>
