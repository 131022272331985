import Vue from 'vue';

const buildDimension = (minFrom = null, maxTo = null) => {
  const from = null;
  const to = null;

  return {
    from,
    to,
    minFrom,
    maxTo,

    buffer: { from, to },

    preset: 0,

    isSetByUser: false,
  };
};

export const buildInitialState = () => ({
  dimensions: {},
});

export const state = buildInitialState();

export const mutations = {
  'set-dimension'(state, { id, minFrom, maxTo }) {
    Vue.set(state.dimensions, id, buildDimension(minFrom, maxTo));
  },
  'delete-dimension'(state, { id }) {
    Vue.delete(state.dimensions, id);
  },

  'set-date-range'(state, { id, from, to, isSetByUser = false }) {
    const dimension = state.dimensions[id];

    from = from || dimension.from;
    to = to || dimension.to;

    // <to> before <from>...
    if (to.diff(from) < 0) return;

    dimension.from = from;
    dimension.to = to;

    dimension.isSetByUser = isSetByUser;
  },

  'set-date-range-buffer'(state, { id, from, to }) {
    const dimension = state.dimensions[id];

    dimension.buffer.from = from;
    dimension.buffer.to = to;
  },

  'set-preset'(state, { id, preset }) {
    const dimension = state.dimensions[id];

    dimension.preset = preset;
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  setDimension({ commit, state }, { id, minFrom, maxTo }) {
    if (state.dimensions[id]) return;

    commit('set-dimension', { id, minFrom, maxTo });
  },
  deleteDimension({ commit }, { id }) {
    commit('delete-dimension', { id });
  },

  setDateRange({ commit }, { id, from, to }) {
    commit('set-date-range-buffer', { id, from, to });
  },

  setPreset({ commit }, { id, preset }) {
    commit('set-preset', { id, preset });
  },

  sync({ commit, state }, { id, isSetByUser }) {
    const { from, to } = state.dimensions[id].buffer;

    commit('set-date-range', { id, from, to, isSetByUser });
  },
};

export const getters = {
  isSynced(state) {
    return id => {
      const dimension = state.dimensions[id];

      return (
        dimension.from === dimension.buffer.from &&
        dimension.to === dimension.buffer.to
      );
    };
  },

  ready(state) {
    return id => {
      const dimension = state.dimensions[id];

      return (
        Boolean(dimension) && Boolean(dimension.from) && Boolean(dimension.to)
      );
    };
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
