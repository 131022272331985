<template>
  <div class="discrete-gradient-bar" :style="{ background }" />
</template>

<script>
const buildColor = (color, fraction) => `${color} ${fraction * 100}%`;

export default {
  name: 'DiscreteGradientBar',
  props: {
    gradient: { type: Array, required: true },
  },
  computed: {
    background() {
      if (this.gradient.length === 0) return;
      else if (this.gradient.length === 1) return this.gradient[0][0];
      else
        return `linear-gradient(${_.reduce(
          this.gradient,
          (gradient, [color, fraction], index) => {
            if (index === 0) gradient += `,${buildColor(color, fraction)}`;
            else {
              const previous = buildColor(color, this.gradient[index - 1][1]);

              gradient +=
                index === this.gradient.length
                  ? `,${previous}`
                  : `,${previous},${buildColor(color, fraction)}`;
            }

            return gradient;
          },
          'to right'
        )})`;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$default-height: 4px

.discrete-gradient-bar
  width: 100%
  height: var(--height, $default-height)
</style>
