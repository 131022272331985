<template>
  <div class="l-unauthenticated">
    <Header v-if="!options.hideHeader" />

    <div class="l-unauthenticated__body">
      <div class="l-unauthenticated__slot-wrapper">
        <slot />
      </div>
    </div>

    <div class="l-unauthenticated__footer">
      <t center>
        {{ $t('g.copyrights-message-1') }}
        <t b>{{ $t('g.company') }}</t>
        {{ $t('g.copyrights-message-2') }}
      </t>
    </div>
    <div class="l-unauthenticated__footer-placeholder" />
  </div>
</template>

<script>
import mixin from '@/layouts/mixin';

// components
import Header from '@/components/ui/Header/mobile.vue';

export default {
  name: 'LUnauthenticated',
  components: { Header },
  mixins: [mixin],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.l-unauthenticated
  height: 100%

.header + .l-unauthenticated__body
  height: calc(100% - #{$header-height + $footer-height})

.l-unauthenticated__body
  position: relative
  z-index: #{$z-index-base + 1}

  height: calc(100% - #{$footer-height})

.l-unauthenticated__slot-wrapper
  height: 100%

.l-unauthenticated__footer
  height: $footer-height

  padding: $spacing-3 $spacing-4

  +d-flex-r(center, center)
  +p-fixed(null, initial, 0, 0)

.l-unauthenticated__footer-placeholder
  height: $footer-height
</style>
