import Vue from 'vue';
import Vuex from 'vuex';
import { tokens } from '@/helpers';
import {
  authentication,
  collections,
  download,
  map,
  time,
  ui,
  viewport,
} from './modules';

Vue.use(Vuex);

const buildInitialState = () => {
  const locale = 'es';

  moment.locale(locale);

  return {
    theme: 'light',
    locale,
  };
};

const state = Object.assign({ ready: false }, buildInitialState());

const mutations = {
  ready(state) {
    if (!state.ready) state.ready = true;
  },

  'set-locale'(state, { locale }) {
    state.locale = locale;

    moment.locale(locale);
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');

    Object.keys(modules).forEach(namespace => {
      if (!modules[namespace].mutations.reset) return;

      commit(`${namespace}/reset`);
    });
  },
};

const getters = {};

const modules = {
  authentication,
  collections,
  download,
  map,
  time,
  ui,
  viewport: viewport.module,
};

const plugins = [
  viewport.plugin({
    breakpoints: {
      xs: tokens.breakpoints('xs'),
      sm: tokens.breakpoints('sm'),
      md: tokens.breakpoints('md'),
      lg: tokens.breakpoints('lg'),
      xl: tokens.breakpoints('xl'),
    },
    delay: 100,
    maxDelay: 100,
  }),
];

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,

  modules,
  plugins,
});
