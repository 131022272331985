export const REMOTE_AGGREGATIONS_KEY = 'aggregations';
export const REMOTE_FIELDS_KEY = 'value_field';
export const REMOTE_GRANULARITIES_KEY = 'frequencies';

export const GROUP_BY_FIELD_TO_FORMAT_MAPPING = {
  subgroup_by_day_of_week: 'ddd',
  subgroup_by_hour: 'HH',
};

export const REMOTE_GRANULARITY_TO_GROUP_BY_FIELD_MAPPING = {
  DAILY: 'subgroup_by_hour',
  WEEKLY: 'subgroup_by_day_of_week',
};
