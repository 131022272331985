<template>
  <div class="range">
    <div
      ref="thumb"
      v-hammer:pan.start="onTouchStart"
      v-hammer:panmove="onTouchMove"
      v-hammer:panend="onTouchEnd"
      class="range__thumb-wrapper"
    >
      <slot name="thumb"><div class="range__thumb"/></slot>

      <slot />
    </div>

    <DiscreteGradientBar :gradient="gradient" class="range__bar" />
  </div>
</template>

<script>
import { getElWidth, parseTranslateX } from '@/helpers';

// components
import DiscreteGradientBar from './../DoubleRange/DiscreteGradientBar.vue';

const getTouchX = ({ srcEvent: e }) => e.pageX || e.touches[0].pageX;

export default {
  name: 'Range',
  components: { DiscreteGradientBar },
  props: {
    value: { type: Number, required: true },

    max: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
    step: { type: Number, default: 0.01 },

    highlighted: { type: Boolean, default: false },
  },
  data() {
    return {
      startTouchX: NaN,
      startTranslateX: NaN,
      touchX: NaN,

      dragging: false,
      draggingTarget: null,

      translateX_: NaN,

      emitValue: (() => {
        const DEBOUNCE_OPTIONS = { leading: true, trailing: false };
        const DEBOUNCE_WAIT = 100;

        const emitValue = value => this.$emit('input', value);
        const f = _.debounce(emitValue, DEBOUNCE_WAIT, DEBOUNCE_OPTIONS);

        let lastEmittedValue;

        return value => {
          if (value !== lastEmittedValue) f.cancel();

          f(value);

          lastEmittedValue = value;
        };
      })(),
    };
  },
  computed: {
    nValues() {
      return this.normalizeValue(this.max);
    },
    stepWidth() {
      return this.getWidth() / (this.nValues / this.step);
    },

    translateX: {
      get() {
        return this.translateX_;
      },
      set(newTranslateX) {
        const previousTranslateX = this.translateX_;
        newTranslateX = this.sanitizeTranslateX(newTranslateX);

        if (newTranslateX !== previousTranslateX) {
          const { thumb } = this.$refs;

          this.translateX_ = newTranslateX;

          if (thumb) thumb.style.transform = `translateX(${this.translateX}px)`;
        }

        this.emitValue(this.getTranslateAsValue(this.translateX));
      },
    },

    gradient() {
      const background = ['var(--bar-color)', 1];

      return this.highlighted
        ? [
            [
              'var(--highlight-color)',
              this.normalizeValue(this.value) / this.nValues,
            ],
            background,
          ]
        : [background];
    },
  },
  watch: {
    value: 'syncTranslateToValue',

    dragging() {
      this.$emit('dragging-change', { target: this.draggingTarget });
    },
  },
  mounted() {
    this.syncTranslateToValue();
  },
  methods: {
    getWidth() {
      return this.$el ? getElWidth(this.$el) : NaN;
    },

    clampValue(value) {
      return _.clamp(value, this.min, this.max);
    },
    clampTranslateX(translateX) {
      return _.clamp(translateX, 0, this.getWidth());
    },

    sanitizeValue(value) {
      value = this.clampValue(value);

      return this.clampValue(_.round(value / this.step) * this.step);
    },
    sanitizeTranslateX(translateX) {
      translateX = this.clampTranslateX(translateX);

      return this.clampTranslateX(
        _.round(translateX / this.stepWidth) * this.stepWidth
      );
    },

    normalizeValue(value) {
      return value - this.min;
    },

    getValueAsTranslate(value) {
      return this.sanitizeTranslateX(
        (this.normalizeValue(value) / this.nValues) * this.getWidth()
      );
    },
    getTranslateAsValue(translateX) {
      return this.sanitizeValue(
        (translateX / this.getWidth()) * this.nValues + this.min
      );
    },

    syncTranslateToValue() {
      this.translateX = this.getValueAsTranslate(this.value);
    },

    update() {
      requestAnimationFrame(this.update);

      if (!this.dragging) return;

      this.translateX = this.sanitizeTranslateX(
        this.touchX - this.startTouchX + this.startTranslateX
      );
    },

    onTouchStart(he) {
      this.startTouchX = getTouchX(he);
      this.startTranslateX = parseTranslateX(this.$refs.thumb.style.transform);
      this.touchX = this.startTouchX;

      this.dragging = true;
      this.draggingTarget = he.target;

      requestAnimationFrame(this.update);
    },
    onTouchMove(he) {
      this.touchX = getTouchX(he);
    },
    onTouchEnd() {
      this.draggingTarget = null;
      this.dragging = false;

      this.syncTranslateToValue();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$bar-height: 4px

$thumb-border-width: 2px
$thumb-size: 12px

$height: $thumb-size + $thumb-border-width
$z-index: $z-index-base

.range
  width: 100%
  height: $height

  cursor: pointer
  pointer-events: none

  +p-relative($z-index)

.range__thumb-wrapper
  display: inline-block

  cursor: pointer
  pointer-events: auto

  +p-relative(#{$z-index + 2})

.range__thumb
  border-radius: $b-radius-circle
  border-style: solid
  border-width: $thumb-border-width
  box-sizing: content-box

  transform: translateX(-50%)

  will-change: transform

  +size($thumb-size)

.range__bar
  top: ($height - $bar-height) / 2

  width: 100%
  height: $bar-height

  +p-absolute(#{$z-index + 1})

// color
.range
  --highlight-color: var(--c-primary)

  --bar-color: var(--c-gray-1)
  --thumb-color: var(--c-white)

.range__thumb
  background-color: var(--thumb-color)
  border-color: var(--highlight-color)
</style>
