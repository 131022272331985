import { PlayerState } from '@/components/the-big-ones/Player/State';

export class PayloadMiddleware {
  service: Service;
  dateRange: DateRange;
  playerState: PlayerState;

  constructor(
    service: Service,
    dateRange: DateRange,
    playerState: PlayerState
  ) {
    this.service = service;
    this.dateRange = dateRange;
    this.playerState = playerState;
  }
}

export default PayloadMiddleware;
