<template>
  <component :is="component" v-bind="bind" v-on="$listeners">
    <slot />

    <template v-slot:header-title="props">
      <slot name="header-title" v-bind="props" />
    </template>

    <template v-slot:option="props">
      <slot name="option" v-bind="props" />
    </template>
  </component>
</template>

<script>
// components
import InputDate from './calendar/InputDate.vue';
import InputDateRange from './calendar/InputDateRange.vue';
import InputSelect from './InputSelect.vue';
import InputText from './InputText.vue';

const COMPONENTS = Object.freeze({
  'date-range': InputDateRange,

  date: InputDate,

  email: InputText,
  password: InputText,
  select: InputSelect,
  text: InputText,
});

export default {
  name: 'EInput',
  props: {
    type: { type: String, default: 'text' },
  },
  computed: {
    component() {
      return COMPONENTS[this.type];
    },

    bind() {
      return Object.assign({}, this.$props, this.$attrs);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
