import {
  isGridManifest,
  isPresetManifest,
  isTabsManifest,
} from '@/components/the-big-ones/Dashboard/helpers';
import parsePresetManifest from '@/charting/presets';

import validators from './validators';

const isContainerManifest = (manifest: any) =>
  isGridManifest(manifest) || isTabsManifest(manifest);

const parseManifest = (id: Id, manifest: any): any => {
  if (isPresetManifest(manifest))
    return parseManifest(id, parsePresetManifest(id, manifest));

  if (isContainerManifest(manifest)) {
    return {
      ...manifest,

      // @ts-ignore
      areas: (isGridManifest(manifest) ? _.mapValues : _.map)(
        manifest.areas,
        (area: any) => parseManifest(id, area)
      ),
    };
  }

  return manifest;
};

export function manifest(remoteManifest: any) {
  if (!validators.remoteManifest(remoteManifest)) return;

  const copy = _.cloneDeep(remoteManifest);

  const manifest = {
    ...copy,
    dashboard: parseManifest(
      remoteManifest.project_id,
      remoteManifest.dashboard
    ),
    services: _.mapValues(remoteManifest.services, (service, service_id) =>
      parseManifest(service_id, service)
    ),
  };

  return manifest;
}

export default manifest;
