import Raw from './Raw';

export class CounterRaw extends Raw {
  build({ title }) {
    const type = 'BarChart';
    const datasets = [
      {
        metrics: [
          {
            aggregation: 'SUM',
            field: 'conteo-in',
          },
        ],

        label: {
          es: 'Camiones entrando',
          en: 'Entering trucks',
        },
        options: {
          color: 'green',
        },
      },
      {
        metrics: [
          {
            aggregation: 'SUM',
            field: 'conteo-out',
          },
        ],

        label: {
          es: 'Camiones saliendo',
          en: 'Leaving trucks',
        },
        options: {
          color: 'red',
        },
      },
    ];

    return {
      title,

      data_address_parameters: {
        datasets: datasets.map(dataset =>
          this.buildDatasetFromDataset(dataset)
        ),
      },

      type,
    };
  }
}

export default CounterRaw;
