<template>
  <div class="download-drawer__video-image">
    <div class="v-spacing-4 p-4">
      <DownloadDrawerVideoImagePreview
        :service-id="payload.service.id"
        :date-range="payload.dateRange"
        :current-time="Number(form.currentTime)"
        :process="
          form.withDetections === 'true'
            ? $options.PROCESSES.default
            : $options.PROCESSES.default
        "
      />

      <DownloadDrawerQualityInput
        v-model="form.quality"
        :options="qualitiesAsOptions"
      />

      <DownloadDrawerWithDetectionsInput
        :value="String(form.withDetections)"
        disabled
      />

      <FormOverlay
        :status="status"
        @cancel="$emit('cancel')"
        @clear="clear"
        @retry="$emit('retry')"
      >
        <template v-if="status === 'pending'">
          {{ $t(`${$options.path}.message.pending`) }}
        </template>

        <template v-if="status === 'success'">
          {{ $t(`${$options.path}.message.success`) }}
        </template>

        <template v-if="status === 'failure'">
          {{ $t(`${$options.path}.message.failure`) }}
        </template>
      </FormOverlay>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Form from './../Form';
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';
import { qualitiesAsOptions } from './../helpers.ts';

// components
import DownloadDrawerQualityInput from '@/components/ui/DownloadDrawer/inputs/Quality.vue';
import DownloadDrawerVideoImagePreview from './Preview.vue';
import DownloadDrawerWithDetectionsInput from '@/components/ui/DownloadDrawer/inputs/WithDetections.vue';

export default {
  name: 'DownloadDrawerVideoImageForm',
  components: {
    DownloadDrawerQualityInput,
    DownloadDrawerVideoImagePreview,
    DownloadDrawerWithDetectionsInput,
  },
  mixins: [Form],
  computed: {
    ...mapState('download', {
      payload: state => state.payload,

      qualities: state => state.videoImage.qualities,
      disabledQualities: state => state.videoImage.disabledQualities,

      storeForm: state => ({
        currentTime: state.videoImage.payload.currentTime,

        quality: state.videoImage.payload.quality,
        withDetections: state.videoImage.payload.withDetections,
      }),

      status: state => state.videoImage.status,
    }),

    qualitiesAsOptions() {
      const { qualities, disabledQualities } = this;

      return qualitiesAsOptions(qualities, disabledQualities);
    },
  },
  methods: {
    ...mapMutations('download', {
      clear: 'clear-video-image',
      setPayload: 'set-video-image-payload',
    }),

    syncDown() {
      const { storeForm } = this;

      this.form = {
        currentTime: String(storeForm.currentTime),

        quality: String(storeForm.quality),
        withDetections: String(storeForm.withDetections),
      };
    },
    syncUp() {
      const { form } = this;

      this.setPayload({
        currentTime: Number(form.currentTime),

        quality: Number(form.quality),
        withDetections: form.withDetections === 'true',
      });
    },
  },

  path: 'c.download-drawer',

  PROCESSES,
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$z-index: $z-index-base

.download-drawer__video-image
  +p-relative($z-index)

  & > .download-drawer__form-overlay
    z-index: #{$z-index + 1}
</style>
