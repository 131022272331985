import Vue from 'vue';

export const deepVueSet = (target, path, objectValue) => {
  let currentVertex = target;

  const pathAsArray = _.toPath(path);
  pathAsArray.forEach((edge, index) => {
    let nextVertex = _.get(currentVertex, edge);
    if (!nextVertex) {
      Vue.set(currentVertex, edge, {});
      nextVertex = _.get(currentVertex, edge);

      if (index === pathAsArray.length - 1)
        Object.keys(objectValue).forEach(key =>
          Vue.set(nextVertex, key, objectValue[key])
        );
    }

    currentVertex = nextVertex;
  });

  return currentVertex;
};
