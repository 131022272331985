<template>
  <MenuUnreadNotifications class="menu-activator">
    <template v-slot:highlighted>
      <EButton v-bind="buttonBind" @click="onClick">menu</EButton>
    </template>
  </MenuUnreadNotifications>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import MenuUnreadNotifications from './MenuUnreadNotifications.vue';

export default {
  name: 'MenuActivator',
  components: { MenuUnreadNotifications },
  computed: {
    ...mapState('ui', { isOpen: state => state.menu.isOpen }),

    buttonBind() {
      const bind = { navigation: true, md: true };

      return Object.assign(bind, this.$attrs);
    },
  },
  methods: {
    ...mapActions('ui', ['openMenu', 'closeMenu']),

    onClick() {
      if (this.isOpen) this.closeMenu();
      else this.openMenu();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
