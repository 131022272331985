<template>
  <div class="l-with-header">
    <div class="l-with-header__header">
      <GoDefaultLink />
    </div>

    <div class="l-with-header__body">
      <slot />
    </div>
  </div>
</template>

<script>
import mixin from '@/layouts/mixin';

// components
import GoDefaultLink from '@/components/ui/Header/GoDefaultLink.vue';

export default {
  name: 'LWithHeader',
  components: { GoDefaultLink },
  mixins: [mixin],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$padding: $spacing-3

.l-with-header
  display: grid
  grid-template-areas: 'header' 'body'
  grid-template-rows: auto 1fr

  height: 100%

.l-with-header__body
  grid-area: body

  overflow: hidden

.l-with-header__header
  grid-area: header

  +d-flex-r(center)

  & > *
    flex-shrink: 0

  & > *:first-child
    flex-basis: $left-width

// START - height --------------------------------------------------------------
.l-with-header__header
  height: $header-height
// END - height ----------------------------------------------------------------

// START - padding -------------------------------------------------------------
.l-with-header__header
  padding-right: $padding

  & > *:first-child
    padding-left: $padding
// END - padding ---------------------------------------------------------------
</style>
