type Segment = {
  color: string;
  zIndex: number;

  start: number;
  end: number;
};

type SegmentLimit = {
  type: 'start' | 'end';

  color: Segment['color'];
  zIndex: Segment['zIndex'];

  value: number;
};

type GradientSegment = [string, number];
type Gradient = GradientSegment[];

const buildSegment = (
  timestamps: Timestamp[],
  from: Timestamp,
  duration: number,
  step: number
): Segment => ({
  color: 'var(--c-gray-1)',
  zIndex: 1,

  start: timestamps[0].diff(from) / duration,
  end: (timestamps[timestamps.length - 1].diff(from) + step) / duration,
});

export const parseFilenames = (filenames: string[]) => {
  const timestamps = _.map(filenames, filename =>
    moment.parseZone(_.replace(filename, /\./g, ':'))
  );

  return {
    from: timestamps[0],
    to: timestamps[timestamps.length - 1],

    timestamps,
  };
};

export const getSegments = (
  {
    from,
    to,

    timestamps,
  }: {
    from: Timestamp;
    to: Timestamp;

    timestamps: Timestamp[];
  },
  step: number
) => {
  const segments: Segment[] = [];
  if (timestamps.length === 0) return segments;
  const duration = to.diff(from) + step;

  let bag: Timestamp[] = [];

  const pushAndFlush = () => {
    segments.push(buildSegment(bag, from, duration, step));

    bag = [];
  };

  _.forEach(timestamps, (timestamp, index, { length }) => {
    const pivot = _.last(bag);

    if (_.isUndefined(pivot)) bag.push(timestamp);
    else {
      const threshold = moment(pivot).add(step, 'milliseconds');
      const isInGroup = timestamp.isSameOrBefore(threshold);

      if (!isInGroup) pushAndFlush();

      bag.push(timestamp);
    }

    if (index === length - 1) pushAndFlush();
  });

  return segments;
};

const getSegmentsLimits = (
  segments: Segment[],
  defaultZIndex: number
): SegmentLimit[] =>
  _.orderBy(
    _.flatMap(segments, segment => {
      const { color, zIndex } = segment;

      return [
        {
          color,
          zIndex: zIndex || defaultZIndex,

          type: 'start',
          value: segment.start,
        },
        {
          color,
          zIndex: zIndex || defaultZIndex,

          type: 'end',
          value: segment.end,
        },
      ];
    }),
    'value'
  );

export const buildGradientFromSegments = (segments: Segment[]) => {
  const DEFAULT_Z_INDEX = 0;

  const gradient: Gradient = [];

  segments.push({
    color: 'var(--c-black)',
    zIndex: DEFAULT_Z_INDEX,

    start: 0,
    end: 1,
  });

  const stackDepth =
    _.maxBy(segments, ({ zIndex }) => zIndex)?.zIndex || DEFAULT_Z_INDEX;
  const stack: (string | null)[] = _.times(stackDepth + 1, _.constant(null));

  let stackColor: string | null;

  const limits: SegmentLimit[] = getSegmentsLimits(segments, DEFAULT_Z_INDEX);

  _.orderBy(limits, ['value', 'zIndex'], ['asc', 'desc']).forEach(limit => {
    stack[limit.zIndex] = limit.type === 'start' ? limit.color : null;

    if (gradient.length > 0 && limit.value === gradient[gradient.length - 1][1])
      return;

    const newStackColor = _.findLast(stack, _.negate(_.isNull));
    if (stackColor && stackColor !== newStackColor)
      gradient.push([stackColor, limit.value]);

    if (!_.isUndefined(newStackColor)) stackColor = newStackColor;
  });

  return gradient;
};
