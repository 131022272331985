import { mapsAPI } from '@/main';
import {
  buildImageOverlay,
  buildInstance,
  buildMapsAPIMarker,
  buildVisionOverlay,
} from './helpers';

const buildInitialState = () => ({
  instance: null,
  parentEl: null,
  mapType: '',

  markers: [],
  overlays: [],
});

export const state = buildInitialState();

export const mutations = {
  'set-instance'(state, { instance, parentEl }) {
    state.instance = instance;
    state.parentEl = parentEl;
    state.mapType = instance.getMapTypeId();
  },

  'set-map-type'(state, { mapType }) {
    state.instance.setMapTypeId(mapType);
    state.mapType = mapType;
  },

  'add-markers'(state, { markers, maps }) {
    const { instance } = state;

    // mapsAPI markers
    const mapsAPIMarkers = markers.map(marker =>
      buildMapsAPIMarker(marker, maps)
    );

    mapsAPIMarkers.forEach(mapsAPIMarker => mapsAPIMarker.setMap(instance));
    state.markers.push(...mapsAPIMarkers);

    // mapsAPI markers vision overlays
    const overlays = _.filter(markers, 'orientation').map(marker =>
      buildVisionOverlay(marker, maps)
    );

    overlays.forEach(overlay => overlay.setMap(instance));
    state.overlays.push(...overlays);
  },

  'add-overlays'(state, { overlays, maps }) {
    const overlays_ = overlays.map(overlay => buildImageOverlay(overlay, maps));

    overlays_.forEach(overlay => overlay.setMap(state.instance));
    state.overlays.push(...overlays_);
  },

  'remove-all-markers'(state, { maps }) {
    const removeMarker = marker => {
      maps.event.clearInstanceListeners(marker);
      marker.setMap(null);
    };

    state.markers.forEach(removeMarker);
    state.markers = [];
  },

  'remove-all-overlays'(state) {
    const removeOverlay = overlay => overlay.setMap(null);

    state.overlays.forEach(removeOverlay);
    state.overlays = [];
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  async getInstance({ commit, state }, { parentEl }) {
    const isNewInstance = !state.instance;
    const instance = state.instance || (await buildInstance(parentEl));
    if (state.parentEl && parentEl !== state.parentEl) {
      const child = state.parentEl.querySelector('div');

      parentEl.appendChild(child);
    }

    commit('set-instance', { instance, parentEl });

    return isNewInstance;
  },

  async addMarkers({ commit, state }, { markers }) {
    const maps = await mapsAPI();
    const { instance } = state;
    if (!instance) return;

    // necessary to know when are all markers added
    const promise = new Promise(resolve => {
      const MAX_MS = 100;

      instance.addListenerOnce('idle', resolve);
      setTimeout(resolve, MAX_MS);
    });
    commit('add-markers', { markers, maps });

    return promise;
  },

  async addOverlays({ commit }, { overlays }) {
    const maps = await mapsAPI();

    commit('add-overlays', { overlays, maps });
  },

  async removeAllMarkers({ commit }) {
    const maps = await mapsAPI();

    commit('remove-all-markers', { maps });
  },

  async removeAllOverlays({ commit }) {
    const maps = await mapsAPI();

    commit('remove-all-overlays', { maps });
  },

  fitZoomToMarkers({ state }, payload) {
    const { instance } = state;
    if (!instance) return;

    const offset = payload ? payload.offset : {};

    return instance.fitZoomToMarkers(state.markers, offset);
  },

  panToWithOffset({ state }, { center, offset }) {
    const { instance } = state;
    if (!instance) return;

    return instance.panToWithOffset(center, offset);
  },

  toggleMapType({ commit, state }) {
    const { instance } = state;
    if (!instance) return;

    let mapType = instance.getMapTypeId();
    if (mapType === 'roadmap') mapType = 'satellite';
    else if (mapType === 'satellite') mapType = 'roadmap';

    commit('set-map-type', { mapType: mapType });
  },

  zoomIn({ state }) {
    const { instance } = state;
    if (!instance) return;

    instance.setZoom(instance.getZoom() + 1);
  },
  zoomOut({ state }, payload) {
    const { instance } = state;
    if (!instance) return;

    let zoom = 1;
    if (payload && payload.zoom) zoom = payload.zoom;

    instance.setZoom(instance.getZoom() - zoom);
  },

  async mountControls({ state }, { controls }) {
    const { instance } = state;
    if (!instance) return;

    controls.forEach(control => instance.mountControl(control));
  },

  async unmountControls({ state }, { controls }) {
    const { instance } = state;
    if (!instance) return;

    controls.forEach(control => instance.unmountControl(control));
  },
};

export const getters = {};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
