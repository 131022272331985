export const VARIANTS = {
  person: {
    aggregation: 'WORKDAY',
    field: 'person.duration',

    class_name: 'person',

    kpi_label: Object.freeze({
      es: 'Jornada',
      en: 'Workday',
    }),
    single_legend_label: Object.freeze({
      es: 'Duración',
      en: 'Duration',
    }),
  },

  worker: {
    aggregation: 'WORKDAY',
    field: 'workers.duration',

    class_name: 'workers',

    kpi_label: Object.freeze({
      es: 'Jornada',
      en: 'Workday',
    }),
    single_legend_label: Object.freeze({
      es: 'Duración',
      en: 'Duration',
    }),
  },

  machine: {
    aggregation: 'WORKDAY',
    field: 'truck.duration',

    class_name: 'truck',

    kpi_label: Object.freeze({
      es: 'Jornada',
      en: 'Workday',
    }),
    single_legend_label: Object.freeze({
      es: 'Duración',
      en: 'Duration',
    }),
  },
};
