<template>
  <div :class="className">
    <v-calendar v-bind="calendarBind" v-on="calendarListeners">
      <template v-slot:header-title="props">
        <slot name="header-title" v-bind="props" />
      </template>
    </v-calendar>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'InputDate',
  mixins: [mixin],
  data() {
    return {
      date: null,
    };
  },
  computed: {
    attributes() {
      const today = moment().toDate();

      return [
        { key: 'today', dates: today, dot: true },
        { key: 'date', dates: this.date.toDate(), highlight: true },
      ];
    },
    calendarBind() {
      return {
        attributes: this.attributes,

        minDate: this.minDate ? this.minDate.toDate() : undefined,
        maxDate: this.maxDate ? this.maxDate.toDate() : undefined,

        ...this.$attrs,
      };
    },
    calendarListeners() {
      return {
        dayclick: this.onDateClick,

        ...this.$listeners,
      };
    },

    className() {
      const className = 'input-date';

      return [
        className,
        { [`${className}--details-hidden`]: this.hideDetails },
      ];
    },
  },
  methods: {
    sync() {
      this.date = moment(this.value);
    },

    onDateClick({ date }) {
      date = moment(date);

      if (this.isValidDate(date))
        this.$parent.$emit('input', date.startOf('day'));
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

$message-height: 16px
$message-padding: 2px

=message
  font-family: $f-family-primary
  font-size: $f-size-sm
  line-height: $message-height

  height: $message-height
  padding: $message-padding

// base
.input-date
  position: relative

  padding-top: ($message-height + $message-padding) / 2
  padding-bottom: $message-height + $message-padding

.input-date__body
  border: 1px solid

  +d-flex-r(center)
  +h-rounded($input-height)

  .input-date__date
    flex: 1

  .input-date__date:nth-child(1)
    padding-right: $input-height / 4
    padding-left: $input-height / 2

  .input-date__date:nth-child(2)
    padding-right: $input-height / 2
    padding-left: $input-height / 4

.input-date__date
  position: relative

  font-family: $f-family-primary
  font-size: $f-size-sm

  height: 100%

  cursor: pointer

  +d-flex-r(center)

  &::before
    content: var(--label)

    position: absolute
    top: -50%

    transform: translateY(50%)

    +message

.input-date__calendar
  +d-flex-r(center, center)
  +p-fixed-top-left($z-index-calendar, 0)
  +size(100%)

.input-date > .vc-container
  $border-radius: 4px // FIX: move to ds

  $header-height: 40px
  $arrows-size: 24px

  font-family: $f-family-primary

  border-radius: initial
  border: initial

  .vc-arrows-container,
  .vc-header
    height: $header-height

  .vc-header
    padding: initial

    border-radius: $border-radius

  .vc-svg-icon
    +size($arrows-size)

  .vc-text-lg,
  .vc-text-sm
    font-size: $f-size-xs

  .vc-font-bold
    font-weight: 600

  .vc-title,
  .vc-weekday
    text-transform: capitalize

  .vc-rounded-full
    border-radius: $border-radius

.input-date
  &--details-hidden
    padding-bottom: initial

// color
.input-date
  .input-date__body
    background-color: var(--c-white)

    border-color: var(--c-gray-2)

    .input-date__date
      &::before
        color: var(--c-gray-2)

        background-color: var(--c-white)

.input-date__calendar
  background-color: rgba(0, 0, 0, .66)

.input-date > .vc-container
  --day-content-bg-color-focus: var(--c-primary)
  --day-content-bg-color-hover: #{rgba($c-primary, .16)}

  .vc-header
    // we can't use CSS3 variables on <rgba> function
    background-color: rgba($c-primary, .16)

  // focus date color
  .vc-day-content:focus
    color: var(--c-white)

  // color
  .vc-text-gray-500,
  .vc-text-gray-600,
  .vc-text-gray-800,
  .vc-text-gray-900
    color: var(--c-secondary)

  // selected background color
  // - date
  .vc-bg-blue-600
    background-color: var(--c-primary)

  // - range
  .vc-bg-blue-200
    // we can't use CSS3 variables on <rgba> function
    background-color: rgba($c-primary, .08)
</style>
