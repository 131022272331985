const buildInitialState = () => ({
  bottomDrawer: {
    isHidden: true,
    isOpen: false,
  },

  collectionIndex: {
    collectionIds: [],
    type: '',
  },

  map: {
    isHidden: true,
  },

  menu: {
    isOpen: false,
  },

  notifications: {
    isOpen: false,
  },

  servicePlayerDownloadDrawer: {
    service: null,
    dateRange: null,

    state: null,
    media: null,

    isOpen: false,
  },
});

export const state = buildInitialState();

export const mutations = {
  'set-bottom-drawer-is-hidden'(state, { isHidden }) {
    state.bottomDrawer.isHidden = isHidden;

    if (!isHidden) state.bottomDrawer.isOpen = false;
  },
  'set-bottom-drawer-is-open'(state, { isOpen }) {
    state.bottomDrawer.isOpen = isOpen;
  },

  'set-collection-index'(state, { collectionIds, type }) {
    state.collectionIndex.collectionIds = collectionIds;
    state.collectionIndex.type = type;
  },

  'set-map-is-hidden'(state, { isHidden }) {
    state.map.isHidden = isHidden;
  },

  'set-menu-is-open'(state, { isOpen }) {
    state.menu.isOpen = isOpen;
  },

  'set-notifications-is-open'(state, { isOpen }) {
    state.notifications.isOpen = isOpen;
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  showBottomDrawer({ commit }) {
    commit('set-bottom-drawer-is-hidden', { isHidden: false });
  },
  hideBottomDrawer({ commit }) {
    commit('set-bottom-drawer-is-hidden', { isHidden: true });
  },
  openBottomDrawer({ commit }) {
    commit('set-bottom-drawer-is-open', { isOpen: true });
  },
  closeBottomDrawer({ commit }) {
    commit('set-bottom-drawer-is-open', { isOpen: false });
  },

  showMap({ commit }) {
    commit('set-map-is-hidden', { isHidden: false });
  },
  hideMap({ commit }) {
    commit('set-map-is-hidden', { isHidden: true });
  },

  openMenu({ commit }) {
    commit('set-menu-is-open', { isOpen: true });
  },
  closeMenu({ commit, dispatch }) {
    commit('set-menu-is-open', { isOpen: false });
    dispatch('closeNotifications');
  },

  openNotifications({ commit }) {
    commit('set-notifications-is-open', { isOpen: true });
  },
  closeNotifications({ commit }) {
    commit('set-notifications-is-open', { isOpen: false });
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
};
