import { getDefaultRoute } from '@/router';
import store from '@/store';

export const isAuthenticated = () =>
  store.getters['authentication/isAuthenticated'];

export default [
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () =>
      import(
        /* webpackPreload: true */ '../../authentication/views/SignIn.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) next(to.query.redirect || getDefaultRoute());
      else next();
    },
    meta: {
      layout: {
        name: 'unauthenticated',
        options: {
          hideHeader: true,
        },
      },
    },
  },

  {
    path: '/send-reset-password',
    name: 'send-reset-password',
    component: () =>
      import(
        /* webpackChunkName: "send-reset-password" */ '../../authentication/views/SendResetPassword.vue'
      ),
    meta: {
      layout: 'unauthenticated',
    },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../../authentication/views/ResetPassword.vue'
      ),
    meta: {
      layout: 'unauthenticated',
    },
  },
];
