<template>
  <DownloadDrawerInputWrapper :label="$t(`${$options.path}.label`)">
    <FormulateInput
      type="odd-switch"
      name="with-detections"
      :label="$t(`${$options.path}.description`)"
      label-position="before"
      v-bind="$attrs"
    />
  </DownloadDrawerInputWrapper>
</template>

<script>
// components
import DownloadDrawerInputWrapper from '@/components/ui/DownloadDrawer/InputWrapper.vue';

export default {
  name: 'DownloadDrawerWithDetectionsInput',
  components: { DownloadDrawerInputWrapper },

  path: 'c.download-drawer.with-detections',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
