import firebase from 'firebase';
import { aggregate } from '@/aggregation';
import { GROUP_BY_FIELD_TO_FORMAT_MAPPING } from '../../constants';

export type GroupByField = 'subgroup_by_day_of_week' | 'subgroup_by_hour';

export type DataPointValue = number;
export type DataPoint = { x: string | moment.Moment; y: DataPointValue };
type AggregatableDataPoint = Overwrite<
  DataPoint,
  { y: { [key: string]: DataPointValue } }
>;

declare global {
  type Overwrite<T, U> = {
    [K in Exclude<keyof T, keyof U>]: T[K];
  } &
    U;

  type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
}

export const aggregateGroups = (
  array: AggregatableDataPoint[],
  groupByField: GroupByField,
  remoteAggregation: RemoteAggregation = 'SUM'
) => {
  const format = GROUP_BY_FIELD_TO_FORMAT_MAPPING[groupByField];
  const formatter = (string: string) => moment(string, format, 'en');

  return _.map(
    _.reduce(
      array,
      (result, { y }) => {
        _.forEach(y, (value, key) =>
          (result[key] || (result[key] = [])).push(value)
        );

        return result;
      },
      <{ [key: string]: DataPointValue[] }>{}
    ),
    (value, key) => ({
      x: formatter(key),
      y: aggregate(value, remoteAggregation),
    })
  );
};

export const getArrayFromQuerySnapshot = (
  querySnapshot: firebase.firestore.QuerySnapshot,
  callback: (
    doc: firebase.firestore.QueryDocumentSnapshot
  ) => PartialBy<DataPoint, 'x'>
) =>
  _.reduce(
    querySnapshot.docs,
    (array, doc) => {
      const dp = callback(doc);

      if (!_.isUndefined(dp.x)) array.push({ x: dp.x, y: dp.y });

      return array;
    },
    <DataPoint[]>[]
  );

export const getArrayFromQuerySnapshot2 = (
  querySnapshot: firebase.firestore.QuerySnapshot,
  callback: (
    doc: firebase.firestore.QueryDocumentSnapshot
  ) => PartialBy<AggregatableDataPoint, 'x'>
) =>
  _.reduce(
    querySnapshot.docs,
    (array, doc) => {
      const dp = callback(doc);

      if (!_.isUndefined(dp.x)) array.push({ x: dp.x, y: dp.y });

      return array;
    },
    <AggregatableDataPoint[]>[]
  );
