import DataMatrix from './DataMatrix';
import Metric from './Metric';

type MetricMatrix = InstanceType<typeof Metric>[];

export class MetricArray extends DataMatrix {
  matrix: MetricMatrix;

  aggregations: Aggregation[];
  fields: string[];
  granularities: Granularity[];

  fetching: boolean;

  constructor(matrix: MetricMatrix) {
    super(matrix);

    this.matrix = matrix;

    this.aggregations = [];
    this.fields = [];
    this.granularities = [];

    this.fetching = false;

    // binding
    _.bindAll(this, ['fetch', 'setup']);
  }

  get metrics() {
    return this.matrix;
  }

  async setup() {
    const { metrics } = this;

    await Promise.all(_.map(metrics, metric => metric.setup()));

    this.aggregations = _.intersection(..._.map(metrics, 'aggregations'));
    this.fields = _.intersection(..._.map(metrics, 'fields'));
    this.granularities = _.intersection(..._.map(metrics, 'granularities'));
  }

  async fetch(...payload: Parameters<InstanceType<typeof Metric>['fetch']>) {
    try {
      this.fetching = true;

      await Promise.all(
        _.map(this.metrics, metric => metric.fetch(...payload))
      );
    } finally {
      this.fetching = false;
    }
  }
}

export default MetricArray;
