<template>
  <div :style="style" :class="className">
    <template v-if="!inline">
      <div class="input-date-range__body" @click="isOpen = true">
        <div
          :style="{ '--label': `'${fromLabel}'` }"
          class="input-date-range__date"
        >
          {{ value.from.format(format) }}
        </div>
        -
        <div
          :style="{ '--label': `'${toLabel}'` }"
          class="input-date-range__date"
        >
          {{ value.to.format(format) }}
        </div>
      </div>

      <div
        v-show="isOpen"
        ref="calendar"
        class="input-date-range__calendar"
        @click="isOpen = false"
      >
        <v-calendar
          v-bind="calendarBind"
          @click.native.stop
          v-on="calendarListeners"
        />
      </div>
    </template>

    <template v-else>
      <v-calendar
        v-bind="calendarBind"
        @click.native.stop
        v-on="calendarListeners"
      />
    </template>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'InputDateRange',
  mixins: [mixin],
  props: {
    inline: { type: Boolean, default: false },

    isInvalid: { type: Boolean, default: false },
    error: { type: String, default: '' },

    fromLabel: { type: String, required: true },
    toLabel: { type: String, required: true },

    format: { type: String, default: 'DD MMM YYYY' },
  },
  data() {
    return {
      from: null,
      to: null,

      hoverTo: null,

      isOpen: false,
    };
  },
  computed: {
    attributes() {
      const today = moment().toDate();
      const dateRange = this.isChoosingTo
        ? { start: this.from.toDate(), end: this.hoverTo.toDate() }
        : { start: this.from.toDate(), end: this.to.toDate() };

      return [
        { key: 'today', dot: true, dates: today },
        { key: 'date-range', dates: dateRange, highlight: true },
      ];
    },
    calendarBind() {
      return Object.assign(
        {
          attributes: this.attributes,
          minDate: this.minDate ? this.minDate.toDate() : undefined,
          maxDate: this.maxDate ? this.maxDate.toDate() : undefined,
        },
        this.$attrs
      );
    },
    calendarListeners() {
      return Object.assign(
        {
          dayclick: this.onDateClick,
          daymouseenter: this.onDateHover,
        },
        this.$listeners
      );
    },

    isChoosingTo() {
      return Boolean(this.from) && !this.to;
    },

    style() {
      return Object.assign({}, this.error && { '--error': `'${this.error}'` });
    },
    className() {
      const className = 'input-date-range';

      return [
        className,
        {
          [`${className}--details-hidden`]: this.hideDetails,
          [`${className}--focused`]: this.isFocused,
          [`${className}--inline`]: this.inline,
          [`${className}--invalid`]: this.isInvalid,
          [`${className}--invalid-with-error`]: this.isInvalid && this.error,
        },
      ];
    },
  },
  watch: {
    isOpen() {
      const { calendar } = this.$refs;

      if (this.isOpen) document.body.appendChild(calendar);
      else this.$el.appendChild(calendar);
    },
  },
  methods: {
    sync() {
      this.from = this.value.from;
      this.to = this.value.to;

      // created without <to> and <hoverTo> assigned
      if (this.isChoosingTo) this.hoverTo = this.from;
    },

    onDateClick({ date }) {
      date = moment(date);
      if (!this.isValidDate(date)) return;

      if (this.isChoosingTo) {
        this.to = date;
        this.isOpen = false;

        // <to> before <from>... invert
        if (this.to.diff(this.from) < 0) {
          const from = this.from;

          this.from = this.to;
          this.to = from;
        }

        this.$parent.$emit('input', {
          from: this.from.startOf('day'),
          to: this.to.endOf('day'),
        });
      } else {
        this.from = date;
        this.to = null;

        this.$parent.$emit('input', { from: this.from.startOf('day') });
      }
    },
    onDateHover({ date }) {
      date = moment(date);
      if (!this.isValidDate(date)) return;

      this.hoverTo = date;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

$message-height: 16px
$message-padding: 2px

=message
  font-family: $f-family-primary
  font-size: $f-size-sm
  line-height: $message-height

  height: $message-height
  padding: $message-padding

// base
.input-date-range
  position: relative

  padding-top: ($message-height + $message-padding) / 2
  padding-bottom: $message-height + $message-padding

.input-date-range__body
  border: 1px solid

  +d-flex-r(center)
  +h-rounded($input-height)

  .input-date-range__date
    flex: 1

  .input-date-range__date:nth-child(1)
    padding-right: $input-height / 4
    padding-left: $input-height / 2

  .input-date-range__date:nth-child(2)
    padding-right: $input-height / 2
    padding-left: $input-height / 4

.input-date-range__date
  position: relative

  font-family: $f-family-primary
  font-size: $f-size-sm

  height: 100%

  cursor: pointer

  +d-flex-r(center)

  &::before
    content: var(--label)

    position: absolute
    top: -50%

    transform: translateY(50%)

    +message

.input-date-range__calendar
  +d-flex-r(center, center)
  +p-fixed-top-left($z-index-calendar, 0)
  +size(100%)

.input-date-range > .vc-container
  $border-radius: 4px // FIX: move to ds

  $header-height: 40px
  $arrows-size: 24px

  font-family: $f-family-primary

  border-radius: initial
  border: initial

  .vc-arrows-container,
  .vc-header
    height: $header-height

  .vc-header
    padding: initial

    border-radius: $border-radius

  .vc-svg-icon
    +size($arrows-size)

  .vc-text-lg,
  .vc-text-sm
    font-size: $f-size-xs

  .vc-font-bold
    font-weight: 600

  .vc-title,
  .vc-weekday
    text-transform: capitalize

  .vc-rounded-full
    border-radius: $border-radius

.input-date-range
  &--details-hidden
    padding-bottom: initial

  &--inline
    +v-padding(initial)

  &--invalid-with-error
    .input-date-range__body
      position: relative

      &::after
        content: var(--error)

        position: absolute
        top: 100%
        left: $input-height / 2

        +message

// color
.input-date-range
  .input-date-range__body
    background-color: var(--c-white)

    border-color: var(--c-gray-2)

    .input-date-range__date
      &::before
        color: var(--c-gray-2)

        background-color: var(--c-white)

.input-date-range__calendar
  background-color: rgba(0, 0, 0, .66)

  &--invalid
    .input-date-range__body
      border-color: var(--c-red)

      .input-date-range__date
        &::before
          color: var(--c-red)

  &--invalid-with-error
    .input-date-range__body
      &::after
        color: var(--c-red)

.input-date-range > .vc-container
  --day-content-bg-color-focus: var(--c-primary)
  --day-content-bg-color-hover: #{rgba($c-primary, .16)}

  .vc-header
    // we can't use CSS3 variables on <rgba> function
    background-color: rgba($c-primary, .16)

  // focus date color
  .vc-day-content:focus
    color: var(--c-white)

  // color
  .vc-text-gray-500,
  .vc-text-gray-600,
  .vc-text-gray-800,
  .vc-text-gray-900
    color: var(--c-secondary)

  // selected background color
  // - date
  .vc-bg-blue-600
    background-color: var(--c-primary)

  // - range
  .vc-bg-blue-200
    // we can't use CSS3 variables on <rgba> function
    background-color: rgba($c-primary, .08)
</style>
