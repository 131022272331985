export default {
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    this.isActive = true;
  },
  activated() {
    this.isActive = true;
  },
  deactivated() {
    this.isActive = false;
  },
};
