<template>
  <div v-if="ready" class="v-spacing-2 ph-2">
    <div
      v-for="{ icon, text } in summary"
      :key="text"
      class="d-flex-r d-flex-a-c h-spacing-3"
    >
      <icon>{{ icon }}</icon> <t sm medium>{{ text }}</t>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { asVideoTime } from '@/components/the-big-ones/Player/Controls/filters';

export default {
  computed: {
    ...mapState('download', {
      currentTime: state => state.videoImage.payload.currentTime,
      dateRange: state => state.payload.dateRange,
      quality: state => state.videoImage.payload.quality,
      service: state => state.payload.service,
    }),
    ...mapGetters('collections', ['getProjectById']),

    project() {
      const { service } = this;

      return !_.isNil(service) ? this.getProjectById(service.projectId) : null;
    },

    time() {
      const { asDate } = this.$time.format;

      return `
        ${asDate(this.dateRange.from)} / ${asDate(this.dateRange.to)}
        -
        ${asVideoTime(this.currentTime)}
      `;
    },

    metadata() {
      return `${this.quality}p`;
    },

    summary() {
      return [
        { icon: 'map-marker', text: this.project.name },
        { icon: 'video', text: this.service.name },
        { icon: 'calendar', text: this.time },
        { icon: 'info', text: this.metadata },
      ];
    },

    ready() {
      return !_.isNil(this.project);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
