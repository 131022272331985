import { app } from '@/config/firebase';
import { $SignOut } from '@/store/buses';
import { functions } from '@/helpers';

const buildInitialState = () => ({
  user: null,
  status: '',
});

export const state = buildInitialState();

export const mutations = {
  'set-user'(state, { user }) {
    state.user = user;
  },

  'sign-in-request'(state) {
    state.status = 'request';
  },

  'sign-in-success'(state) {
    state.status = 'success';
  },

  'sign-in-failure'(state) {
    state.status = 'failure';
  },

  'sign-out'(state) {
    state.user = null;
    state.status = '';
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  async signIn({ commit }, { email, password }) {
    try {
      commit('sign-in-request');

      const response = await app
        .auth()
        .signInWithEmailAndPassword(email, password);

      commit('set-user', { user: response.user });
      commit('sign-in-success');

      return response;
    } catch (error) {
      commit('sign-in-failure');

      throw error;
    }
  },

  async signOut({ commit, dispatch }) {
    $SignOut.next();

    // FIX: wait until all connections are closed
    await dispatch('reset', undefined, { root: true });
    await app.auth().signOut();
    commit('sign-out');
  },

  async sendResetPassword(undefined, { email, isNewUser }) {
    return !isNewUser
      ? app.auth().sendPasswordResetEmail(email)
      : functions.sendWelcome({ email });
  },

  verifyResetPasswordCode(undefined, { actionCode }) {
    const auth = app.auth();

    return auth.verifyPasswordResetCode(actionCode);
  },

  confirmPasswordReset(undefined, { actionCode, newPassword }) {
    const auth = app.auth();

    return auth.confirmPasswordReset(actionCode, newPassword);
  },

  async resetPassword({ dispatch }, payload) {
    // https://firebase.google.com/docs/auth/custom-email-handler

    let response;

    response = await dispatch('verifyResetPasswordCode', payload);
    response = await dispatch('confirmPasswordReset', payload);

    return response;
  },
};

export const getters = {
  isAuthenticated(state) {
    return state.status === 'success';
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
