<template>
  <div v-show="!isEmpty" class="portal-target-with-overlay">
    <div v-if="!isEmpty" class="portal-target-with-overlay__overlay" />

    <portal-target
      class="portal-target-with-overlay__portal-target"
      v-bind="$attrs"
      v-on="listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'PortalTargetWithOverlay',
  data() {
    return {
      isEmpty: true,
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, { change: this.change_ });
    },
  },
  methods: {
    change_(...e) {
      const [isNotEmpty] = e;

      this.isEmpty = !isNotEmpty; // lol

      // just bubbling
      const { change } = this.$listeners;
      if (change) change(...e);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.portal-target-with-overlay__overlay
  opacity: .61 // FIX

  +p-fixed($z-index-base, 0)

.portal-target-with-overlay__portal-target
  +p-relative(#{$z-index-base + 1})

// color
.portal-target-with-overlay__overlay
  background-color: #2E2F30 // FIX
</style>
