<template>
  <div v-if="isActive" class="tab-body"><slot /></div>
</template>

<script>
import Groupable from './Groupable';

export default {
  name: 'TabBody',
  mixins: [Groupable],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
