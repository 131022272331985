import i18n from '@/i18n';
import { downloadByAnchor, functions, isURI, time } from '@/helpers';

import DownloadPromise from './DownloadPromise.ts';
import VideoImagePayloadMiddleware from './VideoImagePayloadMiddleware.ts';
import VideoPayloadMiddleware from './VideoPayloadMiddleware.ts';
import { NOTIFICATION_STATUS } from './NotificationPromise';

const observableCustomizer = (objectValue, sourceValue, key, object) => {
  // lodash <merge With> customizer to work as a workaround for arrays
  // reactivity limitations.

  if (_.isArray(objectValue)) return (object[key] = sourceValue);
};

const VALIDATIONS = Object.freeze({
  canOpen: [
    {
      handler: payload => {
        const DISABLED_THRESHOLD_MONTHS = 2;

        const { from, to } = payload.dateRange;
        const months = moment.duration(to.diff(from)).months();

        return months <= DISABLED_THRESHOLD_MONTHS;
      },
      message: i18n.t('m.download.errors.date-range-threshold-exceeded'),
    },
  ],
});

const validate = (payload, validations) =>
  _.reduce(
    validations,
    (messages, validation) => {
      if (!validation.handler(payload)) messages.push(validation.message);

      return messages;
    },
    []
  );

export const buildInitialState = () => ({
  payload: {
    service: null,
    dateRange: null,
    playerState: null,
  },

  video: {
    uri: '',
    promise: null,

    types: [0, 1],

    playbackSpeeds: [],
    disabledPlaybackSpeeds: VideoPayloadMiddleware.DISABLED_PLAYBACK_SPEEDS,

    qualities: [],
    disabledQualities: VideoPayloadMiddleware.DISABLED_QUALITIES,

    middleware: null,
    payload: {
      media: null,

      type: null,

      date: null,
      timeRange: null,

      quality: null,
      playbackSpeed: null,
      withDetections: null,
    },

    status: 'idle',
  },
  videoImage: {
    uri: '',
    promise: null,

    qualities: [],
    disabledQualities: VideoImagePayloadMiddleware.DISABLED_QUALITIES,

    middleware: null,
    payload: {
      currentTime: null,

      quality: null,
      withDetections: null,
    },

    status: 'idle',
  },

  isOpen: false,
});

export const state = buildInitialState();

export const mutations = {
  'set-video'(state, { uri, status, promise }) {
    if (uri) state.video.uri = uri;
    if (status) state.video.status = status;
    if (promise) state.video.promise = promise;
  },
  'set-video-payload'(state, payload) {
    _.mergeWith(state.video.payload, payload, observableCustomizer);
  },
  'cancel-video'(state) {
    state.video.promise.cancel();
  },
  'clear-video'(state) {
    state.video.uri = '';
    state.video.status = 'idle';
  },

  'set-video-image'(state, { uri, status, promise }) {
    if (uri) state.videoImage.uri = uri;
    if (status) state.videoImage.status = status;
    if (promise) state.videoImage.promise = promise;
  },
  'set-video-image-payload'(state, payload) {
    _.mergeWith(state.videoImage.payload, payload, observableCustomizer);
  },
  'cancel-video-image'(state) {
    state.videoImage.promise.cancel();
  },
  'clear-video-image'(state) {
    state.videoImage.uri = '';
    state.videoImage.status = 'idle';
  },

  open(state, { service, dateRange, playerState }) {
    const { payload, video, videoImage } = state;

    playerState.player.pause();

    payload.service = service;
    payload.dateRange = dateRange;
    payload.playerState = playerState;

    video.middleware = new VideoPayloadMiddleware(
      service,
      dateRange,
      playerState
    );
    video.payload = video.middleware.form;
    video.qualities = playerState.qualities;
    video.playbackSpeeds = playerState.playbackSpeeds;

    videoImage.middleware = new VideoImagePayloadMiddleware(
      service,
      dateRange,
      playerState
    );
    videoImage.payload = videoImage.middleware.form;
    videoImage.qualities = playerState.qualities;

    state.isOpen = true;
  },

  close(state) {
    state.isOpen = false;
  },

  reset(state) {
    const initialState = buildInitialState();

    Object.keys(initialState).forEach(key => (state[key] = initialState[key]));
  },
};

export const actions = {
  generateVideo({ commit, state }) {
    commit('set-video', { status: NOTIFICATION_STATUS.pending });

    const payload = state.video.middleware.buildPayload();
    const promise = new DownloadPromise(() => functions.generateVideo(payload));

    promise
      .then(({ uri }) =>
        commit('set-video', { uri, status: NOTIFICATION_STATUS.success })
      )
      .catch(() => {
        if (promise.isCanceled) return;

        commit('set-video', { uri: '', status: NOTIFICATION_STATUS.failure });
      });

    commit('set-video', { promise });

    return promise;
  },

  async generateVideoImage({ commit, state }) {
    commit('set-video-image', { status: NOTIFICATION_STATUS.pending });

    const payload = state.videoImage.middleware.buildPayload();
    const promise = new DownloadPromise(() =>
      functions.generateVideoImage(payload)
    );

    promise
      .then(({ uri }) =>
        commit('set-video-image', { uri, status: NOTIFICATION_STATUS.success })
      )
      .catch(() => {
        if (promise.isCanceled) return;

        commit('set-video-image', {
          uri: '',
          status: NOTIFICATION_STATUS.failure,
        });
      });

    commit('set-video-image', { promise });

    return promise;
  },

  downloadVideo({ state }) {
    const EXTENSION = 'mp4';

    const { uri } = state.video;
    const filename = `${state.payload.service.name}.${EXTENSION}`;

    return downloadByAnchor(uri, filename);
  },
  downloadVideoImage({ state }) {
    const EXTENSION = 'jpg';

    const { uri } = state.videoImage;
    const filename = `${state.payload.service.name}.${EXTENSION}`;

    return downloadByAnchor(uri, filename);
  },
};

export const getters = {
  canOpenVideoWith() {
    return ({ service, dateRange, playerState, media }) =>
      !_.isNil(service) &&
      time.isValidDateRange(dateRange) &&
      !_.isNil(media) &&
      _.isFinite(playerState.quality) &&
      _.isFinite(playerState.playbackSpeed);
  },
  canOpenVideoImageWith() {
    return ({ service, dateRange, playerState }) =>
      !_.isNil(service) &&
      time.isValidDateRange(dateRange) &&
      _.isFinite(playerState.currentTime) &&
      _.isFinite(playerState.quality);
  },

  canOpenWith(state, getters) {
    return payload => {
      const canOpen =
        getters.canOpenVideoWith(payload) &&
        getters.canOpenVideoImageWith(payload);
      if (!canOpen) return false;
      const result = validate(payload, VALIDATIONS.canOpen);

      return result.length === 0 ? true : result;
    };
  },

  canOpen(state, getters) {
    return getters.canOpenWith({
      ...state.payload,

      media: state.video.payload.media,
    });
  },

  canDownloadVideo(state) {
    const { media, quality, playbackSpeed } = state.video.payload;

    return (
      !_.isNil(state.payload.service) &&
      time.isValidDateRange(state.payload.dateRange) &&
      !_.isNil(media) &&
      _.isFinite(quality) &&
      _.isFinite(playbackSpeed)
    );
  },
  canDownloadVideoImage(state) {
    const { currentTime, quality } = state.videoImage.payload;

    return (
      !_.isNil(state.payload.service) &&
      time.isValidDateRange(state.payload.dateRange) &&
      _.isFinite(currentTime) &&
      _.isFinite(quality)
    );
  },

  isURI(state) {
    const { uri } = state;

    return isURI(uri);
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
