import ConstrainedValue from './ConstrainedValue';

type Range = [number, number];

export class RangeValue extends ConstrainedValue {
  max: number;
  maxDifference: number;
  min: number;
  minDifference: number;

  constructor(
    value: Range,
    max: number,
    maxDifference: number,
    min: number,
    minDifference: number
  ) {
    super(value);

    this.max = max;
    this.maxDifference = maxDifference;
    this.min = min;
    this.minDifference = minDifference;
  }

  isValid(value: Range) {
    const difference = value[1] - value[0];

    return (
      value[0] < value[1] &&
      (_.inRange(value[0], this.min, this.max) || value[0] === this.max) &&
      (_.inRange(value[1], this.min, this.max) || value[1] === this.max) &&
      (_.inRange(difference, this.minDifference, this.maxDifference) ||
        difference === this.maxDifference)
    );
  }

  sanitize(value: Range, thumb: 'left' | 'right' | 'both') {
    let [left, right] = value;

    if (thumb === 'left')
      right = _.clamp(
        right,
        left + this.minDifference,
        left + this.maxDifference
      );
    else if (thumb === 'right')
      left = _.clamp(
        left,
        right - this.maxDifference,
        right - this.minDifference
      );
    else {
      const difference = right - left;

      if (left - this.min < this.max - right) {
        right = _.clamp(right, this.min + difference, this.max);
        left = right - difference;
      } else {
        left = _.clamp(left, this.min, this.max - difference);
        right = left + difference;
      }
    }

    return [left, right];
  }
}

export default RangeValue;
