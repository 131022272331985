import { isIterable } from '@/helpers';

import Dataset from './Dataset';
import { RemoteDataset } from './Dataset';

export class DatasetArray {
  datasets: InstanceType<typeof Dataset>[];
  groupBy?: boolean;

  aggregations: Aggregation[];
  fields: string[];
  granularities: Granularity[];

  fetching: boolean;

  constructor(datasets: InstanceType<typeof Dataset>[]) {
    this.datasets = datasets;
    this.groupBy = false;

    this.aggregations = [];
    this.fields = [];
    this.granularities = [];

    this.fetching = false;

    // binding
    _.bindAll(this, ['empty', 'fetch', 'fetch_', 'setup']);
  }

  asChartJsDatasets() {
    return this.datasets.map(dataset => dataset.asChartJsDataset());
  }

  async setup() {
    const { datasets } = this;
    const metrics = _.map(datasets, 'metrics');

    await Promise.all(_.map(datasets, dataset => dataset.setup()));

    this.aggregations = _.intersection(..._.map(metrics, 'aggregations'));
    this.fields = _.intersection(..._.map(metrics, 'fields'));
    this.granularities = _.intersection(..._.map(metrics, 'granularities'));
  }

  fetch_(...payload: Parameters<InstanceType<typeof Dataset>['fetch']>) {
    return Promise.all(
      _.map(this.datasets, dataset => dataset.fetch(...payload))
    );
  }

  async fetch(
    remoteDateRange:
      | { from: string; to: string }
      | Iterable<{ from: string; to: string }>,
    remoteGranularity: RemoteGranularity
  ) {
    try {
      this.fetching = true;

      if (!isIterable(remoteDateRange))
        await this.fetch_(
          remoteDateRange,
          remoteGranularity,
          true,
          this.groupBy
        );
      else {
        this.empty();

        for (const remoteDateRange_ of remoteDateRange)
          await this.fetch_(
            remoteDateRange_,
            remoteGranularity,
            false,
            this.groupBy
          );
      }
    } finally {
      this.fetching = false;
    }
  }

  empty() {
    this.datasets.forEach(dataset => dataset.empty());
  }

  static fromRemoteDatasets(
    remoteDatasets: RemoteDataset[],
    groupBy?: boolean
  ) {
    if (!_.isArray(remoteDatasets) && _.has(remoteDatasets, 'metrics'))
      remoteDatasets = [remoteDatasets];

    const datasetArray = new DatasetArray(
      _.map(remoteDatasets, (remoteDataset, keyOrIndex) => {
        const key = String(
          _.isPlainObject(remoteDatasets)
            ? keyOrIndex
            : remoteDataset.key || keyOrIndex
        );

        return Dataset.fromRemoteDataset(remoteDataset, key);
      })
    );
    datasetArray.groupBy = groupBy;

    return datasetArray;
  }
}

export default DatasetArray;
