<template>
  <div :class="className"><slot /></div>
</template>

<script>
export default {
  name: 'Dummy',
  props: {
    shimmer: { type: Boolean, default: false },
  },
  computed: {
    className() {
      const className = 'dummy';

      return [className, { [`${className}--shimmer`]: this.shimmer }];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.dummy--shimmer
  animation: shimmer 2s infinite

// color
.dummy
  background-color: var(--c-gray-1)

@keyframes shimmer
  0%
    background-color: #BDBDBD // FIX

  25%
    background-color: #EEEEEE // FIX

    animation-timing-function: linear

  100%
    background-color: #BDBDBD // FIX
</style>
