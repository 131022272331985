import { mapActions, mapState } from 'vuex';
import { parseTranslateY, tokens } from '@/helpers';

export default {
  props: {
    threshold: {
      type: Number,
      default: 32,
    },
  },
  data() {
    return {
      // anime animation
      animation: {
        instance: null,

        configuration: {
          easing: 'easeOutQuint',
          duration: tokens.durations('slowly'),
        },
      },

      // handcrafted animation (while dragging)
      startTouchY: null,
      startTranslateY: null,
      currentTouchY: null,

      isDragging: false,
    };
  },
  watch: {
    isOpen() {
      this.animate();
    },
  },
  computed: {
    ...mapState('ui', { isOpen: state => state.bottomDrawer.isOpen }),

    minTranslateY() {
      return -this.heights.open + this.heights.closed;
    },
  },
  methods: {
    ...mapActions('ui', ['openBottomDrawer', 'closeBottomDrawer']),

    // anime animation
    pause() {
      const { animation } = this;
      if (animation.instance) animation.instance.pause();
    },
    animate() {
      this.pause();

      const { animation } = this;
      const configuration = {
        targets: this.$el,
        ...animation.configuration,
      };

      if (this.isOpen) {
        animation.instance = this.$a({
          translateY: this.minTranslateY,
          ...configuration,
        });
      } else {
        animation.instance = this.$a({
          translateY: 0,
          ...configuration,
        });
      }
    },

    // handcrafted animation (while dragging)
    onTouchStart({ srcEvent: e }) {
      this.pause();

      this.startTouchY = e.pageY || e.touches[0].pageY;
      this.startTranslateY = parseTranslateY(this.$el.style.transform);
      this.currentTouchY = this.startTouchY;

      this.isDragging = true;

      requestAnimationFrame(this.update);
    },
    onTouchMove({ srcEvent: e }) {
      this.currentTouchY = e.pageY || e.touches[0].pageY;
    },
    onTouchEnd() {
      const translateY = this.currentTouchY - this.startTouchY;

      this.isDragging = false;

      if (Math.abs(translateY) > this.threshold)
        if (translateY < -this.threshold) this.openBottomDrawer();
        else this.closeBottomDrawer();
      else this.animate();
    },

    update() {
      requestAnimationFrame(this.update);

      if (this.isDragging) {
        const { startTouchY, startTranslateY, currentTouchY } = this;
        const translateY = currentTouchY - startTouchY + startTranslateY;

        if (this.minTranslateY < translateY && translateY < 0)
          this.$el.style.transform = `translateY(${translateY}px)`;
      }
    },
  },
};
