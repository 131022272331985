import CRCellPreset from './../CRCellPreset';

import { KPI_LABEL, LEGEND_LABEL } from './constants';
import { parseClassName, parseROI } from './../../helpers';

export class WorkDaySE extends CRCellPreset {
  constructor(id) {
    super(id);

    // binding
    _.bindAll(this, ['buildDatasets', 'buildEndMetric', 'buildStartMetric']);
  }

  buildStartMetric(className, roi) {
    return {
      id: `${this.id}-${roi}_${className}${
        this.suffix ? `-${this.suffix}` : ''
      }`,
      aggregation: this.aggregation,
      field: `${this.field}.start`,
    };
  }

  buildEndMetric(className, roi) {
    return {
      id: `${this.id}-${roi}_${className}${
        this.suffix ? `-${this.suffix}` : ''
      }`,
      aggregation: this.aggregation,
      field: `${this.field}.finish`,
    };
  }

  buildDatasets(classNames, rois) {
    classNames = _.map(
      classNames,
      className => parseClassName(className).className
    );
    rois = _.map(rois, roi => parseROI(roi).roi);

    return {
      start: {
        metrics: _.map(rois, roi => this.buildStartMetric(classNames[0], roi)),
        pipeline: 'between:min',

        label: LEGEND_LABEL.start,
        options: { color: 'green' },
      },
      end: {
        metrics: _.map(rois, roi => this.buildEndMetric(classNames[0], roi)),
        pipeline: 'between:max',

        label: LEGEND_LABEL.end,
        options: { color: 'red' },
      },
    };
  }

  buildKPIs() {
    return { aggregation: 'max', label: KPI_LABEL };
  }

  build(remotePayload) {
    const payload = this.parseRemotePayload(remotePayload);

    this.setup(payload);

    const type = 'LineChart';
    const kpis = this.buildKPIs();

    return {
      data_address_parameters: {
        datasets: this.buildDatasets(this.classNames, this.rois),
      },

      type,
      type_parameters: { isWorkDaySE: true },

      kpis,
    };
  }
}

export default WorkDaySE;
