<script>
const INTERNAL_BIND = Object.freeze({
  color: 'white',
  semibold: true,
  center: true,
});

export default {
  name: 'UnreadNotificationsCount',
  functional: true,
  props: {
    count: { type: Number, required: true },
    sm: { type: Boolean, default: undefined },
  },

  render(createElement, ctx) {
    const { count, sm } = ctx.props;
    const { staticStyle, staticClass } = ctx.data;

    const className = 'unread-notifications-count';
    const bind = Object.assign({}, INTERNAL_BIND, ctx.data.attrs);

    return count > 0
      ? createElement(
          't',
          {
            props: bind,
            class: [className, staticClass, { [`${className}--sm`]: sm }],
            style: staticStyle,
          },
          [count]
        )
      : undefined;
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.unread-notifications-count
  border-radius: 4px // FIX

// START - size ----------------------------------------------------------------
=size($width, $height)
  line-height: $height

  width: $width
  height: $height

// - md
$md-width: 32px
$md-height: 24px

.unread-notifications-count
  font-size: $f-size-sm

  +size($md-width, $md-height)

// - sm
$sm-width: 24px
$sm-height: 18px

.unread-notifications-count--sm
  font-size: $f-size-xs

  +size($sm-width, $sm-height)
// END - size ------------------------------------------------------------------

// color
.unread-notifications-count
  background-color: var(--c-red)
</style>
