<template>
  <div v-if="allProjectsReady" class="menu-projects">
    <div
      v-for="project in projects"
      :key="project.id"
      class="d-flex-r d-flex-a-c p-3"
    >
      <router-link
        :to="{ name: 'projects-show', params: { projectId: project.id } }"
        @click="closeMenu"
      >
        <DocLabel :doc="project" type="projects" />
      </router-link>

      <div class="spacer" />

      <ProjectsShowServicesIndexMapLink :project="project" @click="closeMenu" />
      <ProjectsShowSummaryLink
        :project="project"
        class="ml-2"
        @click="closeMenu"
      />
    </div>
  </div>

  <Progress v-else variant="circular" color="primary" class="h-margin-center" />
</template>

<script>
import { mapActions } from 'vuex';
import { WithAllProjects } from '@/mixins';

// components
import DocLabel from '@/components/collection/DocLabel.vue';
import ProjectsShowServicesIndexMapLink from '@/components/collection/projects/ProjectsShowServicesIndexMapLink.vue';
import ProjectsShowSummaryLink from '@/components/collection/projects/ProjectsShowSummaryLink.vue';

export default {
  name: 'MenuProjects',
  components: {
    DocLabel,
    ProjectsShowServicesIndexMapLink,
    ProjectsShowSummaryLink,
  },
  mixins: [WithAllProjects],
  methods: {
    ...mapActions('ui', ['closeMenu']),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
