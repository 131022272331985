export default {
  methods: {
    getItemKey(item) {
      return _.findIndex(
        this.$slots.default,
        vNode => vNode.componentInstance === item
      );
    },
  },
  provide() {
    const { getActiveItemKey, getItemKey } = this;

    return { getActiveItemKey, getItemKey };
  },
};
