<template>
  <div class="go-back-link h-spacing-3">
    <EButton v-bind="buttonBind" @click="onClick">arrow-thin-left</EButton>

    <slot />
  </div>
</template>

<script>
import { findRouteWithMeta } from '@/helpers';

export default {
  name: 'GoBackLink',
  computed: {
    backLocationByMeta() {
      const routeWithMeta = findRouteWithMeta(this.$route.matched, 'parent');
      if (!routeWithMeta) return;
      const {
        meta: { parent },
      } = routeWithMeta;

      return { name: parent };
    },
    backLocationByFullPath() {
      const chunks = _.split(this.$route.fullPath, '/').filter(Boolean);
      const newFullPath = `/${_.take(chunks, chunks.length - 1).join('/')}`;

      return { path: newFullPath };
    },

    buttonBind() {
      const bind = { navigation: true, md: true };

      return Object.assign(bind, this.$attrs);
    },
  },
  methods: {
    push(location) {
      location = { ...location };
      (location.query || (location.query = {})).backward = true;

      return this.$router.push(location);
    },

    async onClick() {
      if (!this.backLocationByMeta)
        return this.push(this.backLocationByFullPath);

      try {
        await this.push(this.backLocationByMeta);
      } catch (error) {
        this.push(this.backLocationByFullPath);
      }
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
