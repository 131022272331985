import CRContainerPreset from './CRContainerPreset';

export class Occupancies extends CRContainerPreset {
  static VALID_PROPERTIES = [
    'aggregation',
    'field',
    'suffix',

    'groupBy',
    'unit',
  ];

  aggregation;
  field;
  suffix;

  groupBy;
  unit;

  buildAreas() {
    return this.classNames.map(className => {
      return {
        key: className.value,

        preset: 'Occupancy',
        preset_parameters: {
          aggregation: this.aggregation,
          field: this.field,
          suffix: this.suffix,

          class_name: className.value,
          rois: this.rois,

          kpi_label: className.kpiLabel,

          group_by: this.groupBy,
          unit: this.unit,
        },
      };
    });
  }

  build(remotePayload) {
    const payload = this.parseRemotePayload(remotePayload);

    this.setup(payload);

    return {
      title: this.title,
      areas: this.buildAreas(),
    };
  }
}

export default Occupancies;
