<template>
  <div id="app" :class="[theme]">
    <component :is="layout" v-if="ready">
      <router-view />
    </component>

    <RefreshNotification />

    <portal-target name="app" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { findRouteWithMeta } from '@/helpers';

// layouts
import LAuthenticated from '@/layouts/Authenticated.vue';
import LDefault from '@/layouts/Default.vue';
import LUnauthenticated from '@/layouts/Unauthenticated.vue';
import LWithHeader from '@/layouts/WithHeader.vue';

// components
import RefreshNotification from '@/components/notifications/RefreshNotification.vue';

const LAYOUTS = Object.freeze({
  authenticated: LAuthenticated,
  default: LDefault,
  unauthenticated: LUnauthenticated,
  withHeader: LWithHeader,
});

export default {
  name: 'VApp',
  components: { RefreshNotification },
  computed: {
    ...mapState(['ready', 'theme']),

    layout() {
      const routeWithLayout = findRouteWithMeta(this.$route.matched, 'layout');

      if (routeWithLayout) {
        const { layout } = routeWithLayout.meta;

        return LAYOUTS[_.isPlainObject(layout) ? layout.name : layout];
      } else return LAYOUTS.default;
    },
  },
  watch: {
    async ready(current, previous) {
      const { redirect } = this.$route.query;

      if (current && !previous && redirect) await this.redirect();
    },
  },
  methods: {
    redirect() {
      const { redirect } = this.$route.query;

      return this.$router.replace(redirect).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
#app
  height: 100%

// color
#app
  background-color: var(--c-background)
</style>
