<template>
  <LeftDrawer class="download-drawer" :is-open="isOpen" @close="close">
    <template v-if="ready" v-slot:header>
      <t semibold>{{ $t(`${$options.path}.title`) }}</t>
    </template>

    <template v-if="ready" v-slot:default>
      <div class="download-drawer__body">
        <Tabs v-model="option" class="download-drawer__header">
          <Tab v-for="{ icon, text } in options" :key="text">
            <template v-slot:default="{ isActive }">
              <icon :color="isActive ? 'primary' : 'gray-2'" class="mr-2">
                {{ icon }}
              </icon>
              <t :color="isActive ? 'primary' : 'gray-2'" semibold>
                {{ text }}
              </t>
            </template>
          </Tab>
        </Tabs>

        <TabsBodies v-model="option" class="download-drawer__body-body">
          <TabBody v-for="{ text, component } in options" :key="text">
            <component
              :is="component"
              v-if="isOpen"
              @retry="onRetry"
              @cancel="onCancel"
            />
          </TabBody>
        </TabsBodies>

        <div class="download-drawer__footer pv-4 ph-3">
          <template v-if="option === 0">
            <DownloadDrawerVideoSummary />

            <EButton
              filled
              lg
              class="fluid mt-4"
              :disabled="!canDownloadVideo || status.video === 'pending'"
              @click="onSubmit"
            >
              {{ $t(`${$options.path}.action.generate`) }}
            </EButton>
          </template>

          <template v-else>
            <DownloadDrawerVideoImageSummary />

            <EButton
              filled
              lg
              class="fluid mt-4"
              :disabled="
                !canDownloadVideoImage || status.videoImage === 'pending'
              "
              @click="onSubmit"
            >
              {{ $t(`${$options.path}.action.download`) }}
            </EButton>
          </template>
        </div>
      </div>
    </template>
  </LeftDrawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import WithDownload from './WithDownload';

// components
import LeftDrawer from '@/components/ui/LeftDrawer.vue';
import Tab from '@/components/ui/Tabs/Tab.vue';
import TabBody from '@/components/ui/Tabs/TabBody.vue';
import Tabs from '@/components/ui/Tabs/Tabs.vue';
import TabsBodies from '@/components/ui/Tabs/TabsBodies.vue';
// - video
import DownloadDrawerVideoForm from './Video/Form.vue';
import DownloadDrawerVideoSummary from './Video/Summary.vue';
// - video image
import DownloadDrawerVideoImageForm from './VideoImage/Form.vue';
import DownloadDrawerVideoImageSummary from './VideoImage/Summary.vue';

export default {
  name: 'DownloadDrawer',
  components: {
    DownloadDrawerVideoImageSummary,
    DownloadDrawerVideoSummary,
    LeftDrawer,
    Tab,
    TabBody,
    Tabs,
    TabsBodies,
  },
  mixins: [WithDownload],
  data() {
    return {
      option: 0,
      options: Object.freeze([
        {
          icon: 'play',
          text: this.$t('g.video'),
          component: DownloadDrawerVideoForm,
        },
        {
          icon: 'picture',
          text: this.$t('g.image'),
          component: DownloadDrawerVideoImageForm,
        },
      ]),
    };
  },
  computed: {
    ...mapState('download', ['isOpen']),
    ...mapState('download', {
      status: state => ({
        video: state.video.status,
        videoImage: state.videoImage.status,
      }),
      isOpen: state => state.isOpen,
    }),
    ...mapGetters('download', [
      'canDownloadVideo',
      'canDownloadVideoImage',
      'canOpen',
    ]),

    ready() {
      return this.canOpen;
    },
  },
  methods: {
    ...mapActions('download', [
      'downloadVideo',
      'downloadVideoImage',
      'generateVideo',
      'generateVideoImage',
    ]),
    ...mapMutations('download', ['close']),
    ...mapMutations('download', {
      cancelVideo: 'cancel-video',
      clearVideo: 'clear-video',

      cancelVideoImage: 'cancel-video-image',
      clearVideoImage: 'clear-video-image',
    }),

    async download() {
      const { option } = this;

      if (option === 0) {
        if (await this.generateVideo()) return this.downloadVideo();
      } else if (await this.generateVideoImage())
        return this.downloadVideoImage();
    },
    cancel() {
      const { option } = this;

      return option === 0 ? this.cancelVideo() : this.cancelVideoImage();
    },
    clear() {
      const { option } = this;

      return option === 0 ? this.clearVideo() : this.clearVideoImage();
    },

    onCancel() {
      this.cancel();
      this.clear();
    },
    onRetry() {
      this.cancel();
      this.clear();
      this.download();
    },
    onSubmit() {
      this.clear();
      this.download();
    },
  },

  path: 'c.download-drawer',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$footer-border-width: 1px

.download-drawer__body
  display: grid
  grid-template-columns: auto
  grid-template-rows: auto 1fr auto

  height: 100%

.download-drawer__footer
  border-top-style: solid
  border-top-width: $footer-border-width

  .e-icon
    $size: 24px
    $svg-size: 16px

    --size: #{$size}

    padding: ($size - $svg-size) / 2

    border-radius: $b-radius-circle
    box-sizing: border-box

    & > svg
      +size($svg-size)

.download-drawer__body-body
  overflow-y: auto

  &,
  & > .tab-body,
  & > .tab-body > .download-drawer__video,
  & > .tab-body > .download-drawer__video-image
    height: 100%

// color
.download-drawer__footer
  border-top-color: var(--c-gray-1)

  .e-icon
    --color: var(--c-gray-2)

    background-color: var(--c-gray-0)
</style>
