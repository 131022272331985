<template functional>
  <div
    :style="{
      '--overlay-background-color': `var(--c-${
        typeof props.withOverlay === 'string' ? props.withOverlay : 'background'
      })`,
    }"
    :class="[
      'progress-odd',
      { 'progress-odd--with-overlay': Boolean(props.withOverlay) },
    ]"
  >
    <div class="progress-odd__grid">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressOdd',
  props: {
    withOverlay: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$cell-size: 13px
$grid-size: 64px

.progress-odd__grid
  position: relative

  +size($grid-size)

  & > div
    position: absolute

    border-radius: $b-radius-circle

    animation: odd 1.2s ease-in-out infinite

    +size($cell-size)

    // - rows
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3)
      top: 6px

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6)
      top: 26px

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9)
      top: 45px

    // - columns
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7)
      left: 6px

    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(8)
      left: 26px

    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9)
      left: 45px

    &:nth-child(1)
      animation-delay: 0s

    &:nth-child(2),
    &:nth-child(4)
      animation-delay: -0.4s

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7)
      animation-delay: -0.8s

    &:nth-child(6),
    &:nth-child(8)
      animation-delay: -1.2s

    &:nth-child(9)
      animation-delay: -1.6s

// - with overlay
.progress-odd--with-overlay
  $z-index: $z-index-base + 1

  +d-flex-r(center, center)
  +p-absolute($z-index, 0)

  &::before
    content: ''

    +p-absolute(#{$z-index - 1}, 0)

// color
.progress-odd__grid > div
  background-color: var(--c-white)

  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(9)
    background-color: #27EC5D

  &:nth-child(3),
  &:nth-child(8),
  &:nth-child(4)
    background-color: #FC186A

  &:nth-child(2),
  &:nth-child(6),
  &:nth-child(7)
    background-color: #FFE654

// - with overlay
.progress-odd--with-overlay::before
  background-color: var(--overlay-background-color)

@keyframes odd
  0%, 100%
    opacity: 1

  50%
    opacity: 0
</style>
