import Metric from './Metric';
import MetricArray from './MetricArray';
import Pipeline from './Pipeline';
import { RemoteMetric } from './Metric';
import { asRemoteAggregation } from '@/aggregation';
import DataMatrix from './DataMatrix';

export type RemoteDataset = {
  key: string;

  label: I18nText;

  metrics: RemoteMetric | RemoteMetric[];
  pipeline: string;

  options: {
    background?: boolean | { gradient?: boolean; color?: string };
    color?: string;
  };
};

const FOR_EACH_COLUMN_AGGREGATOR_KEY = 'between';
const FOR_EACH_ROW_AGGREGATOR_KEY = 'per';

const aggregators = {
  [FOR_EACH_COLUMN_AGGREGATOR_KEY]: (
    matrix: InstanceType<typeof DataMatrix>,
    remoteAggregation: RemoteAggregation
  ) => matrix.aggregate(remoteAggregation, 'columns'),
  [FOR_EACH_ROW_AGGREGATOR_KEY]: (
    matrix: InstanceType<typeof DataMatrix>,
    remoteAggregation: RemoteAggregation
  ) => matrix.aggregate(remoteAggregation, 'rows'),
};

export class Dataset {
  key: string;
  metrics: InstanceType<typeof MetricArray>;
  pipeline: InstanceType<typeof Pipeline>;
  label: I18nText;
  options: any;

  fetching: boolean;

  constructor(
    key: string,
    metrics: InstanceType<typeof Metric>[],
    pipeline: string,
    label: I18nText,
    options: RemoteDataset['options'] | undefined = undefined
  ) {
    this.key = key;
    this.metrics = new MetricArray(metrics);
    this.pipeline = new Pipeline(pipeline);
    this.label = label;
    this.options = options;

    this.fetching = false;

    // binding
    _.bindAll(this, [
      'aggregateWithPipeline',
      'asChartJsDataset',
      'empty',
      'fetch',
      'setup',
    ]);
  }

  aggregateWithPipeline() {
    let metrics: InstanceType<typeof DataMatrix> = this.metrics;

    this.pipeline.forEach(
      (aggregatorKey, aggregation) =>
        (metrics = aggregators[aggregatorKey](
          metrics,
          asRemoteAggregation(<Aggregation>String(aggregation))
        ))
    );

    return metrics;
  }

  asChartJsDataset() {
    const data = _.flatMap(this.aggregateWithPipeline().matrix, 'array');

    return {
      key: this.key,
      data,

      label: this.label,
      stack: 0,

      options: this.options,
    };
  }

  setup() {
    return this.metrics.setup();
  }

  async fetch(
    ...payload: Parameters<InstanceType<typeof MetricArray>['fetch']>
  ) {
    try {
      this.fetching = true;

      await this.metrics.fetch(...payload);
    } finally {
      this.fetching = false;
    }
  }

  empty() {
    this.metrics.matrix.forEach(metric => metric.empty());
  }

  static fromRemoteDataset(remoteDataset: RemoteDataset, key: string) {
    const { pipeline, label, options } = remoteDataset;

    let { metrics: remoteMetrics } = remoteDataset;
    if (!_.isArray(remoteMetrics)) remoteMetrics = [remoteMetrics];
    const metrics = remoteMetrics.map(Metric.fromRemoteMetric);

    return new Dataset(key, metrics, pipeline, label, options);
  }
}

export default Dataset;
