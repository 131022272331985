// @ts-ignore
import abbreviate from 'number-abbreviate';
import streamSaver from 'streamsaver';
import i18n from '@/i18n';
import functions from './functions';
import time from './time';
export { default as functions } from './functions';
export { default as time } from './time';

declare global {
  type Locale = 'es' | 'en';
}

const LOCALE = 'es-CL';

const PIXELS_REGEXP = /(.*)px/g;
const TRANSLATE_X_REG_EXR = /translateX\((.*)px\)/g;
const TRANSLATE_Y_REG_EXR = /translateY\((.*)px\)/g;

const abbreviate_ = (value: number, threshold = 0, decimalPlaces = 0) =>
  value > threshold
    ? String(abbreviate(value, decimalPlaces))
        .toLocaleUpperCase(LOCALE)
        .replace(/\s/g, '')
    : String(value).toLocaleUpperCase(LOCALE);

const isCSSColor = (color: string) =>
  Boolean(color) && Boolean(color.match(/^(#|var\(--|(rgb|hsl)a?\()/));

export const buildFileURI = (file: StorageFile) =>
  `${process.env.VUE_APP_STORAGE_DOMAIN}/${file.filename}`;

export const camelCaseObject = (object: any) =>
  _.mapKeys(object, (value, key) => _.camelCase(key));

export const chunkString = (string: string, limit: number) => {
  const WORDS_SEPARATOR = ' ';

  const chunks: string[] = [];

  const words = _.split(string, WORDS_SEPARATOR);
  let chunk = '';

  _.forEach(words, (word, index) => {
    if (chunk.length + WORDS_SEPARATOR.length + word.length <= limit)
      chunk += WORDS_SEPARATOR + word;
    else {
      chunks.push(_.trim(chunk));
      chunk = word;
    }

    if (index === words.length - 1) chunks.push(_.trim(chunk));
  });

  return chunks;
};

export const download = async (uri: string, filename: string) => {
  const fs = streamSaver.createWriteStream(filename);
  const { body } = await fetch(uri);
  if (!body) return;

  if (window.WritableStream && body.pipeTo) return body.pipeTo(fs);

  const writer = fs.getWriter();
  const reader = body.getReader();

  const pump = async (): Promise<void> => {
    const { done, value } = await reader.read();

    return done ? writer.close() : writer.write(value).then(pump);
  };

  pump();
};

export const downloadByAnchor = (uri: string, filename: string) => {
  const link = document.createElement('a');

  link.href = uri;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fetchFile = async (file: StorageFile) =>
  functions
    .fetchFileSignedURI(file)
    .then(uri => Object.assign({}, file, { uri }))
    .catch(() => Object.assign({}, file, { uri: undefined }));

export const findRouteWithMeta = (routes: any, key: string) =>
  _.findLast(routes, `meta.${key}`);

export const formatters = (type: string) => {
  switch (type) {
    // number
    case 'localedRound':
      return (value: number, precision?: number) =>
        _.round(value, precision).toLocaleString(LOCALE);
    case 'abbreviate':
      return (value: number, threshold: number, precision?: number) =>
        abbreviate_(_.round(value, precision), threshold, 1);

    // time
    case 'duration':
      return time.format.asDuration;
    case 'hourOfDay':
      return time.format.asHourOfDay;

    default:
      return _.identity;
  }
};

export const getElWidth = (el: HTMLElement) => el.getBoundingClientRect().width;
export const getElHeight = (el: HTMLElement) =>
  el.getBoundingClientRect().height;

export const getPixelsValue = (value: string, regexp = PIXELS_REGEXP) => {
  const result = regexp.exec(value);
  regexp.lastIndex = 0;
  if (!result) return;

  return Number(result[1]);
};

export const htmlStringToElement = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.firstChild;
};

export const isIterable = (value: any): value is Iterable<any> =>
  !_.isNull(value) && typeof value[Symbol.iterator] === 'function';

export const isURI = (value: any) => _.isString(value) && value.length > 0;

export const parseI18nText = (i18nText: I18nText) =>
  _.isString(i18nText) ? i18nText : i18nText[<Locale>i18n.locale];

export const parseTranslateX = (transform: string) => {
  const translateX = transform
    ? getPixelsValue(transform, TRANSLATE_X_REG_EXR)
    : 0;

  return translateX;
};

export const parseTranslateY = (transform: string) => {
  const translateY = transform
    ? getPixelsValue(transform, TRANSLATE_Y_REG_EXR)
    : 0;

  return translateY;
};

export const polarToCartesian = (
  cx: number,
  cy: number,
  radius: number,
  angle: number
) => {
  const radians = ((angle - 90) * Math.PI) / 180.0;

  return {
    x: cx + radius * Math.cos(radians),
    y: cy + radius * Math.sin(radians),
  };
};

export const tokens = (() => {
  const json = require('odd-ds/dist/tokens.json');

  return {
    fonts: {
      families: _.memoize(function(key) {
        key = `f_family_${_.snakeCase(key)}`;

        return json[key];
      }),
    },

    breakpoints: _.memoize(function(key) {
      key = `breakpoint_${_.snakeCase(key)}`;

      return getPixelsValue(json[key]);
    }),

    colors: _.memoize(function(key) {
      if (isCSSColor(key)) return key;
      key = `c_${_.snakeCase(key)}`;

      return json[key];
    }),

    durations: _.memoize(function(key) {
      key = `duration_${_.snakeCase(key)}`;
      const result = /(.*)s/g.exec(json[key]);
      if (!result) return;

      return Number(result[1]) * 1000;
    }),

    allBreakpoints: _.once(() => {
      const KEY_REGEXP = /breakpoint_(.*)/g;

      return _.reduce(
        json,
        (breakpoints, value, key) => {
          const result = KEY_REGEXP.exec(key);
          KEY_REGEXP.lastIndex = 0;
          if (!result) return breakpoints;
          key = result[1];

          return { ...breakpoints, ...{ [key]: getPixelsValue(value) } };
        },
        {}
      );
    }),
  };
})();

export const updateMediaCredentials = functions.fetchMediaSignedCookie;
