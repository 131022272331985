export default {
  props: {
    elevation: { type: [Number, String], default: undefined },
  },
  computed: {
    elevatableClasses() {
      return {
        [`elevation-${this.elevation}`]:
          this.elevation && !isNaN(Number(this.elevation)),
      };
    },
  },
};
