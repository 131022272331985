<template>
  <div v-show="!isBottomDrawerHidden" :style="style" class="bottom-drawer">
    <div class="bottom-drawer__placeholder">
      <div
        v-hammer:pan.start="onTouchStart"
        v-hammer:panend="onTouchEnd"
        v-hammer:panmove="onTouchMove"
        v-hammer:swipe.down="closeBottomDrawer"
        v-hammer:swipe.up="openBottomDrawer"
        class="bottom-drawer__drawer"
      >
        <HeightObserver @notify="onHeaderHeightChange" @click="onHeaderClick">
          <div class="bottom-drawer__header">
            <portal-target
              name="bottom-drawer-header"
              slim
              @change="notEmpty => (isHeaderEmpty = !notEmpty)"
            />
          </div>
        </HeightObserver>

        <HeightObserver
          class="bottom-drawer__body"
          @notify="onBodyHeightChange"
        >
          <portal-target
            name="bottom-drawer-body"
            slim
            @change="notEmpty => (isBodyEmpty = !notEmpty)"
          />
        </HeightObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import animation from './Animation';

// components
import HeightObserver from '@/components/utils/HeightObserver.vue';

export default {
  name: 'BottomDrawer',
  components: { HeightObserver },
  mixins: [animation],
  data() {
    return {
      heights: {
        closed: null,
        open: null,

        header: null,
        body: null,
      },

      isHeaderEmpty: null,
      isBodyEmpty: null,
    };
  },
  computed: {
    ...mapState('ui', {
      isBottomDrawerHidden: state => state.bottomDrawer.isHidden,
    }),

    isEmpty() {
      return this.isHeaderEmpty && this.isBodyEmpty;
    },

    ready() {
      const { closed, open } = this.heights;

      return !_.isNull(closed) && !_.isNull(open);
    },

    style() {
      const { closed, open } = this.heights;

      return {
        '--bottom': `${closed - open}px`,
        '--closed-height': `${closed}px`,
        opacity: this.ready ? 1 : 0,
      };
    },
  },
  watch: {
    isEmpty() {
      if (this.isEmpty) this.hideBottomDrawer();
      else this.showBottomDrawer();
    },
  },
  methods: {
    ...mapActions('ui', ['hideBottomDrawer', 'showBottomDrawer']),

    updateClosedAndOpenHeights() {
      this.heights.closed = this.heights.header;
      this.heights.open = this.heights.header + this.heights.body;
    },
    onHeaderHeightChange(height) {
      this.heights.header = height;

      this.updateClosedAndOpenHeights();

      this.$emit('height-change', height);
    },
    onBodyHeightChange(height) {
      this.heights.body = height;

      this.updateClosedAndOpenHeights();
    },
    onHeaderClick() {
      if (this.isOpen) this.closeBottomDrawer();
      else this.openBottomDrawer();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$padding: $spacing-3

// - drawer
.bottom-drawer__drawer
  position: absolute
  bottom: var(--bottom)
  z-index: $z-index-bottom-drawer

  width: 100%

  box-shadow: $elevation-5

  will-change: transform

  +border-top-radius($padding)

// - draggable
.bottom-drawer__draggable
  $width: 56px
  $height: 3px

  +d-flex-r(center, center)
  +v-padding($padding)

  &::after
    content: ''

    width: $width

    +h-rounded($height)

// - header
.bottom-drawer__header
  padding: $padding

// - body
.bottom-drawer__body
  +padding-shell-bottom($padding)

// - placeholder
.bottom-drawer__placeholder
  position: relative

  height: var(--closed-height)

// color
.bottom-drawer__drawer
  background-color: var(--c-white)

.bottom-drawer__draggable::after
  background-color: var(--c-gray-1)
</style>
