import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/service-worker';

// stylesheets
import 'odd-ds/dist/odd-ds.css';
import '@odd-industries/odd-charting-components/dist/lib.css';
import '@/assets/stylesheets/index.sass';
import '@/authentication/forms/style.sass';

// external modules
// - Google Maps API
import loadMapsAPI from '@/config/mapsAPI';
export const mapsAPI = loadMapsAPI(process.env.VUE_APP_MAPS_API_KEY, {
  libraries: 'geometry',
});
Vue.prototype.$mapsAPI = mapsAPI;

// - anime (https://github.com/juliangarnier/anime)
// @ts-ignore
import anime from 'animejs/lib/anime.es';
Vue.prototype.$a = anime;

// - axios (https://github.com/axios/axios)
import axios from 'axios';
Vue.prototype.$http = axios;

// - lodash (https://github.com/lodash/lodash)
// @ts-ignore
import _ from 'lodash';
Vue.prototype._ = _;

// - vue calendar (https://github.com/nathanreyes/v-calendar)
// @ts-ignore
import VueCalendar from 'v-calendar';
Vue.use(VueCalendar, {
  locales: {
    es: {
      firstDayOfWeek: 2,
      masks: { weekdays: 'WW' },
    },
    en: {
      firstDayOfWeek: 2,
      masks: { weekdays: 'WW' },
    },
  },
});

// - vue click outside (https://github.com/ndelvalle/v-click-outside)
// @ts-ignore
import VueClickOutside from 'v-click-outside';
Vue.use(VueClickOutside);

// - vue clipboard (https://github.com/Inndy/vue-clipboard2)
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// - vue formulate (https://github.com/wearebraid/vue-formulate)
// @ts-ignore
import VueFormulate from '@braid/vue-formulate';
// @ts-ignore
import { en, es } from '@braid/vue-formulate-i18n';
Vue.use(VueFormulate, {
  plugins: [en, es],
  library: {
    'odd-radio': { classification: 'box', component: 'FormulateOddRadio' },
    'odd-select': { classification: 'select', component: 'FormulateOddSelect' },
    'odd-switch': { classification: 'box', component: 'FormulateOddSwitch' },
  },
});

// - vue gtag (https://github.com/MatteoGabriele/vue-gtag)
import VueGtag from 'vue-gtag';
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG_ID },
  },
  router
);

// - vue hammer (https://github.com/bsdfzzzy/vue2-hammer)
// @ts-ignore
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// - vue notification (https://github.com/euvl/vue-notification)
import VueNotification from 'vue-notification';
Vue.use(VueNotification, { componentName: 'VNotification' });

// - vue portal (https://github.com/LinusBorg/portal-vue)
import VuePortal from 'portal-vue';
Vue.use(VuePortal);

// - vue resize (https://github.com/Akryum/vue-resize)
// @ts-ignore
import VueResize from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
Vue.use(VueResize);

// internal modules
// - API
import API from '@/API';
Vue.prototype.$API = API;

// - time
import time from '@/helpers/time';
Vue.use(time.plugin);

// global components
// @ts-ignore
import EButton from '@/components/ds/Button.vue';
import EIcon from '@/components/ds/Icon.vue';
import EInput from '@/components/formulate/Input/index.vue';
import EText from '@/components/ds/Text.vue';
import FormulateOddRadio from '@/components/formulate/FormulateOddRadio.vue';
import FormulateOddSelect from '@/components/formulate/FormulateOddSelect.vue';
import FormulateOddSwitch from '@/components/formulate/FormulateOddSwitch.vue';
import Progress from '@/components/utils/Progress/index.vue';
Vue.component('EButton', EButton);
Vue.component('EInput', EInput);
Vue.component('FormulateOddRadio', FormulateOddRadio);
Vue.component('FormulateOddSelect', FormulateOddSelect);
Vue.component('FormulateOddSwitch', FormulateOddSwitch);
Vue.component('Progress', Progress);
Vue.component('icon', EIcon);
Vue.component('t', EText);

// global mixins
import { WithViewer } from '@/mixins';
Vue.mixin(WithViewer);

Vue.config.productionTip = false;

import App from '@/App.vue';
new Vue({ router, store, i18n, render: h => h(App) }).$mount('#app');
