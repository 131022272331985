<template>
  <div class="menu-notifications" @click="onClick">
    <MenuUnreadNotifications class="d-flex-r d-flex-a-c">
      <template v-slot:highlighted>
        <EButton navigation md>bell</EButton>
      </template>

      <template v-slot:default="{ unreadNotifications }">
        <t semibold class="ml-3">{{ $t('c.menu.notifications') }}</t>

        <div class="spacer" />

        <UnreadNotificationsCount :count="unreadNotifications.length" />
      </template>
    </MenuUnreadNotifications>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import MenuUnreadNotifications from '@/components/ui/Menu/MenuUnreadNotifications.vue';
import UnreadNotificationsCount from '@/components/ui/Menu/UnreadNotificationsCount.vue';

export default {
  name: 'MenuNotifications',
  components: { MenuUnreadNotifications, UnreadNotificationsCount },
  computed: {
    ...mapState('ui', { isOpen: state => state.notifications.isOpen }),
  },
  methods: {
    ...mapActions('ui', ['openNotifications', 'closeNotifications']),

    onClick() {
      if (this.isOpen) this.closeNotifications();
      else this.openNotifications();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
