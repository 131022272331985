export const VARIANTS = {
  person: {
    aggregation: 'HH',
    field: 'person',

    class_name: 'person',

    kpi_label: Object.freeze({
      es: 'Horas hombres',
      en: 'Man-hours',
    }),
    single_legend_label: Object.freeze({
      es: 'Horas hombres',
      en: 'Man-hours',
    }),
  },

  worker: {
    aggregation: 'HH',
    field: 'workers',

    class_name: 'workers',

    kpi_label: Object.freeze({
      es: 'Horas hombres',
      en: 'Man-hours',
    }),
    single_legend_label: Object.freeze({
      es: 'Horas hombres',
      en: 'Man-hours',
    }),
  },

  machine: {
    aggregation: 'HH',
    field: 'truck',

    class_name: 'truck',

    kpi_label: Object.freeze({
      es: 'Horas máquinas',
      en: 'Machine-hours',
    }),
    single_legend_label: Object.freeze({
      es: 'Horas máquinas',
      en: 'Machine-hours',
    }),
  },
};
