<template>
  <div class="double-range">
    <Range
      :value="value[0]"
      :max="max"
      :min="min"
      :step="step"
      class="double-range__range--left"
      @dragging-change="$emit('dragging-change', $event)"
      @input="onInput([$event, value[1]], 'left')"
    >
      <slot />
    </Range>

    <Range
      :value="value[1]"
      :max="max"
      :min="min"
      :step="step"
      class="double-range__range--right"
      @dragging-change="$emit('dragging-change', $event)"
      @input="onInput([value[0], $event], 'right')"
    />

    <div class="fluid">
      <DiscreteGradientBar :gradient="gradient" />

      <div class="d-flex-r d-flex-j-sb mt-1">
        <slot name="start" />

        <slot name="end" />
      </div>
    </div>
  </div>
</template>

<script>
import { buildGradientFromSegments } from '@/components/ui/DownloadDrawer/inputs/TimeRange/helpers';

// components
import DiscreteGradientBar from './DiscreteGradientBar.vue';
import Range from '@/components/formulate/Range/index.vue';

export default {
  name: 'DoubleRange',
  components: { DiscreteGradientBar, Range },
  props: {
    value: { type: Array, required: true },

    segments: { type: Array, default: _.stubArray },

    max: { type: Number, default: 1 },
    maxDifference: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
    minDifference: { type: Number, default: 0 },
    step: { type: Number, default: 0.01 },
  },
  computed: {
    thumbSegment() {
      const nValues = this.max - this.min;

      return {
        color: 'var(--c-primary)',
        zIndex: 2,

        start: (this.value[0] - this.min) / nValues,
        end: (this.value[1] - this.min) / nValues,
      };
    },

    gradient() {
      return buildGradientFromSegments([...this.segments, this.thumbSegment]);
    },
  },
  methods: {
    sanitizeValue(value, thumb = 'left') {
      let [left, right] = value;

      if (thumb === 'left')
        right = _.clamp(
          right,
          left + this.minDifference,
          left + this.maxDifference
        );
      else if (thumb === 'right')
        left = _.clamp(
          left,
          right - this.maxDifference,
          right - this.minDifference
        );

      return [left, right];
    },

    onInput(value, thumb) {
      this.$emit('input', this.sanitizeValue(value, thumb));
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$thumb-border-width: 2px
$thumb-size: 12px

$right-thumb-height: 13px
$right-thumb-width: 3px
$right-thumb-z-index: $z-index-base

$height: $thumb-size + $thumb-border-width

.double-range
  width: 100%
  height: $height

  +d-flex-r(center, center)
  +p-relative

.double-range > .range
  +p-absolute

  & > .range__thumb-wrapper > .range__thumb
    opacity: 0

  & > .range__bar
    display: none

.double-range__range--left
  z-index: #{$right-thumb-z-index + 1}

  .range__thumb
    transform: translateX(var(--right-thumb-translate-x, -50%))

.double-range__range--right
  z-index: $right-thumb-z-index
</style>
