export const TITLE_KEY = 'title';
export const TITLE_PARAMETERS_KEY = 'title_parameters';

export const AREAS_KEY = 'areas';
export const AREAS_TEMPLATES_KEY = 'areas_templates';

export const TABS_KEY = 'tabs';

// avoiding "WithDataContainer" mixin...
export const WITH_DATA_CONTAINER_PROPS = {
  dateRange: { type: Object, required: true },
};

// avoiding "WithManifest" mixin...
export const WITH_MANIFEST_PROPS = {
  manifest: { type: Object, required: true },
};
