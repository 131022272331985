<template>
  <MenuUnreadNotifications>
    <template v-slot:highlighted>
      <EButton v-bind="$attrs" @click="onClick" v-on="$listeners">bell</EButton>
    </template>
  </MenuUnreadNotifications>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import MenuUnreadNotifications from '@/components/ui/Menu/MenuUnreadNotifications.vue';

export default {
  name: 'NotificationsDrawerActivator',
  components: { MenuUnreadNotifications },
  computed: {
    ...mapState('ui', { isOpen: state => state.notifications.isOpen }),
  },
  methods: {
    ...mapActions('ui', ['closeNotifications', 'openNotifications']),

    onClick() {
      if (this.isOpen) this.closeNotifications();
      else this.openNotifications();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
