export default {
  props: {
    value: { type: Object, required: true },

    maxDate: { type: Object, default: null },
    minDate: { type: Object, default: null },

    hideDetails: { type: Boolean, default: false },
  },
  watch: {
    value: { handler: 'sync', immediate: true },
  },
  methods: {
    isValidDate(date) {
      return (
        (!this.minDate || this.minDate.diff(date.startOf('day')) <= 0) &&
        (!this.maxDate || this.maxDate.diff(date.endOf('day')) >= 0)
      );
    },
  },
};
