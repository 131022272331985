<template>
  <div :class="className">
    <div class="d-flex-r d-flex-a-fs">
      <div class="notification__icon mr-3">
        <icon v-if="icon">{{ icon }}</icon>
      </div>

      <div class="d-flex-c d-flex-a-fs">
        <div class="v-spacing-1 mb-3">
          <t v-if="body" sm semibold class="notification__body">{{ body }}</t>
          <br />
          <t v-if="notification.serviceName" xs>
            {{ notification.serviceName }} <br />

            <t color="gray-2" class="mt-1">{{ offsetAsString }}</t>
          </t>
        </div>

        <div class="d-flex-r d-flex-a-c h-spacing-2">
          <EButton
            v-if="notification.status === 'success'"
            filled
            md
            @click="download"
          >
            {{ $t('g.download') }}
          </EButton>

          <EButton md @click="dismiss">{{ $t('g.dismiss') }}</EButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadByAnchor } from '@/helpers';

export default {
  name: 'NotificationsDrawerBodyNotification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      const { type } = this.notification;

      if (type === 'download-video') return 'video-play';
      if (type === 'download-image') return 'picture';

      return '';
    },
    body() {
      const { status, type } = this.notification;

      if (type === 'download-video')
        return this.$t(`c.notification.download-video.${status}`);
      if (type === 'download-image')
        return this.$t(`c.notification.download-image.${status}`);

      return '';
    },
    offsetAsString() {
      const { timestamp } = this.notification;

      return this.$time.offsetAsString(this.$time.timestampToOffset(timestamp));
    },

    className() {
      const className = 'notifications-drawer-body-notification';

      return [className, `${className}--${this.notification.status}`];
    },
  },
  methods: {
    download() {
      const { serviceName: filename, type, uri } = this.notification;

      let extension = '';
      if (type === 'download-video') extension = 'mp4';
      else if (type === 'download-image') extension = 'jpg';

      return downloadByAnchor(uri, `${filename}.${extension}`);
    },

    dismiss() {
      this.$API.update.notification(this.notification.id, { dismissed: true });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$icon-size: 16px
$size: 24px

.notification__icon
  +circle($size)
  +d-flex-r(center, center)

  & > .e-icon
    --size: #{$icon-size}

// color
=color($color, $background-color)
  $alpha: .15

  .notification__icon
    background-color: rgba($background-color, $alpha) // FIX

    & > .e-icon
      --color: #{$color}

.notifications-drawer-body-notification--success,
.notifications-drawer-body-notification--pending
  +color($c-primary, $c-gray-0)

.notifications-drawer-body-notification--pending
  $background-color-alpha: .1
  $opacity-alpha: .34

  background-color: rgba($c-primary, $background-color-alpha)

  .notification__icon
    opacity: $opacity-alpha

.notifications-drawer-body-notification--failure
  +color($c-red, $c-red)

  .notification__body
    --color: #{$c-red}
</style>
