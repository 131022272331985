export const playbackSpeedsAsOptions = (
  playbackSpeeds: PlaybackSpeed[],
  disabledPlaybackSpeeds: Set<PlaybackSpeed>
) =>
  _.map(playbackSpeeds, playbackSpeed => {
    const label = `${playbackSpeed}x`;

    return {
      id: label,

      label,
      value: String(playbackSpeed),

      disabled: disabledPlaybackSpeeds.has(playbackSpeed),
      lg: true,
    };
  });

export const qualitiesAsOptions = (
  qualities: Quality[],
  disabledQualities: Set<Quality>
) =>
  _.map(qualities, quality => {
    const label = `${quality}p - ${quality <= 480 ? 'SD' : 'HD'}`;

    return {
      id: label,

      label,
      value: String(quality),

      disabled: disabledQualities.has(quality),
      lg: true,
    };
  });
