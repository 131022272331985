<template>
  <div class="notifications-drawer-body-empty">
    <icon color="gray-1" class="mb-3">bell-off</icon>

    <t color="gray-2" sm medium center>{{ $t('c.notifications-empty') }}</t>
  </div>
</template>

<script>
export default {
  name: 'NotificationsDrawerBodyEmpty',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.notifications-drawer-body-empty
  max-width: 50%

  +d-flex-c(center, center)
  +h-margin(auto)

.notifications-drawer-body-empty > .e-icon
  width: 50%
  height: auto
</style>
