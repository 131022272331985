import i18n from '@/i18n';
import { METRICS_COLLECTION_ID } from './constants';

import Metric from './index';

type MessageKey = 'invalid-id';

class MetricError extends Error {
  constructor(...payload: ConstructorParameters<ErrorConstructor>) {
    super(...payload);

    this.name = 'MetricError';
  }
}

export class MetricErrorDispatcher {
  static I18N_PATH = 'errors.metric';

  metric: InstanceType<typeof Metric>;

  constructor(metric: InstanceType<typeof Metric>) {
    this.metric = metric;
  }

  dispatch(messageKey: MessageKey) {
    const message = i18n
      .t(`${MetricErrorDispatcher.I18N_PATH}/${messageKey}`, {
        collectionId: METRICS_COLLECTION_ID,
        metricId: this.metric.id,
      })
      .toString();

    return new MetricError(message);
  }
}

export default MetricErrorDispatcher;
