<template>
  <div :style="style" :class="className">
    <div class="input-text__body">
      <input
        v-bind="$attrs"
        :value="value"
        @blur="isFocused = false"
        @focus="isFocused = true"
        @input="e => $parent.$emit('input', e.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    value: {
      type: String,
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    style() {
      return Object.assign(
        {},
        this.error && { '--error': `'${this.error}'` },
        this.label && { '--label': `'${this.label}'` }
      );
    },
    className() {
      const className = 'input-text';

      return [
        className,
        {
          [`${className}--focused`]: this.isFocused,
          [`${className}--invalid`]: this.isInvalid,
          [`${className}--invalid-with-error`]: this.isInvalid && this.error,
        },
      ];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

$message-height: 16px
$message-padding: 2px

=message
  font-family: $f-family-primary
  font-size: $f-size-sm
  line-height: $message-height

  height: $message-height
  padding: $message-padding

// base
.input-text
  padding-top: ($message-height + $message-padding) / 2
  padding-bottom: $message-height + $message-padding

.input-text__body
  position: relative

  border: 1px solid

  +h-rounded($input-height, true)

  & > input
    font-family: $f-family-primary
    font-size: $f-size-md

    +size(100%)

.input-text__body
  &::before, &::after
    position: absolute
    left: $input-height / 2

    +message

  &::before
    content: var(--label)

    top: -50%

    transform: translateY(50%)

  &::after
    content: var(--error)

    top: 100%

// color
.input-text
  .input-text__body
    background-color: var(--c-white)

    border-color: var(--c-gray-2)

    &::before
      color: var(--c-gray-2)

      background-color: var(--c-white)

    & > input
      color: var(--c-secondary)

  &--invalid
    .input-text__body
      border-color: var(--c-red)

      &::before
        color: var(--c-red)

  &--invalid-with-error
    .input-text__body
      &::after
        color: var(--c-red)

  &--focused
    .input-text__body
      border-color: var(--c-green)

      &::before
        color: var(--c-green)
</style>
