import DataArray from './DataArray';
import { DataPoint, DataPointValue } from './Metric/helpers';

type Matrix = DataArray[];

export class DataMatrix {
  matrix: Matrix;

  constructor(matrix: Matrix) {
    this.matrix = matrix;

    // binding
    _.bindAll(this, ['aggregate', 'fill']);
  }

  fill() {
    const buckets: { [key: string]: { [key: string]: DataPointValue } } = {};
    const bucketsColumns = [...Array(this.matrix.length).keys()];

    this.matrix.forEach((row, index) => {
      row.array.forEach(({ x, y }) => {
        const bucketKey = _.isString(x) ? x : x.format();
        const bucket = buckets[bucketKey] || (buckets[bucketKey] = {});

        bucket[index] = y;
      });
    });

    this.matrix.forEach(row => (row.array = []));

    _(buckets)
      .entries()
      .sortBy(([bucketKey]) => bucketKey)
      .forEach(([bucketKey, bucket]) => {
        _.forEach(bucketsColumns, bucketColumn => {
          const x = moment(bucketKey);
          const y = bucket[bucketColumn] || 0;

          this.matrix[bucketColumn].array.push({ x, y });
        });
      });
  }

  aggregate(remoteAggregation: RemoteAggregation, through = 'rows') {
    let aggregator: (matrix: Matrix) => Matrix = _.identity;
    let matrix = this.matrix;

    if (through === 'rows' || through === 'columns') {
      aggregator = (matrix: Matrix) =>
        _.map(matrix, row => row.aggregate(remoteAggregation));
      matrix = through === 'rows' ? this.matrix : this.tMatrix;
    }

    return new DataMatrix(aggregator(matrix));
  }

  get filled() {
    const { matrix } = this;
    if (matrix.length === 0) return true;
    const length = matrix[0].array.length;

    return _.every(matrix, row => row.array.length === length);
  }

  get tMatrix() {
    if (!this.filled) this.fill();

    const tMatrix = <DataPoint[][]>_.zip(..._.map(this.matrix, 'array'));

    return _.map(tMatrix, column => {
      const x = column.length > 0 ? column[0].x : undefined;
      const label = moment.isMoment(x) ? x.format() : _.isString(x) ? x : '';

      return new DataArray(column, label);
    });
  }
}

export default DataMatrix;
