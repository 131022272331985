import CRCellPreset from './../CRCellPreset';

import { VARIANTS } from './constants';

export class WorkDay extends CRCellPreset {
  static VALID_PROPERTIES = ['kpiLabel', 'singleLegendLabel'];

  static DEFAULT_VARIANT = 'person';

  kpiLabel;
  singleLegendLabel;

  buildKPIs() {
    return { aggregation: 'max', label: this.kpiLabel };
  }

  build(remotePayload) {
    const payload = this.parseRemotePayload(remotePayload, VARIANTS);

    this.setup(payload);

    const type = 'BarChart';
    const kpis = this.buildKPIs();

    return {
      data_address_parameters: {
        datasets: this.buildDatasets(this.classNames, this.rois, this.legendBy),
      },

      type,
      type_parameters: { stacked: true, isWorkDay: true },

      kpis,
    };
  }
}

export default WorkDay;
