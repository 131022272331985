<template>
  <a class="go-default-link" @click="onClick">
    <div class="go-default-link__logo">
      <ELogo />

      <icon>home</icon>
    </div>

    <slot />
  </a>
</template>

<script>
import { getDefaultRoute } from '@/router';

// components
import { ELogo } from 'odd-ds';

export default {
  name: 'GoBackLink',
  components: { ELogo },
  methods: {
    onClick() {
      this.$router.push(getDefaultRoute()).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$logo-desktop-size: 40px
$logo-mobile-size: 36px
$logo-icon-desktop-size: 24px
$logo-icon-mobile-size: 22px

.go-default-link
  +button
  +d-flex-r(center)

  & > .e-text
    margin-left: $spacing-2

.go-default-link__logo
  position: relative

  & > .e-icon
    opacity: 0

    transition: opacity $duration-normal $ease-in-out-sine

    +p-absolute-top-left($z-index-base, calc(50% - var(--size) / 2))

  +on-hover
    & > .e-icon
      opacity: 1

+media-up(md)
  .go-default-link__logo
    & > .e-logo
      +size($logo-desktop-size)

    & > .e-icon
      --size: #{$logo-icon-desktop-size}

+media-down(md)
  .go-default-link__logo
    & > .e-logo
      +size($logo-mobile-size)

    & > .e-icon
      --size: #{$logo-icon-mobile-size}

// color
.go-default-link__logo > .e-icon
  --color: var(--c-white)
</style>
