type Event = 'error';

export class ConstrainedValue {
  value_: any;

  callbacks: Partial<Record<Event, Function>> = {};

  constructor(value: any) {
    this.value_ = value;
  }

  get value() {
    return this.value_;
  }

  set value(newValue) {
    if (!this.isValid(newValue)) this.emit('error');
    else this.value_ = newValue;
  }

  isValid(value: any) {
    return true;
  }

  on(event: Event, callback: Function) {
    this.callbacks[event] = callback;
  }

  emit(event: Event) {
    const callback = this.callbacks[event];

    if (callback) callback();
  }
}

export default ConstrainedValue;
