import PresetCore from './PresetCore';

import { isClassName, isROI } from './helpers';

export class CRPreset extends PresetCore {
  static VALID_PROPERTIES = {
    classNames: {
      callback: remoteClassNames => {
        const { DEFAULT_REMOTE_CLASS_NAMES: DEFAULT } = CRPreset;

        let classNames = remoteClassNames || DEFAULT();
        if (!_.isArray(classNames)) classNames = [classNames];

        return _.filter(classNames, isClassName);
      },
      aliases: ['className'],
    },
    rois: {
      callback: remoteROIs => {
        const { DEFAULT_REMOTE_ROIS: DEFAULT } = CRPreset;

        let rois = remoteROIs || DEFAULT();
        if (!_.isArray(rois)) rois = [rois];

        return _.filter(rois, isROI);
      },
      aliases: ['roi'],
    },
  };

  static DEFAULT_REMOTE_CLASS_NAMES = _.stubArray;
  static DEFAULT_REMOTE_ROIS = _.stubArray;

  classNames;
  rois;
}

export default CRPreset;
