<template functional>
  <div
    :style="{ opacity: Number(props.display) }"
    :class="['tooltip', data.class, data.staticClass]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    display: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.tooltip
  transition: opacity $duration-quickly $ease-out-quint

  +p-absolute
</style>
