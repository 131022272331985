import { aggregate } from '@/aggregation';
import { DataPoint } from './Metric/helpers';

export class DataArray {
  array: DataPoint[];
  label: string;

  constructor(array: DataPoint[], label: string) {
    this.array = array;
    this.label = label;

    // binding
    _.bindAll(this, ['aggregate']);
  }

  aggregate(remoteAggregation: RemoteAggregation) {
    const x = this.label;
    const y = aggregate(this.array, remoteAggregation);

    return new DataArray([{ x, y }], this.label);
  }
}

export default DataArray;
