let isMapsAPIScriptInjected = false;

const injectMapsAPIScript = (options = {}) => {
  if (isMapsAPIScriptInjected) throw new Error('');

  const optionsQuery = Object.keys(options)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`
    )
    .join('&');
  const uri = `https://maps.googleapis.com/maps/api/js?${optionsQuery}`;

  const script = document.createElement('script');
  script.setAttribute('src', uri);
  script.setAttribute('async', '');
  script.setAttribute('defer', '');
  document.head.appendChild(script);

  isMapsAPIScriptInjected = true;
};

const loadMapsAPI = (APIKey, options = {}) =>
  _.once(async () => {
    await new Promise((resolve, reject) => {
      try {
        window.onMapsAPILoaded = resolve;

        injectMapsAPIScript({
          key: APIKey,
          callback: 'onMapsAPILoaded',
          ...options,
        });
      } catch (error) {
        reject(error);
      }
    });

    const { maps } = window.google;

    maps.Map.prototype.addListenerOnce = function(event, handler) {
      const map = this;

      const listener = map.addListener(event, () => {
        handler();

        listener.remove();
      });
    };

    maps.Map.prototype.fitZoomToMarkers = function(markers, offset = {}) {
      const map = this;

      return new Promise(resolve => {
        const overlay = new maps.OverlayView();

        overlay.onAdd = function() {
          const bounds = new maps.LatLngBounds();
          markers.forEach(marker => bounds.extend(marker.getPosition()));

          // extending bounds with offset as new LatLng objects
          const projection = this.getProjection();
          const sw = bounds.getSouthWest();
          const sw_ = projection.fromLatLngToContainerPixel(sw);
          const ne = bounds.getNorthEast();
          const ne_ = projection.fromLatLngToContainerPixel(ne);

          const { top = 0, right = 0, bottom = 0, left = 0 } = offset;
          if (top) ne_.y -= top;
          if (right) ne_.x += right;
          if (bottom) sw_.y += bottom;
          if (left) sw_.x -= left;

          bounds.extend(projection.fromContainerPixelToLatLng(sw_));
          bounds.extend(projection.fromContainerPixelToLatLng(ne_));

          map.addListenerOnce('zoom_changed', resolve);
          map.fitBounds(bounds);
        };

        overlay.setMap(this);
      });
    };

    maps.Map.prototype.panToWithOffset = function({ lat, lng }, offset = {}) {
      const map = this;

      return new Promise(resolve => {
        const overlay = new maps.OverlayView();

        overlay.onAdd = function() {
          const projection = this.getProjection();
          const p = new maps.LatLng(lat, lng);
          const p_ = projection.fromLatLngToContainerPixel(p);

          const { top = 0, right = 0, bottom = 0, left = 0 } = offset;
          if (top || bottom) p_.y += (bottom - top) / 2;
          if (left || right) p_.x += (right - left) / 2;

          map.addListenerOnce('center_changed', () => overlay.setMap(null));
          map.panTo(projection.fromContainerPixelToLatLng(p_));
        };

        overlay.onRemove = resolve;

        overlay.setMap(this);
      });
    };

    maps.Map.prototype.mountControl = function({
      id,
      builder,
      position,
      classes,
    }) {
      const map = this;

      position = _.get(maps.ControlPosition, position);
      const controls = map.controls[position];

      // finding wrapper (I couldn't do better)
      let index = -1;
      controls.forEach((el, index_) => {
        if (el.id === id) index = index_;
      });
      if (index !== -1) return; // wrapper found. skipping...

      // creating
      const wrapper = document.createElement('div');
      wrapper.id = id;
      wrapper.classList.add(...classes);
      wrapper.index = 1;

      const control = builder(wrapper);
      wrapper.appendChild(control);

      // adding
      controls.push(wrapper);
    };

    maps.Map.prototype.unmountControl = function({ id, position }) {
      const map = this;

      position = _.get(maps.ControlPosition, position);
      const controls = map.controls[position];

      // finding wrapper (I couldn't do better)
      let index = -1;
      controls.forEach((el, index_) => {
        if (el.id === id) index = index_;
      });
      if (index === -1) return; // wrapper not found. skipping...

      // removing
      map.controls[position].removeAt(index);
    };

    return maps;
  });

export default loadMapsAPI;
