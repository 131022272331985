<template>
  <VNotification :group="group" position="bottom right">
    <template v-slot:body="props">
      <div class="refresh-notification">
        <t color="white">{{ props.item.text }}</t>

        <EButton type="submit" @click="onClick">
          {{ $t('c.notification.refresh.action-text') }}
        </EButton>
      </div>
    </template>
  </VNotification>
</template>

<script>
export default {
  name: 'RefreshNotification',
  data() {
    return {
      group: 'refresh',

      channel: null,
    };
  },
  created() {
    this.setup();
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    setup() {
      const channel = new BroadcastChannel('refresh-channel');
      // channel.onmessage = this.prompt;

      this.channel = channel;
    },
    teardown() {
      this.channel.close();
    },

    prompt() {
      this.$notify({
        group: this.group,
        text: this.$t('c.notification.refresh.text'),
        duration: -1,
      });
    },

    onClick() {
      window.location.reload(true);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.refresh-notification
  $height: 40px
  $button-padding: $height * .125
  $button-height: $height - 2 * $button-padding

  margin: $spacing-2
  padding-right: ($height - $button-height) / 2
  padding-left: $height / 2

  box-shadow: $elevation-6

  +d-flex-r(center, space-between)
  +h-rounded($height)

  & > .e-button
    font-size: $f-size-sm

    margin-left: $button-padding

    +h-rounded($button-height, true)

.vue-notification-wrapper
  overflow: initial

// color
.refresh-notification
  background-color: var(--c-gray-2)

  & > .e-button
    --color: var(--c-white)
    --text-color: var(--c-secondary)
</style>
