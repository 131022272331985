<template>
  <div v-if="ready" class="header header--mobile">
    <div class="header__body">
      <component :is="link.component" class="mr-3" />
      <ProjectsServicesSelect />

      <div class="spacer" />

      <div class="d-flex-r d-flex-a-c h-spacing-2 ml-2">
        <MenuActivator v-if="state !== 'at-unauthenticated'" />
      </div>
    </div>
    <div class="header__body-placeholder" />
  </div>
</template>

<script>
import mixin from './mixin';

// components
import GoBackLink from './GoBackLink.vue';
import GoDefaultLink from './GoDefaultLink.vue';
import MenuActivator from '@/components/ui/Menu/MenuActivator.vue';
import ProjectsServicesSelect from './ProjectsServicesSelect/index.vue';

export default {
  name: 'HeaderMobile',
  components: {
    GoBackLink,
    GoDefaultLink,
    MenuActivator,
    ProjectsServicesSelect,
  },
  mixins: [mixin],
  computed: {
    link() {
      const component =
        this.state === 'at-collection-index' ? GoDefaultLink : GoBackLink;
      let title;

      if (this.state === 'at-collection-index') title = this.client.shortName;
      else if (this.state === 'at-unauthenticated')
        title = this.$t('g.product');

      return { component, title };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass
@import style.sass

// base
.header--mobile > .header__body
  +d-flex-r(center)
  +p-fixed-shell-top(#{$z-index-base + 2}, 0)

// START - height --------------------------------------------------------------
.header--mobile
  & > .header__body,
  & > .header__body-placeholder
    height: $header-height
// END - height ----------------------------------------------------------------

// START - padding -------------------------------------------------------------
.header--mobile > .header__body
  +h-padding($padding)
// END - padding ---------------------------------------------------------------

// color
.header--mobile > .header__body
  background-color: var(--c-background)
</style>
