export const SEQUENTIAL_PALETTE = Object.freeze([
  '#00A563',
  '#03B16E',
  '#05BE78',
  '#07CB84',
  '#08D88F',
  '#07E59B',
  '#05F2A7',
  '#00FFB3',
]);

export const DIVERGING_PALETTE = Object.freeze([
  '#FBE029',
  '#FFB11F',
  '#FF6634',
  '#FA2D57',
  '#E03E9E',
  '#7A5195',
  '#41C3FF',
  '#034D6F',
]);

export default DIVERGING_PALETTE;
