<template>
  <Tooltip
    v-bind="$attrs"
    :display="display"
    :class="['tooltip-on-open', { 'tooltip-on-open--open': isOpen }]"
  >
    <slot />
  </Tooltip>
</template>

<script>
import Tooltip from './Tooltip';

// components
import Template from './Template.vue';

export default {
  name: 'TooltipOnOpen',
  components: { Tooltip: Template },
  mixins: [Tooltip],
  props: {
    isOpen: { type: Boolean, required: true },
  },
  computed: {
    display() {
      return this.forceDisplay || (!this.disabled && this.isOpen);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.tooltip-on-open:not(.tooltip-on-open--open)
  pointer-events: none

.tooltip-on-open--open
  pointer-events: auto
</style>
