<template>
  <div
    :class="[
      $options.className,
      { [`${$options.className}--active`]: isActive },
    ]"
    @click="emitChange(key)"
  >
    <div class="d-flex-r d-flex-center"><slot :is-active="isActive" /></div>

    <div v-if="isActive" class="tab__indicator" />
  </div>
</template>

<script>
import Groupable from './Groupable';

export default {
  name: 'Tab',
  mixins: [Groupable],
  inject: ['emitChange'],

  className: 'tab',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$body-selector: 'div:first-child'

$min-width: 96px
$min-height: 48px

$border-width: 1px

$transition-duration: $duration-quickly
$transition-timing-function: linear

$indicator-height: 2px

.tab
  +button
  +d-flex-c
  +p-relative

// - body
.tab > #{$body-selector}
  flex-grow: 1

  min-width: $min-width
  min-height: $min-height
  padding: $spacing-2 $spacing-4

  border-bottom-style: solid
  border-bottom-width: $border-width
  border-left-style: solid
  border-left-width: $border-width

  transition: background-color $transition-duration $transition-timing-function

.tab:first-child > #{$body-selector}
  border-left: none

// - indicator
.tab__indicator
  content: ''

  flex-shrink: 0

  width: 100%
  height: $indicator-height

  opacity: 0

  transform: translateY(-$border-width)

  transition-duration: $transition-duration
  transition-property: opacity
  transition-timing-function: $transition-timing-function

.tab
  +on-active
    .tab__indicator
      opacity: 1

// color
// - body
.tab > #{$body-selector}
  border-bottom-color: var(--c-gray-1)
  border-left-color: var(--c-gray-1)

.tab
  +on-active
    & > #{$body-selector}
      background-color: var(--c-background)

// - indicator
.tab__indicator
  background-color: var(--c-primary)
</style>
