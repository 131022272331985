import PALETTE from './../palette';

const DEFAULT_PARENT_KEY = 'default';
const PARENTS = Object.freeze({
  [DEFAULT_PARENT_KEY]: require('./../Preset').default,

  CR: require('./../CRPreset').default,
});

export default parentKey => {
  parentKey = parentKey || DEFAULT_PARENT_KEY;

  return class CellPresetCore extends PARENTS[parentKey] {
    static PALETTE = PALETTE;

    constructor(id) {
      super(id);

      // binding
      _.bindAll(this, 'buildKPIs');
    }

    buildKPIs() {}
  };
};
