import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('collections', { projects: 'getAllProjects' }),
    ...mapGetters('collections', ['areAllProjectsFetched']),

    allProjectsReady() {
      return this.areAllProjectsFetched;
    },
  },
};
