<template>
  <div
    :class="[
      'formulate-input-element',
      `formulate-input-element--${context.type}`,
    ]"
    :data-type="context.type"
  >
    <ESwitch
      :value="value"
      :disabled="context.attributes.disabled"
      @input="context.model = String($event)"
    >
      {{ context.model }}
    </ESwitch>
  </div>
</template>

<script>
// components
import ESwitch from '@/components/formulate/Switch.vue';

export default {
  components: { ESwitch },
  props: {
    context: { type: Object, required: true },
  },
  computed: {
    value() {
      const { context } = this;

      return context.model
        ? context.model === 'true'
        : context.value === 'true';
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$type: 'odd-switch'

$border-radius: $b-radius-2
$border-width: 1px

$label-font-family: $f-family-primary
$label-font-size: $f-size-sm

.formulate-input[data-type="#{$type}"]
  padding: $spacing-3

  border-radius: $border-radius
  border-style: solid
  border-width: $border-width

  & > .formulate-input-wrapper
    +d-flex-r(center)

    & > .formulate-input-label
      font-family: $label-font-family
      font-size: $label-font-size

      margin-right: $spacing-4

// color
$border-color: var(--c-gray-1)

$label-color: var(--c-gray-2)

.formulate-input[data-type="#{$type}"]
  border-color: $border-color

  & > .formulate-input-wrapper > .formulate-input-label
    color: $label-color
</style>
