<template>
  <EButton v-show="!disabled" :to="to" v-bind="bind" v-on="$listeners">
    stats-bars
  </EButton>
</template>

<script>
export default {
  name: 'ProjectsShowSummaryLink',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      to: { name: 'projects-show', params: { projectId: this.project.id } },
    };
  },
  computed: {
    disabled() {
      return !this.project.manifest || !this.project.manifest.dashboard;
    },

    bind() {
      const bind = { navigation: true, md: true, disabled: this.disabled };

      return Object.assign(bind, this.$attrs);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
