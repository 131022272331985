<template>
  <div class="height-observer" v-bind="$attrs" v-on="$listeners">
    <slot />

    <ResizeObserver @notify="onNotify" />
  </div>
</template>

<script>
import { getElHeight } from '@/helpers';

export default {
  name: 'HeightObserver',
  mounted() {
    this.$nextTick(this.notify);
  },
  methods: {
    notify() {
      const height = getElHeight(this.$el);

      // if the height is 0, it will try to emit at next tick, just in case
      if (height === 0)
        this.$nextTick(() => {
          this.$emit('notify', getElHeight(this.$el));
        });
      else this.$emit('notify', height);
    },

    onNotify() {
      this.notify();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.height-observer
  position: relative
</style>
