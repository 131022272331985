<template>
  <div :style="style" :class="className" v-html="src">
    <slot />
  </div>
</template>

<script>
import { Elevatable, WithColor, WithSize } from './mixins';

export default {
  name: 'EIcon',
  mixins: [Elevatable, WithColor, WithSize],
  props: {
    weather: { type: Boolean, default: false },
  },
  data() {
    return {
      src: '',
    };
  },
  computed: {
    set() {
      return this.weather ? 'weather' : 'basic';
    },

    style() {
      return Object.assign({}, this.withColorStyle, this.withSizeStyle);
    },
    className() {
      const className = 'e-icon';

      return [className, this.elevatableClasses];
    },
  },
  created() {
    this.setSRC();
  },
  updated() {
    this.setSRC();
  },
  methods: {
    setSRC() {
      const { default: defaultSlot } = this.$slots;

      if (defaultSlot && defaultSlot.length > 0) {
        const name = defaultSlot[0].text.trim();

        this.src = require(`!!raw-loader!@/assets/icons/${this.set}/${name}.svg`).default;
      } else this.src = '';
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.e-icon
  --color: var(--c-secondary)
  --size: 24px

  display: inline-block
  flex-shrink: 0

  line-height: var(--size)

  box-sizing: content-box

  +size(var(--size))

  & > svg
    fill: var(--color)

    +size(100%)
</style>
