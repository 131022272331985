<template>
  <div class="doc-label">
    <t sm class="mb-1">{{ doc.name }}</t> <br class="mb-3" />

    <ActiveServicesLabel v-if="type === 'projects'" :services="services" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// components
import ActiveServicesLabel from '@/components/collection/services/ActiveServicesLabel.vue';

export default {
  name: 'DocLabel',
  components: { ActiveServicesLabel },
  props: {
    doc: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('collections', ['getServicesByIds']),

    services() {
      return this.type === 'projects'
        ? this.getServicesByIds(this.doc.servicesIds)
        : undefined;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
