export default {
  inject: ['getActiveItemKey', 'getItemKey'],
  computed: {
    key() {
      return this.getItemKey(this);
    },

    isActive() {
      return this.key === this.getActiveItemKey();
    },
  },
};
