<template>
  <div :style="style" class="progress-circular">
    <svg
      viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
      style="transform: rotate(0deg)"
    >
      <circle
        cx="45.714285714285715"
        cy="45.714285714285715"
        r="20"
        stroke-dasharray="125.664"
        stroke-dashoffset="125.66370614359172px"
        stroke-width="5.714285714285714"
        fill="transparent"
        class="progress-circular__overlay"
      />
    </svg>
  </div>
</template>

<script>
// FIX
export default {
  name: 'ProgressCircular',
  props: {
    color: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      return Object.assign(
        {},
        this.color && { '--color': `var(--c-${this.color})` },
        this.size && { '--size': `${this.size}px` }
      );
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$default-color: var(--c-white)
$default-size: 32px

.progress-circular
  position: relative

  flex-shrink: 0

  vertical-align: middle

  +size(var(--size, $default-size))

.progress-circular > svg
  margin: auto

  animation: progress-circular-rotate 1.4s linear infinite
  transform-origin: center center
  transition: all .2s ease-in-out

  +p-absolute($z-index-base, 0)
  +size(100%)

.progress-circular__overlay
  z-index: #{$z-index-base + 1}

  stroke-dasharray: 80, 200
  stroke-dashoffset: 0px
  stroke-linecap: round

  animation: progress-circular-dash 1.4s ease-in-out infinite
  transition: all .6s ease-in-out

// color
.progress-circular__overlay
  stroke: var(--color, $default-color)

@keyframes progress-circular-dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0px

  50%
    stroke-dasharray: 100, 200
    stroke-dashoffset: -15px

  100%
    stroke-dasharray: 100, 200
    stroke-dashoffset: -125px

@keyframes progress-circular-rotate
  100%
    transform: rotate(360deg)
</style>
