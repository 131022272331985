import PCancelable from 'p-cancelable';
import axios from 'axios';
import { functions } from '@/config/firebase';
import { time } from '@/helpers';

type OriginalVideoOptions = {
  original: true;
  original_step_unit: 'm';
  original_step_value: number;

  timezone: string;
};

export type GenerateVideoPayload = {
  serviceId: Id;
  dateRange: RemoteDateRange;

  process: RemoteProcess;

  quality?: Quality;
  playbackSpeed?: PlaybackSpeed;
  media: Media;

  options?: OriginalVideoOptions;
};

export type GenerateVideoImagePayload = {
  serviceId: Id;
  dateRange: RemoteDateRange;
  currentTime: number;

  process: RemoteProcess;

  quality: Quality;

  notify?: boolean;
};

declare global {
  type StorageFile = { filename: string; bucket: string; uri?: string };

  type Quality = 360 | 480 | 720 | 1080;
  type PlaybackSpeed = 0.25 | 0.5 | 1 | 2 | 4;
  type Media = 'm3u8' | 'mp4' | 'mpd';
}

const functions_ = {
  async fetchFileSignedURI(file: StorageFile) {
    const { data } = await functions.httpsCallable('fetchFileSignedURI')(file);

    return data;
  },

  fetchMediaSignedCookie() {
    return axios.get(`${process.env.VUE_APP_DOMAIN}/mediaSignedCookie`);
  },

  generateVideo(payload: GenerateVideoPayload) {
    return new PCancelable((resolve: Function, reject: Function) => {
      const remotePayload: {
        service: Id;
        dateRange: RemoteDateRange;

        processId: RemoteProcess;
        process: RemoteProcess;

        quality?: Quality;
        playbackSpeed?: PlaybackSpeed;
        format: Media;

        params?: OriginalVideoOptions;
      } = {
        service: payload.serviceId,
        dateRange: payload.dateRange,

        processId: payload.process,
        process: payload.process,

        quality: payload.quality,
        playbackSpeed: payload.playbackSpeed,
        format: payload.media,
      };
      if (payload.options) remotePayload.params = payload.options;

      functions
        .httpsCallable('getVideo')(remotePayload)
        .then(({ data }) => {
          if (data.error) reject(data.error);

          resolve({ uri: data.url, notificationId: data.docId });
        });
    });
  },

  generateVideoImage(payload: GenerateVideoImagePayload) {
    return new PCancelable((resolve: Function, reject: Function) => {
      const remotePayload = {
        service: payload.serviceId,
        dateRange: payload.dateRange,
        currentTime: payload.currentTime,

        processId: payload.process,
        process: payload.process,

        quality: payload.quality,

        notify: _.isUndefined(payload.notify) || payload.notify,
      };

      functions
        .httpsCallable('getVideoFrame')(remotePayload)
        .then(({ data }) => {
          if (data.error) reject(data.error);

          resolve({ uri: data.url });
        });
    });
  },

  async fetchOriginalVideoMetadata(payload: {
    serviceId: Id;
    date: Timestamp;
  }) {
    const remotePayload = {
      service: payload.serviceId,
      date: time.format.asRemoteDate(payload.date),
    };

    const { data } = await functions.httpsCallable('listFullVideos')(
      remotePayload
    );

    return { filenames: data };
  },

  async sendWelcome(payload: { email: string }) {
    const { data } = await functions.httpsCallable('sendWelcome')(payload);

    if (data && data.status !== 200) throw data.status;

    return data;
  },
};

export default functions_;
