import firebase from 'firebase/app';
import {
  asAggregation,
  asRemoteAggregation,
  isRemoteAggregation,
} from '@/aggregation';
import { asGranularity } from '@/granularity';
import {
  REMOTE_AGGREGATIONS_KEY,
  REMOTE_FIELDS_KEY,
  REMOTE_GRANULARITIES_KEY,
  REMOTE_GRANULARITY_TO_GROUP_BY_FIELD_MAPPING,
} from '../../constants';
import { METRICS_COLLECTION_REF } from './constants';
import {
  GroupByField,
  aggregateGroups,
  getArrayFromQuerySnapshot,
  getArrayFromQuerySnapshot2,
} from './helpers';

import DataArray from '../DataArray';
import MetricDocQuerier from './MetricDocQuerier';
import MetricErrorDispatcher from './MetricErrorDispatcher';

type EnrichedRemoteAggregation = { name: RemoteAggregation };

type RemoteMetricAggregation =
  | Record<RemoteGranularity, EnrichedRemoteAggregation[]>
  | EnrichedRemoteAggregation[]
  | RemoteAggregation[];

export type RemoteMetric = {
  id: Id;
  aggregation: Aggregation;
  field: string;
  label: string;
};

const arePlainObjectV2RemoteAggregations = (
  remoteAggregations: RemoteMetricAggregation
): remoteAggregations is Record<
  RemoteGranularity,
  EnrichedRemoteAggregation[]
> => _.isPlainObject(remoteAggregations);

const areArrayV2RemoteAggregations = (
  remoteAggregations: RemoteMetricAggregation
): remoteAggregations is EnrichedRemoteAggregation[] =>
  _.isArray(remoteAggregations) &&
  (remoteAggregations.length === 0 ||
    (remoteAggregations.length > 0 && _.isPlainObject(remoteAggregations[0])));

export class Metric extends DataArray {
  docRef: firebase.firestore.DocumentReference;

  id: Id;
  aggregation: RemoteAggregation;
  field: string;

  aggregations: Aggregation[];
  fields: string[];
  granularities: Granularity[];

  yPath: InstanceType<typeof MetricDocQuerier>;
  dispatchError: InstanceType<typeof MetricErrorDispatcher>['dispatch'];

  fetching: boolean;

  constructor(id: Id, aggregation: string, field: string, label: string) {
    super([], label);

    this.docRef = METRICS_COLLECTION_REF.doc(id);

    this.id = id;
    this.aggregation = !isRemoteAggregation(aggregation)
      ? asRemoteAggregation(<Aggregation>aggregation)
      : aggregation;
    this.field = field;

    this.aggregations = [];
    this.fields = [];
    this.granularities = [];

    this.yPath = new MetricDocQuerier(this.aggregation, field);
    this.dispatchError = new MetricErrorDispatcher(this).dispatch;

    this.fetching = false;

    // binding
    _.bindAll(this, ['empty', 'fetch', 'setup']);
  }

  async setup() {
    const doc = await this.docRef.get();
    if (!doc.exists) throw this.dispatchError('invalid-id');
    const docData = <firebase.firestore.DocumentData>doc.data();

    const remoteAggregations: RemoteMetricAggregation =
      docData[REMOTE_AGGREGATIONS_KEY];
    const remoteFields = docData[REMOTE_FIELDS_KEY];
    const remoteGranularities = docData[REMOTE_GRANULARITIES_KEY];

    this.aggregations = Metric.parseRemoteAggregations(remoteAggregations);
    this.fields = Metric.parseRemoteFields(remoteFields);

    if (arePlainObjectV2RemoteAggregations(remoteAggregations)) {
      const isValidGranularityForAggregation = (
        remoteGranularity: RemoteGranularity
      ) =>
        _.some(remoteAggregations[remoteGranularity], {
          name: this.aggregation,
        });

      this.granularities = Metric.parseRemoteGranularities(
        remoteGranularities,
        isValidGranularityForAggregation
      );
    } else if (areArrayV2RemoteAggregations(remoteAggregations)) {
      const isValidGranularityForAggregation = (
        remoteGranularity: RemoteGranularity
      ) =>
        _.some(remoteAggregations, {
          name: this.aggregation,
        });

      this.granularities = Metric.parseRemoteGranularities(
        remoteGranularities,
        isValidGranularityForAggregation
      );
    } else {
      this.granularities = Metric.parseRemoteGranularities(remoteGranularities);
    }
  }

  async fetch(
    remoteDateRange: { from: string; to: string },
    remoteGranularity: RemoteGranularity,
    hard = true,
    groupBy = false
  ) {
    try {
      this.fetching = true;

      const collectionRef = this.docRef.collection(remoteGranularity);
      const query = collectionRef
        .where('id', '>=', remoteDateRange.from)
        .where('id', '<=', remoteDateRange.to);
      const querySnapshot = await query.get();

      const groupByField = groupBy
        ? (<Partial<Record<RemoteGranularity, GroupByField>>>(
            REMOTE_GRANULARITY_TO_GROUP_BY_FIELD_MAPPING
          ))[remoteGranularity]
        : undefined;

      const callback = this.yPath.buildDocQuerier(
        remoteGranularity,
        groupByField
      );

      const array = groupByField
        ? aggregateGroups(
            getArrayFromQuerySnapshot2(querySnapshot, callback),
            groupByField
          )
        : getArrayFromQuerySnapshot(querySnapshot, callback);

      this.array = hard ? array : this.array.concat(array);
    } finally {
      this.fetching = false;
    }
  }

  empty() {
    this.array = [];
  }

  static parseRemoteAggregations(
    remoteMetricAggregations: RemoteMetricAggregation
  ) {
    const KEY = 'name';

    let remoteAggregations: RemoteAggregation[];

    if (arePlainObjectV2RemoteAggregations(remoteMetricAggregations))
      remoteAggregations = _.flatMap(remoteMetricAggregations, value =>
        _.map(value, KEY)
      );
    else if (areArrayV2RemoteAggregations(remoteMetricAggregations))
      remoteAggregations = _.flatMap(
        remoteMetricAggregations,
        value => value[KEY]
      );
    else remoteAggregations = remoteMetricAggregations;

    return _.map(remoteAggregations, asAggregation);
  }

  static parseRemoteFields(remoteFields: string[]) {
    return remoteFields;
  }

  static parseRemoteGranularities(
    remoteGranularities: RemoteGranularity[],
    callback?: (remoteGranularity: RemoteGranularity) => boolean
  ) {
    if (callback) remoteGranularities = _.filter(remoteGranularities, callback);

    return _.map(remoteGranularities, asGranularity);
  }

  static fromRemoteMetric(remoteMetric: RemoteMetric) {
    const { id, aggregation, field, label } = remoteMetric;

    return new Metric(id, aggregation, field, label);
  }
}

export default Metric;
