import { createPlugin, storeModule } from 'vuex-viewport';

const MEDIAS = ['xs', 'sm', 'md', 'lg', 'xl'];

const module = {
  namespaced: true,

  state: storeModule.state,
  mutations: storeModule.mutations,
  actions: storeModule.actions,
  getters: {
    ...storeModule.getters,

    mq(state, getters) {
      const mq = { current: getters.mediaName, [getters.mediaName]: true };
      const index = _.indexOf(MEDIAS, getters.mediaName);

      MEDIAS.slice(0, index + 1).forEach(media => (mq[`${media}+`] = true));
      MEDIAS.slice(index).forEach(media => (mq[`${media}-`] = true));

      return mq;
    },
  },
};

export default {
  module,
  plugin: createPlugin,
};
