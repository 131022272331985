<template>
  <component :is="component" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
// components
import ProgressCircular from './ProgressCircular.vue';
import ProgressOdd from './ProgressOdd.vue';

export default {
  name: 'Progress',
  components: { ProgressCircular, ProgressOdd },
  props: {
    variant: { type: String, default: 'circular' },
  },
  computed: {
    component() {
      const { variant } = this;

      if (variant === 'circular') return ProgressCircular;
      if (variant === 'odd') return ProgressOdd;

      return undefined;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
