<template>
  <TabsHeader
    v-if="atIndex"
    :tabs="tabsPayload.tabs"
    :active-tab="tabsPayload.activeTab"
    class="projects-services-select"
    @active-tab-change="onActiveTabChange"
  />

  <EInput
    v-else-if="atShow"
    type="select"
    :value="projectIndex"
    :label="inputSelectPayload.label"
    :icon="inputSelectPayload.icon"
    :options="inputSelectPayload.options"
    class="projects-services-select"
    @input="onProjectIndexChange($event)"
  >
    <template v-slot:option="{ option }">
      <DocLabel type="projects" :doc="option.project" />
    </template>
  </EInput>
</template>

<script>
import { mapGetters } from 'vuex';
import { WithProject } from '@/mixins';
import machine from './machine';

// components
import DocLabel from '@/components/collection/DocLabel.vue';
import TabsHeader from '@/components/ds/TabsHeader.vue';

/**
 * our machine has two different state change sources; the <$route> object
 * and the user itself. The <$route> object can change it because
 * <ProjectServicesSelect> acts like a navigation component, so have to
 * reflect the current route, and the user because the "toggleable"
 * feature does not depend on the current route.
 */

export default {
  name: 'ProjectsServicesSelect',
  components: { DocLabel, TabsHeader },
  mixins: [machine, WithProject()],
  data() {
    return {
      tabsOptions: Object.freeze([
        {
          key: 'projects',
          text: this.$t('g.projects'),
          icon: 'map-marker',
          value: 0,
        },
        {
          key: 'services',
          text: this.$t('g.services'),
          icon: 'video',
          value: 1,
        },
      ]),
    };
  },
  computed: {
    ...mapGetters('collections', ['getAllProjects', 'getProjectById']),

    atIndex() {
      return (
        this.machine.state.matches('allProjects') ||
        this.machine.state.matches('allServices')
      );
    },
    atShow() {
      return (
        this.machine.state.matches('project') ||
        this.machine.state.matches('service')
      );
    },

    activeTab() {
      if (this.machine.state.matches('allProjects')) return 0;
      if (this.machine.state.matches('allServices')) return 1;

      return NaN;
    },
    projectIndex() {
      if (!this.projectReady) return NaN;

      if (this.atShow) {
        const { projectId } = this.$route.params;

        return _.findIndex(this.inputSelectPayload.options, { id: projectId });
      }

      return NaN;
    },

    tabsPayload() {
      return {
        tabs: this.tabsOptions,
        activeTab: this.activeTab,
      };
    },
    inputSelectPayload() {
      const buildOption = project => ({
        id: project.id,
        text: project.name,
        project,
      });
      const projectsAsOptions = this.getAllProjects.map(buildOption);

      return {
        label: this.$t('g.projects'),
        icon: 'map-marker',
        options: projectsAsOptions,
      };
    },
  },
  watch: {
    $route: 'onRouteChange',
  },
  methods: {
    getInitialState() {
      const { name } = this.$route;

      if (name === 'projects-index') return 'allProjects';
      else if (name === 'services-index') return 'allServices';
      else if (
        name === 'projects-show' ||
        name === 'projects-show-services-index'
      )
        return 'project';
      else if (name === 'projects-show-services-show') return 'service';
    },

    onRouteChange({ name }) {
      let event;

      if (name === 'projects-index') event = 'TO_ALL_PROJECTS';
      if (name === 'projects-show' || name === 'projects-show-services-index')
        event = 'TO_PROJECT';
      if (name === 'services-index') event = 'TO_ALL_SERVICES';
      if (name === 'projects-show-services-show') event = 'TO_SERVICE';

      // state change from <$route> object
      if (event) this.send(event);
    },
    onActiveTabChange() {
      // state change from user itself
      this.send('TOGGLE');

      if (this.machine.state.matches('allProjects'))
        this.$router.push({
          name: 'projects-index',
          query: { forward: false },
        });
      else if (this.machine.state.matches('allServices'))
        this.$router.push({
          name: 'services-index',
          query: { forward: false },
        });
      else if (this.machine.state.matches('service')) {
        const { projectId } = this.$route.params;

        this.$router.push({
          name: 'projects-show',
          params: { projectId },
        });
      }
    },
    onProjectIndexChange(index) {
      const collection = this.inputSelectPayload.options[index];

      this.$router.push({
        name: 'projects-show',
        params: { projectId: collection.id },
      });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// color
.projects-services-select .input-select__option
  +on-active
    .doc-label > .e-text
      --color: var(--c-primary)

  &--active
    .doc-label > .e-text
      font-weight: $f-weight-semibold
</style>
