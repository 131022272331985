// - cell
import Counter from '@/charting/presets/cell/Counter';
import Hours from '@/charting/presets/cell/Hours';
import Occupancy from '@/charting/presets/cell/Occupancy';
import SocialDistancing from '@/charting/presets/cell/SocialDistancing';
import WorkDay from '@/charting/presets/cell/WorkDay';
import WorkDaySE from '@/charting/presets/cell/WorkDaySE';

// - cell/raws
import CounterRaw from '@/charting/presets/cell/raws/CounterRaw';
import Raw from '@/charting/presets/cell/raws/Raw';
import SocialDistancingRaw from '@/charting/presets/cell/raws/SocialDistancingRaw';

// - container
import Industrial from '@/charting/presets/container/Industrial';
import IndustrialSE from '@/charting/presets/container/IndustrialSE';
import Occupancies from '@/charting/presets/container/Occupancies';
import SocialDistancingContainer from '@/charting/presets/container/SocialDistancing';

const parsePresetManifest = (id, manifest) => {
  id = id.toLowerCase();

  const type = manifest['preset'];
  const payload = manifest['preset_parameters'];

  switch (type) {
    // - cell
    case 'Counter':
      return new Counter(id).build(payload);
    case 'HH':
      return new Hours(id).build(payload);
    case 'Occupancy':
      return new Occupancy(id).build(payload);
    case 'SocialDistancing':
      return new SocialDistancing(id).build(payload);
    case 'WorkDay':
      return new WorkDay(id).build(payload);
    case 'WorkDaySE':
      return new WorkDaySE(id).build(payload);

    // - cell/raws
    case 'CounterRaw':
      return new CounterRaw(id).build(payload);
    case 'Raw':
      return new Raw(id).build(payload);
    case 'SocialDistancingRaw':
      return new SocialDistancingRaw(id).build(payload);

    // - container
    case 'Industrial':
      return new Industrial(id).build(payload);
    case 'IndustrialSE':
      return new IndustrialSE(id).build(payload);
    case 'Occupancies':
      return new Occupancies(id).build(payload);
    case 'SocialDistancingContainer':
      return new SocialDistancingContainer(id).build(payload);

    default:
      return manifest;
  }
};

export default parsePresetManifest;
