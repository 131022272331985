import Vue from 'vue';
import VueRouter from 'vue-router';
import { authentication, projects, services } from './routes';
import { guards } from './routes/projects';
import { isAuthenticated } from './routes/authentication';

Vue.use(VueRouter);

export const getDefaultRoute = () => ({ name: 'projects-index' });

const routes = [
  ...authentication,
  ...projects,
  ...services,

  {
    path: '/',
    name: 'home',
    redirect: { name: 'projects-index' },
  },

  {
    path: '/reports/:reportId',
    name: 'reports-show',
    component: () =>
      import(
        /* webpackChunkName: "reports-show" */ '../views/reports/show.vue'
      ),
  },

  {
    path: '/monitor',
    name: 'monitor',
    component: () =>
      import(/* webpackChunkName: "monitor" */ '../views/Monitor.vue'),
    meta: {
      layout: { name: 'withHeader' },
    },
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "missing" */ '../views/Missing.vue'),
  },
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticationRequired = to.matched.some(r => r.meta.authenticated);

  if (isAuthenticationRequired && !isAuthenticated()) {
    const redirect = to.fullPath;

    next({ name: 'sign-in', query: { redirect } });
  } else if (to.name === 'projects-index') {
    guards.index.beforeEnter(to, from, next);
  } else if (to.name === 'projects-show') {
    guards.show.beforeEnter(to, from, next);
  } else if (to.name === 'projects-show-services-index') {
    guards.services.index.beforeEnter(to, from, next);
  } else next();
});

export default router;
