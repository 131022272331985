<template>
  <div class="tabs"><slot /></div>
</template>

<script>
import Grouper from './Grouper';

export default {
  name: 'Tabs',
  mixins: [Grouper],
  model: { prop: 'tab', event: 'change' },
  props: {
    tab: { type: Number, default: 0 },
  },
  methods: {
    getActiveItemKey() {
      return this.tab;
    },

    emitChange(...payload) {
      this.$emit('change', ...payload);
    },
  },
  provide() {
    return { emitChange: this.emitChange };
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.tabs
  display: grid
  grid-auto-flow: column
  grid-template-rows: 1fr

  overflow-x: auto

  +no-scrollbar

// color
.tabs
  background-color: var(--c-gray-0)
</style>
