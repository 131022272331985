<template>
  <div :class="className" @click="onClick">
    <template v-if="!icon">
      <icon>check</icon>
    </template>

    <template v-else>
      <icon><slot /></icon>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ESwitch',
  props: {
    value: { type: Boolean, required: true },

    disabled: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    trueValue: { type: Boolean, default: true },
  },
  computed: {
    className() {
      const className = 'switch';

      return [
        className,
        {
          [`${className}--active`]: this.value === this.trueValue,
          [`${className}--disabled`]: this.disabled,
          [`${className}--icon`]: this.icon,
        },
      ];
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;

      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$width: 40px
$height: 20px
$padding: 2px

.switch
  flex-shrink: 0

.switch:not(.switch--icon)
  $icon-size: $height - $padding * 2

  width: $width
  padding: $padding

  transition: background-color

  +button
  +h-rounded($height)

  & > .e-icon
    --size: #{$icon-size}

    border-radius: $b-radius-circle
    box-shadow: $elevation-1

    transition: transform

  // - active
  &.switch--active > .e-icon
    transform: translateX(#{$width - $icon-size - $padding * 2})

  // - disabled
  &.switch--disabled
    pointer-events: none

// - transitions
.switch:not(.switch--icon)
  &, & > .e-icon
    transition-duration: $duration-normal
    transition-timing-function: $ease-in-out-quint

// color
.switch:not(.switch--icon)
  $background-color:  var(--c-gray-1)
  $active-background-color: var(--c-primary)

  $icon-background-color: var(--c-white)

  background-color: #{$background-color}

  & > .e-icon
    --color: #{$icon-background-color}

    background-color: $icon-background-color

  +on-hover
    background-color: #{$active-background-color}

  // - active
  &.switch--active
    background-color: #{$active-background-color}

    & > .e-icon
      --color: #{$active-background-color}

  // - disabled
  &.switch--disabled
    background-color: #{$background-color}

    & > .e-icon
      --color: #{$icon-background-color}
</style>
