import CellPreset from './../CellPreset';

import { KPI_LABEL, LEGEND_LABEL } from './constants';

export class SocialDistancing extends CellPreset {
  constructor(id) {
    super(id);

    // binding
    _.bindAll(this, ['buildDatasetsFromMetricsIds', 'parsePayload']);
  }

  buildDatasetsFromMetricsIds(metricsIds) {
    let safeMetricId, unsafeMetricId;

    if (_.isPlainObject(metricsIds)) {
      safeMetricId = metricsIds.safe;
      unsafeMetricId = metricsIds.unsafe;
    } else if (_.isArray(metricsIds)) {
      safeMetricId = metricsIds[0];
      unsafeMetricId = metricsIds[1];
    } else if (_.isString(metricsIds))
      safeMetricId = unsafeMetricId = metricsIds;

    return [
      {
        metrics: [
          {
            id: safeMetricId,
            aggregation: 'SUM',
            field: 'object_count',
          },
        ],

        label: LEGEND_LABEL.safe,
        options: { color: 'green' },
      },
      {
        key: 'unsafe',

        metrics: [
          {
            id: unsafeMetricId,
            aggregation: 'SUM',
            field: 'object_count',
          },
        ],

        label: LEGEND_LABEL.unsafe,
        options: { color: 'red' },
      },
    ];
  }

  parsePayload(remotePayload) {
    const DEFAULT_PAYLOAD = { byHour: false };

    return _.merge(DEFAULT_PAYLOAD, {
      ...remotePayload,

      metricsIds: remotePayload['metrics_ids'],
      byHour: remotePayload['by_hour'],
    });
  }

  build(payload) {
    const { metricsIds, byHour } = this.parsePayload(payload);

    return {
      title: {
        es: '¿Cuántas interacciones inseguras han ocurrido?',
        en: 'How many unsafe interactions have occurred?',
      },

      areas: [
        {
          data_address_parameters: {
            datasets: this.buildDatasetsFromMetricsIds(metricsIds),
            groupBy: Boolean(byHour),
          },

          type: 'BarChart',
          type_parameters: {
            stacked: true,
            unit: byHour ? 'hour' : undefined,
          },

          kpis: {
            datasets_keys: 'unsafe',
            aggregation: 'sum',

            label: KPI_LABEL,
            icon: 'person',
          },
        },
      ],
    };
  }
}

export default SocialDistancing;
