<template>
  <div
    class="download-drawer-video-image-preview b-radius-2 o-hidden elevation-1"
  >
    <transition name="fade-normal" mode="out-in">
      <Dummy v-if="fetching" shimmer />

      <EImage
        v-else-if="!fetching && isURI"
        v-show="complete"
        :src="uri"
        @complete="complete = true"
      />
    </transition>
  </div>
</template>

<script>
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';
import { functions, isURI } from '@/helpers';

// components
import { EImage } from 'odd-ds';
import Dummy from '@/components/utils/Dummy.vue';

const DEFAULT_QUALITY = 360;

export default {
  name: 'DownloadDrawerVideoImagePreview',
  components: { Dummy, EImage },
  props: {
    serviceId: { type: String, required: true },
    dateRange: { type: Object, required: true },
    currentTime: { type: Number, required: true },

    quality: { type: Number, default: DEFAULT_QUALITY },
    process: { type: String, default: PROCESSES.default },
  },
  data() {
    return {
      uri: '',

      complete: false,
      fetching: false,
    };
  },
  computed: {
    payload() {
      return {
        serviceId: this.serviceId,
        dateRange: this.$time.getDateRangeAsRemoteDateRange(this.dateRange),
        currentTime: this.currentTime,

        process: this.process,

        quality: this.quality,

        notify: false,
      };
    },
    payloadKey() {
      const { payload } = this;

      return `${payload.currentTime}${payload.process}`;
    },

    isURI() {
      return isURI(this.uri);
    },
  },
  watch: {
    payloadKey: { handler: 'fetch', immediate: true },
  },
  methods: {
    async fetch() {
      try {
        this.fetching = true;

        this.uri = (await functions.generateVideoImage(this.payload)).uri;
      } catch (error) {
        this.uri = '';
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.download-drawer-video-image-preview
  +aspect-ratio

  & > .dummy
    +size(100%)
</style>
