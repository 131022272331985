<template>
  <div v-click-outside="close" :data-type="context.type" :class="className">
    <div
      :class="[
        `${$options.className}__input`,
        'd-flex-r d-flex-a-c box b-radius-2',
      ]"
      @click="toggle"
    >
      <t sm medium>{{ context.options[context.model].label }}</t>

      <div class="spacer" />

      <icon>chevron-down</icon>
    </div>

    <TooltipOnOpen
      v-if="isMounted"
      :target="$el"
      :is-open="isOpen"
      :class="[`${$options.className}__options`, 'b-radius-2 elevation-2']"
    >
      <div
        v-for="(option, index) in context.options"
        :key="option.id"
        :active="index === Number(context.model)"
        @click="onOptionClick(option)"
      >
        <t sm medium>{{ option.label }}</t>
      </div>
    </TooltipOnOpen>
  </div>
</template>

<script>
import { Openable, WithIsMounted } from '@/mixins';

// components
import TooltipOnOpen from '@/components/ui/Tooltip/TooltipOnOpen.vue';

export default {
  components: { TooltipOnOpen },
  mixins: [Openable, WithIsMounted],
  props: {
    context: { type: Object, required: true },
  },
  computed: {
    className() {
      const { className } = this.$options;

      return [
        className,
        this.context.classes.element,
        { [`${className}--open`]: this.isOpen },
      ];
    },
  },
  methods: {
    onOptionClick(option) {
      const { context } = this;

      context.model = String(_.indexOf(context.options, option));

      this.close();
    },
  },

  className: 'odd-select',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$type: 'odd-select'

$height: 40px
$max-height: 128px
$z-index: 2

$icon-size: 24px

.formulate-input[data-type="#{$type}"]
  +p-relative($z-index)

.odd-select__input
  +button
  +text-height($height)

  & > .e-text, & > .e-icon
    transition-duration: $duration-normal
    transition-timing-function: $ease-in-out-quint

  & > .e-text
    transition-property: color

    +h-padding($spacing-4)

  & > .e-icon
    --size: #{$icon-size}

    margin-right: #{($height - $icon-size) / 2}

    transition-property: transform

.odd-select__options
  width: 100%
  max-height: $max-height

  box-sizing: content-box
  overflow-y: auto

  +p-absolute(#{$z-index + 1})
  +v-padding($spacing-2)

  & > div
    +button
    +h-padding($spacing-4)
    +text-height($height)

  & > div[active="true"]
    & > .e-text
      --color: var(--c-primary)

// - open
.odd-select--open > .odd-select__input
  & > .e-text
    --color: var(--c-primary)

  & > .e-icon
    transform: rotate(180deg)

// color
.odd-select__input
  background-color: var(--c-background)

.odd-select__options
  background-color: var(--c-background)

  & > div
    +on-hover
      background-color: var(--c-gray-0)
</style>
