import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('time', {
      from(state) {
        return state.dimensions[this.dimensionId].from;
      },
      to(state) {
        return state.dimensions[this.dimensionId].to;
      },
      minFrom(state) {
        return state.dimensions[this.dimensionId].minFrom;
      },
      maxTo(state) {
        return state.dimensions[this.dimensionId].maxTo;
      },
    }),

    dateRange() {
      return { from: this.from, to: this.to };
    },
    limitDateRange() {
      return { minFrom: this.minFrom, maxTo: this.maxTo };
    },

    timeReady() {
      return this.$store.getters['time/ready'](this.dimensionId);
    },
  },
};
