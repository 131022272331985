export const findNotDisabledValue = (
  value: any,
  values: any[],
  disabledValues: Set<any>,
  guaranteedValue: any
) => {
  if (!values.includes(value) || disabledValues.has(value)) {
    if (value !== guaranteedValue) value = guaranteedValue;

    if (disabledValues.has(value)) {
      value = _.find(values, value => !disabledValues.has(value));

      return !_.isUndefined(value) ? value : undefined;
    }

    return value;
  }

  return value;
};
