<template>
  <div class="v-spacing-4">
    <DownloadDrawerQualityInput
      v-model="form.quality"
      :options="qualitiesAsOptions"
    />

    <DownloadDrawerPlaybackSpeedInput
      v-model="form.playbackSpeed"
      :options="playbackSpeedsAsOptions"
    />

    <DownloadDrawerWithDetectionsInput
      :value="String(form.withDetections)"
      disabled
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Form from './../Form';
import { playbackSpeedsAsOptions, qualitiesAsOptions } from './../helpers.ts';

// components
import DownloadDrawerPlaybackSpeedInput from '@/components/ui/DownloadDrawer/inputs/PlaybackSpeed.vue';
import DownloadDrawerQualityInput from '@/components/ui/DownloadDrawer/inputs/Quality.vue';
import DownloadDrawerWithDetectionsInput from '@/components/ui/DownloadDrawer/inputs/WithDetections.vue';

export default {
  name: 'DownloadDrawerVideoTimelapseForm',
  components: {
    DownloadDrawerPlaybackSpeedInput,
    DownloadDrawerQualityInput,
    DownloadDrawerWithDetectionsInput,
  },
  mixins: [Form],
  computed: {
    ...mapState('download', {
      playbackSpeeds: state => _.orderBy(state.video.playbackSpeeds),
      disabledPlaybackSpeeds: state => state.video.disabledPlaybackSpeeds,

      qualities: state => state.video.qualities,
      disabledQualities: state => state.video.disabledQualities,

      storeForm: state => ({
        playbackSpeed: state.video.payload.playbackSpeed,
        quality: state.video.payload.quality,
        withDetections: state.video.payload.withDetections,
      }),
    }),

    playbackSpeedsAsOptions() {
      const { playbackSpeeds, disabledPlaybackSpeeds } = this;

      return playbackSpeedsAsOptions(playbackSpeeds, disabledPlaybackSpeeds);
    },
    qualitiesAsOptions() {
      const { qualities, disabledQualities } = this;

      return qualitiesAsOptions(qualities, disabledQualities);
    },
  },
  methods: {
    ...mapMutations('download', { setPayload: 'set-video-payload' }),

    syncDown() {
      const { storeForm } = this;

      this.form = {
        playbackSpeed: String(storeForm.playbackSpeed),
        quality: String(storeForm.quality),
        withDetections: String(storeForm.withDetections),
      };
    },
    syncUp() {
      const { form } = this;

      this.setPayload({
        playbackSpeed: Number(form.playbackSpeed),
        quality: Number(form.quality),
        withDetections: form.withDetections === 'true',
      });
    },
  },

  path: 'c.download-drawer',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.formulate-input[data-type="odd-radio"].odd-radio--big
  & > .formulate-input-wrapper > .formulate-input-group
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: $spacing-2

    div[data-classification="box"]
      margin-left: 0

      .e-button
        width: 100%

.formulate-input[data-type="odd-radio"].odd-radio--square
  & > .formulate-input-wrapper > .formulate-input-group
    div[data-classification="box"]
      flex: 1

      .e-button
        width: 100%
</style>
