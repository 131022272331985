import { camelCaseObject } from '@/helpers';

export class PresetCore {
  static VALID_PROPERTIES = {
    id: (remoteId, ctx) => {
      const { DEFAULT_REMOTE_ID: DEFAULT } = PresetCore;

      return remoteId || (ctx && ctx.id) || DEFAULT;
    },
  };

  static DEFAULT_REMOTE_ID = null;

  id;

  constructor(id) {
    this.id = id;

    // binding
    _.bindAll(this, ['build', 'parseRemotePayload', 'setup']);
  }

  parseRemotePayload(remotePayload, variants, Self) {
    Self = Self || this.constructor;
    const Parent = Object.getPrototypeOf(Self);

    if (variants) {
      const variant = variants[remotePayload.variant || Self.DEFAULT_VARIANT];
      remotePayload = _.merge({}, variant, remotePayload);
    }
    remotePayload = camelCaseObject(remotePayload);

    let theirs, ours;

    if (Parent && Parent.prototype && Parent.prototype.parseRemotePayload)
      theirs = Parent.prototype.parseRemotePayload.call(
        this,
        remotePayload,
        undefined,
        Parent
      );

    if (_.isArray(Self.VALID_PROPERTIES))
      ours = _.pick(remotePayload, Self.VALID_PROPERTIES);
    else if (_.isPlainObject(Self.VALID_PROPERTIES)) {
      ours = _.mapValues(Self.VALID_PROPERTIES, (value, key) => {
        let callback;
        let remoteValue = remotePayload[key];

        if (_.isFunction(value)) callback = value;
        else if (_.isPlainObject(value)) {
          const { aliases = [] } = value;
          callback = value.callback || _.identity;

          for (const alias of aliases)
            if (_.isUndefined(remoteValue)) remoteValue = remotePayload[alias];
            else break;
        }

        return callback(remoteValue, this);
      });
    }

    return _.merge({}, theirs, ours);
  }

  setup(payload, Self) {
    Self = Self || this.constructor;
    const Parent = Object.getPrototypeOf(Self);

    if (Parent && Parent.prototype && Parent.prototype.setup)
      Parent.prototype.setup.call(this, payload, Parent);

    let properties;

    if (_.isArray(Self.VALID_PROPERTIES)) properties = Self.VALID_PROPERTIES;
    else if (_.isPlainObject(Self.VALID_PROPERTIES))
      properties = Object.keys(Self.VALID_PROPERTIES);

    Object.assign(this, _.pick(payload, properties));
  }

  build() {}
}

export default PresetCore;
