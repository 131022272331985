<template>
  <DownloadDrawerInputWrapper
    :label="$t(`${$options.path}.title`)"
    :description="$t(`${$options.path}.body`)"
  >
    <div class="d-flex-r d-flex-center mt-3">
      <DoubleRangeWithLabel
        v-if="!fetching && ready"
        :value="value"
        :segments="segments"
        :max-difference="maxDifference"
        :max="max"
        :min-difference="minDifference"
        :min="min"
        v-bind="$attrs"
        @input="$emit('input', $event)"
      >
        <template v-slot:start>
          <t xs medium no-wrap>{{ (min / 60) | asHourOfDay }}</t>
        </template>

        <template v-slot:default>
          <t xs medium no-wrap>
            {{ (value[0] / 60) | asHourOfDay }}
            -
            {{ (value[1] / 60) | asHourOfDay }}
          </t>
        </template>

        <template v-slot:end>
          <t xs medium no-wrap>{{ (max / 60) | asHourOfDay }}</t>
        </template>
      </DoubleRangeWithLabel>

      <Progress v-else-if="fetching" :size="20" color="primary" />
    </div>

    <div v-if="ready" class="d-flex-r d-flex-a-c mt-3">
      <t sm medium class="mr-3">
        {{ $t(`${$options.path}.duration`) }}

        <t xs no-wrap>{{ duration | asMinutes }}</t>
      </t>

      <Range
        v-model="duration"
        :max="maxDifference"
        :min="minDifference"
        v-bind="$attrs"
        highlighted
      />
    </div>
  </DownloadDrawerInputWrapper>
</template>

<script>
import { functions } from '@/helpers';
import { getSegments, parseFilenames } from './helpers';

// components
import DoubleRangeWithLabel from '@/components/formulate/DoubleRangeWithLabel.vue';
import DownloadDrawerInputWrapper from '@/components/ui/DownloadDrawer/InputWrapper.vue';
import Range from '@/components/formulate/Range/index.vue';

export default {
  name: 'DownloadDrawerTimeRangeInput',
  components: { DoubleRangeWithLabel, DownloadDrawerInputWrapper, Range },
  props: {
    value: { type: Array, required: true },

    serviceId: { type: String, required: true },
    date: { type: Object, required: true },

    maxDuration: { type: Number, required: true },
    minDuration: { type: Number, required: true },
  },
  data() {
    return {
      segments: null,

      max: null,
      min: null,

      fetching: true,
    };
  },
  computed: {
    dayDuration() {
      if (!this.ready) return NaN;

      return this.max - this.min;
    },
    maxDifference() {
      if (!this.ready) return NaN;

      return _.min([this.dayDuration, this.maxDuration]);
    },
    minDifference() {
      if (!this.ready) return NaN;

      return _.min([this.dayDuration, this.minDuration]);
    },

    duration: {
      get() {
        const { value } = this;

        return value[1] - value[0];
      },
      set(newDuration) {
        const { max: maxRightTime, value } = this;

        const newTimeRange = [...value];
        const newRightTime = newTimeRange[0] + newDuration;

        if (newRightTime <= maxRightTime) newTimeRange[1] = newRightTime;
        else {
          const overflow = newRightTime - maxRightTime;

          newTimeRange[1] = newTimeRange[0] + newDuration - overflow;
          newTimeRange[0] = newTimeRange[0] - overflow;
        }

        this.$emit('input', newTimeRange);
      },
    },

    ready() {
      return (
        _.isArray(this.segments) && _.isFinite(this.max) && _.isFinite(this.min)
      );
    },
  },
  watch: {
    date: {
      async handler() {
        const STEP = 3; // minutes

        try {
          this.fetching = true;

          const { filenames } = await functions.fetchOriginalVideoMetadata({
            serviceId: this.serviceId,
            date: this.date,
          });
          const { from, to, timestamps } = parseFilenames(filenames);

          this.segments = getSegments(
            { from, to, timestamps },
            STEP * 60 * 1000
          );

          const midnight = moment.parseZone(from).startOf('day');

          this.max = to.diff(midnight, 'minutes');
          this.min = from.diff(midnight, 'minutes');
        } finally {
          this.fetching = false;
        }
      },
      immediate: true,
    },
  },

  path: 'c.download-drawer.time-range',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
