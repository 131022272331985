import i18n from '@/i18n';
import {
  GRANULARITY_TO_UNIT_MAPPING,
  REMOTE_GRANULARITY_TO_FORMAT_MAPPING,
  REMOTE_GRANULARITY_TO_UNIT_MAPPING,
} from '@/granularity';
import { STATUS } from '@/API/preprocessor/constants.ts';

type AsUnitKey =
  | 'asDays'
  | 'asHours'
  | 'asMinutes'
  | 'asMonths'
  | 'asWeeks'
  | 'asYears';
type Offset = { unit: PUnit; value: any };

export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_LONG_FORMAT = 'DD MMMM, YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const REMOTE_DATE_FORMAT = 'YYYY-MM-DD';
export const REMOTE_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const WEATHER_DATE_FORMAT = 'ddd DD/MM';
export const TIME_FORMAT = 'HH:mm';
export const TIME_LONG_FORMAT = 'HH:mm:ss';

const GRANULARITIES = Object.freeze({
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
});

const plugin: Vue.PluginObject<{}> = {
  install(Vue) {
    const { format } = time;

    Object.entries(format).forEach(([key, handler]) =>
      Vue.filter(key, handler)
    );

    Vue.prototype.$time = time;
  },
};

const time = {
  moment,

  formats: {
    date: DATE_FORMAT,
    dateLong: DATE_LONG_FORMAT,
    datetime: DATETIME_FORMAT,
    remoteDate: REMOTE_DATE_FORMAT,
    remoteDatetime: REMOTE_DATETIME_FORMAT,
    time: TIME_FORMAT,
  },

  format: {
    // general
    as: (timestamp: Timestamp, ...payload: any[]) =>
      timestamp.format(...payload),

    // datetime
    asDatetime: (timestamp: Timestamp) => timestamp.format(DATETIME_FORMAT),
    asRemoteDatetime: (timestamp: Timestamp) =>
      timestamp.format(REMOTE_DATETIME_FORMAT),

    // date
    asDate: (timestamp: Timestamp, long = false) =>
      timestamp.format(long ? DATE_LONG_FORMAT : DATE_FORMAT),
    asDateRange: ({ from, to }: DateRange) => {
      const from_ = time.format.asDate(from);
      const to_ = time.format.asDate(to);

      if (from_ === to_) return from_;
      return `${from_} - ${to_}`;
    },

    asRemoteDate: (timestamp: Timestamp) =>
      timestamp.format(REMOTE_DATE_FORMAT),
    asRemoteDateRange: ({ from, to }: DateRange) => {
      const from_ = time.format.asRemoteDate(from);
      const to_ = time.format.asRemoteDate(to);

      if (from_ === to_) return from_;
      return `${from_} - ${to_}`;
    },

    asWeatherDate: (timestamp: Timestamp) => {
      return time.isToday(timestamp)
        ? timestamp.format(`[${i18n.t('g.today')}] DD/MM`)
        : timestamp.format(WEATHER_DATE_FORMAT);
    },

    // time
    asTime: (timestamp: Timestamp | moment.Duration, long = false) =>
      moment.isMoment(timestamp)
        ? timestamp.format(long ? TIME_LONG_FORMAT : TIME_FORMAT)
        : // @ts-ignore
          timestamp.format(long ? TIME_LONG_FORMAT : TIME_FORMAT, {
            trim: false,
          }),

    asDuration: (seconds: number) =>
      // @ts-ignore
      moment.duration(seconds, 'seconds').format('HH:mm', { trim: false }), // "HH:mm"
    asHourOfDay: (hours: number) =>
      // @ts-ignore
      moment.duration(hours, 'hours').format('HH:mm', { trim: false }), // "HH:mm"
    asMinutes: (minutes: number) =>
      // @ts-ignore
      moment.duration(minutes, 'minutes').format('m [min]'), // "m min"
    asZeroPaddedHour: (hour: number) => _.padStart(`${hour}00`, 4, '0'), // "HH00"

    // others...
    asKey: (timestamp: Timestamp) => {
      // "HH00-(HH+1)00"

      const { asZeroPaddedHour } = time.format;
      const hour = timestamp.hours();

      return `${asZeroPaddedHour(hour)}-${asZeroPaddedHour(hour + 1)}`;
    },
  },

  // date range
  getRemoteDateRangeAsDateRange: ([from, to]: RemoteDateRange): DateRange => ({
    from: moment(from),
    to: moment(to || from),
  }),
  getDateRangeDifference: (
    { from, to }: DateRange,
    granularity: Granularity
  ) => {
    if (!from || !to) return;

    let unit = GRANULARITY_TO_UNIT_MAPPING[granularity];
    if (unit === 'quarter') unit = 'month';
    const asUnitKey = <AsUnitKey>`as${_.capitalize(unit)}s`;

    return moment.duration(to.diff(from))[asUnitKey]();
  },
  shiftDateRange: (
    { from, to }: DateRange,
    granularity: Granularity,
    limitDateRange: { minFrom: Timestamp; maxTo: Timestamp }
  ) => {
    if (!from || !to) return;

    let unit = <moment.unitOfTime.DurationConstructor>(
      GRANULARITY_TO_UNIT_MAPPING[granularity]
    );
    if (unit === 'quarter') unit = 'month';
    const asUnitKey = <AsUnitKey>`as${_.capitalize(unit)}s`;
    const differenceAsUnit = moment.duration(to.diff(from))[asUnitKey]();
    const shift = -differenceAsUnit;

    const shiftedDateRange = {
      from: from
        .clone()
        .add(shift, unit)
        .startOf('day'),
      to: to
        .clone()
        .add(shift, unit)
        .endOf('day'),
    };

    if (!limitDateRange) return shiftedDateRange;
    const { minFrom, maxTo } = limitDateRange;
    if (
      // <from> < <minFrom>!
      (!minFrom && shiftedDateRange.from.diff(minFrom) < 0) ||
      // <to> > <maxTo>!
      (!maxTo && shiftedDateRange.to.diff(maxTo) > 0)
    )
      return;

    return shiftedDateRange;
  },

  // remote date range
  getDateRangeAsRemoteDateRange: ({ from, to }: DateRange): RemoteDateRange => {
    const remoteFrom = time.format.asRemoteDate(from);
    const remoteTo = time.format.asRemoteDate(to);

    if (remoteFrom === remoteTo) return [remoteFrom];
    else return [remoteFrom, remoteTo];
  },
  getRemoteDateRangeDays: ([remoteFrom, remoteTo]: RemoteDateRange) => {
    const days = [];

    const from = moment(remoteFrom).startOf('day');
    let to;
    days.push(time.format.asRemoteDate(from));

    if (remoteTo) {
      to = moment(to).startOf('day');

      // pushing each day between <from> and <to> as a remote date, excluding
      // <from>
      const pivot = moment(from);
      while (pivot.add(1, 'days').diff(to) < 0)
        days.push(time.format.asRemoteDate(moment(pivot)));
      days.push(time.format.asRemoteDate(to));
    }

    return days;
  },

  offsetAsString: ({ unit, value }: Offset) => {
    if (unit === GRANULARITIES.YEARS)
      return i18n.tc('c.timestamp-offset.years-ago', value, [value]);
    else if (unit === GRANULARITIES.MONTHS)
      return i18n.tc('c.timestamp-offset.months-ago', value, [value]);
    else if (unit === GRANULARITIES.DAYS)
      return i18n.tc('c.timestamp-offset.days-ago', value, [value]);
    else if (unit === GRANULARITIES.HOURS)
      return i18n.tc('c.timestamp-offset.hours-ago', value, [value]);
    else if (unit === GRANULARITIES.MINUTES)
      return i18n.tc('c.timestamp-offset.minutes-ago', value, [value]);
    else if (value > 5)
      return i18n.tc('c.timestamp-offset.seconds-ago', value, [value]);
    else return i18n.t('c.timestamp-offset.now');
  },
  offsetToStatus: ({ unit, value }: Offset) => {
    const { DAYS, MONTHS, YEARS } = GRANULARITIES;

    return (unit === DAYS && value >= 0) || [MONTHS, YEARS].includes(unit)
      ? STATUS.inactive
      : STATUS.active;
  },
  timestampToOffset: (timestamp: Timestamp): Offset => {
    const diff = moment.duration(moment().diff(timestamp));

    if (diff.years() > 0) return { unit: 'years', value: diff.years() };
    else if (diff.months() > 0) return { unit: 'months', value: diff.months() };
    else if (diff.days() > 0) return { unit: 'days', value: diff.days() };
    else if (diff.hours() > 0) return { unit: 'hours', value: diff.hours() };
    else if (diff.minutes() > 0)
      return { unit: 'minutes', value: diff.minutes() };
    else return { unit: 'seconds', value: diff.seconds() };
  },
  timestampToStatus(timestamp: Timestamp) {
    const { offsetToStatus, timestampToOffset } = time;

    return offsetToStatus(timestampToOffset(timestamp));
  },

  *dateRangeDays({ from, to }: DateRange) {
    from = moment(from).startOf('day');
    to = moment(to).endOf('day');

    let day = moment(from); // a copy of <from>

    do {
      yield moment(day);

      day.add(1, 'day');
    } while (day < to);
  },

  *granulateDateRange(
    { from, to }: DateRange,
    remoteGranularity: RemoteGranularity,
    batchSize = 1000
  ) {
    const format = (timestamp: Timestamp) =>
      time.format.as(
        timestamp,
        REMOTE_GRANULARITY_TO_FORMAT_MAPPING[remoteGranularity]
      );
    const unit = REMOTE_GRANULARITY_TO_UNIT_MAPPING[remoteGranularity];

    from = moment(from).startOf(unit);
    to = moment(to).endOf(unit);

    let from_ = moment(from); // a copy of <from>
    let to_, done;

    while (!done) {
      to_ = moment(from_);
      to_.add(batchSize, unit);

      done = to_ >= to;
      if (done) to_ = moment(to);
      const timeRange = [format(from_), format(to_)];

      if (done) return yield timeRange;
      else yield timeRange;

      // <from_> is moved after <to_>. If <unit === 'DAILY'> and
      // <to_ == '2020-05-28'> then <from_ = '2020-05-29'>; the next day!
      from_ = moment(to_).add(1, unit);
    }
  },

  isToday(timestamp: Timestamp) {
    return timestamp.isSame(moment(), 'day');
  },

  isValidDate(date: Timestamp) {
    return moment.isMoment(date);
  },
  isValidDateRange({ from, to }: DateRange) {
    if (!time.isValidDate(from) || !time.isValidDate(to)) return false;

    return to.diff(from) >= 0;
  },

  plugin,
};

export default time;
