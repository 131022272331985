<template>
  <div class="d-flex-c">
    <t sm medium class="mb-1">{{ label }}</t>
    <t v-if="description" color="gray-2" sm>{{ description }}</t>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'DownloadDrawerInputWrapper',
  props: {
    label: { type: String, required: true },
    description: { type: String, default: undefined },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
