import { mapsAPI } from '@/main';
import { htmlStringToElement, polarToCartesian } from '@/helpers';
import styles from './styles.json';

const STATUS_TO_COLOR = Object.freeze({
  active: { text: 'green', hex: '#00EB4B' },
  inactive: { text: 'gray', hex: '#9B9B9B' },
});
const IMAGE_OVERLAY_Z_INDEX = 0;
const VISION_OVERLAY_Z_INDEX = 1;

export const DEFAULT_CENTER = Object.freeze({ lat: 0, lng: 0 });
export const DEFAULT_MAP_TYPE = 'satellite';
export const DEFAULT_ZOOM = 6;
export const MAX_ZOOM = 20;
export const MIN_ZOOM = 3;

const buildVisionOverlaySVG = (id, orientation, color) => {
  // https://codepen.io/massimo-cassandro/pen/rWpEEV

  const SIZE = 100; // meters
  const RADIUS = SIZE / 2; // meters
  const WIDTH = 90; // degrees

  orientation = -orientation;

  const from = Math.floor(orientation - WIDTH / 2);
  const to = Math.ceil(orientation + WIDTH / 2);

  const { x: fx, y: fy } = polarToCartesian(RADIUS, RADIUS, RADIUS, to);
  const { x: tx, y: ty } = polarToCartesian(RADIUS, RADIUS, RADIUS, from);

  const d = [
    'M',
    fx,
    fy,
    'A',
    RADIUS,
    RADIUS,
    0,
    to - from <= 180 ? '0' : '1', // is a large sector?
    0,
    tx,
    ty,
    'L',
    RADIUS,
    RADIUS,
    'Z',
  ].join(' ');

  return htmlStringToElement(`
    <svg viewBox="0 0 ${SIZE} ${SIZE}">
      <defs>
        <radialGradient id="${id}-gradient" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stop-color="${color}" stop-opacity="1" />
          <stop offset="100%" stop-color="${color}" stop-opacity="0" />
        </radialGradient>

        <clipPath id="${id}-mask">
          <path d="${d}" />
        </clipPath>
      </defs>

      <circle
        cx="50%"
        cy="50%"
        r="50%"
        fill="url(#${id}-gradient)"
        clip-path="url(#${id}-mask)"
      />
    </svg>
  `);
};

export const buildInstance = async parentEl => {
  const maps = await mapsAPI();

  const map = new maps.Map(parentEl, {
    center: DEFAULT_CENTER,
    mapTypeId: DEFAULT_MAP_TYPE,
    zoom: DEFAULT_ZOOM,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    tilt: 0,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    styles,
  });

  return map;
};

const buildMapsAPIMarkerIcon = (marker, maps) => {
  const SIZE = 40;

  return {
    url: require(`./icons/${marker.type}/${
      STATUS_TO_COLOR[marker.status].text
    }.svg`),
    scaledSize: new maps.Size(SIZE, SIZE),
    anchor: new maps.Point(SIZE / 2, SIZE / 2),
  };
};

export const buildMapsAPIMarker = (marker, maps) => {
  const { listeners = [] } = marker;

  const mapsAPIMarker = new maps.Marker({
    position: marker.position,
    zIndex: Number(marker.status === 'active'),
    icon: buildMapsAPIMarkerIcon(marker, maps),
  });

  listeners.forEach(({ event, handler }) =>
    mapsAPIMarker.addListener(event, handler)
  );

  return mapsAPIMarker;
};

const buildOverlay = (boundsBuilder, elBuilder, maps) => {
  // https://codepen.io/saimiri/pen/EVjMZp

  const bounds = boundsBuilder();
  const el = elBuilder();

  const overlay = new maps.OverlayView();

  overlay.onAdd = function() {
    const panes = this.getPanes();

    panes.overlayLayer.appendChild(el);
  };

  overlay.onRemove = function() {
    el.parentNode.removeChild(el);
  };

  overlay.draw = function() {
    const projection = this.getProjection();
    const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
    const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

    el.style.top = `${ne.y}px`;
    el.style.left = `${sw.x}px`;
    el.style.width = `${ne.x - sw.x}px`;
    el.style.height = `${sw.y - ne.y}px`;
  };

  return overlay;
};

export const buildImageOverlay = (overlay, maps) => {
  const { uri, pivots, angle } = overlay;

  const buildBounds = (positions, maps) => {
    const bounds = new maps.LatLngBounds();

    positions.forEach(position => bounds.extend(new maps.LatLng(position)));

    return bounds;
  };

  const buildEl = (uri, angle) => {
    const el = document.createElement('div');
    el.style.position = 'absolute';
    el.style.borderRadius = '9px';
    el.style.boxShadow =
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)';
    el.style.overflow = 'hidden';

    el.style.zIndex = IMAGE_OVERLAY_Z_INDEX;
    el.style.transform = `rotate(${angle}deg)`;

    const img = document.createElement('img');
    img.src = uri;

    img.style.position = 'absolute';
    img.style.top = '0';
    img.style.left = '0';
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.objectFit = 'cover';

    el.appendChild(img);

    return el;
  };

  return buildOverlay(
    () => buildBounds(pivots, maps),
    () => buildEl(uri, angle),
    maps
  );
};

export const buildVisionOverlay = (marker, maps) => {
  const { id, status, position, orientation } = marker;

  const RADIUS = 100; // meters

  const buildBounds = (position, maps) => {
    position = new maps.LatLng(position.lat, position.lng);

    const sw = maps.geometry.spherical.computeOffset(position, -RADIUS, 45);
    const ne = maps.geometry.spherical.computeOffset(position, RADIUS, 45);

    const bounds = new maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    return bounds;
  };

  const buildEl = (id, orientation, color) => {
    const el = document.createElement('div');
    el.id = id;
    el.classList.add('vision-overlay');
    el.style.position = 'absolute';
    el.style.zIndex = VISION_OVERLAY_Z_INDEX;

    const svg = buildVisionOverlaySVG(id, orientation, color);
    svg.style.position = 'absolute';
    svg.style.bottom = '0';
    svg.style.left = '0';

    el.appendChild(svg);

    return el;
  };

  return buildOverlay(
    () => buildBounds(position, maps),
    () => buildEl(id, orientation, STATUS_TO_COLOR[status].hex),
    maps
  );
};
