import { mapGetters } from 'vuex';

const project = function() {
  const { projectId } = this.$route.params;

  return this.getProjectById(projectId);
};

export default (withServices = false) => {
  return withServices
    ? {
        computed: {
          ...mapGetters('collections', [
            'getProjectById',
            'getServicesByIds',

            'isProjectFetchedById',
            'areServicesFetchedByIds',
          ]),

          project,
          services() {
            return this.getServicesByIds(this.project.servicesIds);
          },

          projectReady() {
            return (
              this.isProjectFetchedById(this.project.id) &&
              this.areServicesFetchedByIds(this.project.servicesIds)
            );
          },
        },
      }
    : {
        computed: {
          ...mapGetters('collections', [
            'getProjectById',

            'isProjectFetchedById',
          ]),

          project,

          projectReady() {
            return Boolean(this.project);
          },
        },
      };
};
