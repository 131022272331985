<template>
  <DownloadDrawerInputWrapper
    :label="$t(`${$options.path}.label`)"
    :description="$t(`${$options.path}.description`)"
  >
    <FormulateInput
      type="odd-radio"
      name="quality"
      v-bind="$attrs"
      class="odd-radio--big mt-4"
      v-on="$listeners"
    />
  </DownloadDrawerInputWrapper>
</template>

<script>
// components
import DownloadDrawerInputWrapper from '@/components/ui/DownloadDrawer/InputWrapper.vue';

export default {
  name: 'DownloadDrawerQualityInput',
  components: { DownloadDrawerInputWrapper },

  path: 'c.download-drawer.quality',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
