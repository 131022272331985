<template>
  <div v-if="status !== 'idle'" class="download-drawer__form-overlay">
    <div class="d-flex-c d-flex-a-c v-spacing-4 p-5">
      <transition name="fade-normal" mode="out-in">
        <Progress
          v-if="status === 'pending'"
          key="pending"
          variant="circular"
          color="primary"
        />

        <icon v-else-if="status === 'success'" key="success" color="green">
          check
        </icon>

        <icon v-else-if="status === 'failure'" key="failure" color="red">
          cancel
        </icon>
      </transition>

      <t color="gray-2" sm center><slot /></t>

      <div class="d-flex-r d-flex-a-c h-spacing-2">
        <template v-if="status === 'pending'">
          <EButton md @click="$emit('cancel')">
            {{ $t(`${$options.path}.action.cancel`) }}
          </EButton>
        </template>

        <template v-if="status === 'success'">
          <EButton md @click="$emit('clear')">
            {{ $t(`${$options.path}.action.close`) }}
          </EButton>
        </template>

        <template v-if="status === 'failure'">
          <EButton md @click="$emit('retry')">
            {{ $t(`${$options.path}.action.retry`) }}
          </EButton>

          <EButton md @click="$emit('clear')">
            {{ $t(`${$options.path}.action.cancel`) }}
          </EButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadDrawerFormOverlay',
  props: {
    status: { type: String, required: true },
  },

  path: 'c.download-drawer',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
.download-drawer__form-overlay
  +d-flex-r(center, center)
  +overlay($c-real-white, .95)
</style>
