<template>
  <div class="l-default">
    <slot />
  </div>
</template>

<script>
import mixin from '@/layouts/mixin';

export default {
  name: 'LDefault',
  mixins: [mixin],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.l-default
  height: 100%
</style>
