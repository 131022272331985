type Extends<T, U extends T> = U;

type LodashAggregation = Extends<Aggregation, 'max' | 'mean' | 'min' | 'sum'>;
type LodashSearchLikeAggregation = Extends<LodashAggregation, 'max' | 'min'>;

declare global {
  type Aggregation = 'max' | 'mean' | 'min' | 'sum' | 'HH' | 'WORK_DAY';

  type RemoteAggregation = 'AVG' | 'MAX' | 'MIN' | 'SUM' | 'HH' | 'WORKDAY';
}

// START - aggregation ---------------------------------------------------------
const AGGREGATIONS: Set<Aggregation> = new Set(['max', 'mean', 'min', 'sum']);

export const AGGREGATION_TO_REMOTE_AGGREGATION_MAPPING: Record<
  Aggregation,
  RemoteAggregation
> = Object.freeze({
  max: 'MAX',
  mean: 'AVG',
  min: 'MIN',
  sum: 'SUM',

  HH: 'HH',
  WORK_DAY: 'WORKDAY',
});

export const isAggregation = (value: any): value is Aggregation =>
  AGGREGATIONS.has(value);

export const asAggregation = (remoteAggregation: RemoteAggregation) =>
  REMOTE_AGGREGATION_TO_AGGREGATION_MAPPING[remoteAggregation];
// END - aggregation -----------------------------------------------------------

// START - remote aggregation --------------------------------------------------
const REMOTE_AGGREGATIONS: Set<RemoteAggregation> = new Set([
  'AVG',
  'MAX',
  'MIN',
  'SUM',

  'HH',
  'WORKDAY',
]);

export const REMOTE_AGGREGATION_TO_AGGREGATION_MAPPING: Record<
  RemoteAggregation,
  Aggregation
> = Object.freeze({
  AVG: 'mean',
  MAX: 'max',
  MIN: 'min',
  SUM: 'sum',

  HH: 'HH',
  WORKDAY: 'WORK_DAY',
});

export const isRemoteAggregation = (value: any): value is RemoteAggregation =>
  REMOTE_AGGREGATIONS.has(value);

export const asRemoteAggregation = (aggregation: Aggregation) =>
  AGGREGATION_TO_REMOTE_AGGREGATION_MAPPING[aggregation];
// END - remote aggregation ----------------------------------------------------

const isLodashAggregation = (
  aggregation: Aggregation
): aggregation is LodashAggregation =>
  aggregation !== 'HH' && aggregation !== 'WORK_DAY';

const isLodashSearchLikeAggregation = (
  aggregation: Aggregation
): aggregation is LodashSearchLikeAggregation =>
  aggregation === 'max' || aggregation === 'min';

const isScatterDataPoint = (object: any) =>
  _.has(object, 'x') || _.has(object, 'y');

export const aggregate = (
  array: any[],
  remoteAggregation: RemoteAggregation
) => {
  if (array.length === 0) return 0;

  const [head] = array; // the first one as representative of all!
  const aggregation = asAggregation(remoteAggregation);

  if (!isLodashAggregation(aggregation)) return;

  if (isScatterDataPoint(head)) {
    // @ts-ignore
    const result = _[`${aggregation}By`](array, 'y');

    return isLodashSearchLikeAggregation(aggregation) ? result.y : result;
  } else {
    // @ts-ignore
    return _[aggregation](array);
  }
};
