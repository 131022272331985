import { fromEventPattern } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { db } from '@/config/firebase';
import preprocessor from './preprocessor/index.ts';

// Hidden Figures (https://en.wikipedia.org/wiki/Hidden_Figures)
import katherine from './katherine/index.ts';

const collection = async (collection, id) => {
  const snapshot = await db
    .collection(collection)
    .doc(id)
    .get();

  return snapshot.data();
};

const collection$ = (collection, id) =>
  fromEventPattern(next =>
    db
      .collection(collection)
      .doc(id)
      .onSnapshot(next, error => {
        // FIX: wait until all connections are closed
        if (error.code === 'permission-denied') return;

        throw error;
      })
  ).pipe(map(snapshot => snapshot.data()));

const collections$ = (collection, queryBuilder) =>
  fromEventPattern(next =>
    queryBuilder(db.collection(collection)).onSnapshot(next, error => {
      // FIX: wait until all connections are closed
      if (error.code === 'permission-denied') return;

      throw error;
    })
  ).pipe(
    map(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
  );

export const API = {
  async user(id) {
    return preprocessor.user(await collection('Users', id));
  },

  async client(id) {
    return preprocessor.client(await collection('Clients', id));
  },

  async project(id) {
    const project = preprocessor.project(await collection('Projects', id));

    let manifest = {};
    if (project.manifestId) manifest = await API.manifest(project.manifestId);

    return Object.assign({}, project, { manifest });
  },

  async service(id) {
    return preprocessor.service(await collection('Services', id));
  },

  async manifest(id) {
    return preprocessor.manifest(await collection('Manifests', id));
  },

  async notification(id) {
    return preprocessor.notification(await collection('Notifications', id));
  },

  async processes(serviceId, date, process) {
    const doc = await db
      .collection('Services')
      .doc(serviceId)
      .collection('Daily')
      .doc(date)
      .collection('Processes')
      .doc(process)
      .get();

    return doc.data();
  },

  user$(id) {
    return collection$('Users', id).pipe(map(preprocessor.user));
  },

  client$(id) {
    return collection$('Clients', id).pipe(map(preprocessor.client));
  },

  project$(id) {
    return collection$('Projects', id).pipe(
      map(preprocessor.project),
      mergeMap(async project =>
        project.manifestId
          ? Object.assign({}, project, {
              manifest: await API.manifest(project.manifestId),
            })
          : Object.assign({}, project, {
              manifest: {},
            })
      )
    );
  },

  service$(id) {
    return collection$('Services', id).pipe(map(preprocessor.service));
  },

  notification$(id) {
    return collection$('Notifications', id).pipe(
      map(preprocessor.notification)
    );
  },

  notifications$(userId) {
    const LIMIT = 10;

    return collections$('Notifications', query =>
      query
        .where('uid', '==', userId)
        .where('dismissed', '==', false)
        .orderBy('timestamp', 'desc')
        .limit(LIMIT)
    ).pipe(map(notifications => notifications.map(preprocessor.notification)));
  },

  katherine,

  update: {
    notification(id, payload) {
      return db
        .collection('Notifications')
        .doc(id)
        .update(payload);
    },
  },
};

export default API;
