import CellPresetCore from './CellPresetCore';

import { parseClassName, parseROI } from './../helpers';

const Parent = CellPresetCore('CR');

export class CRCellPreset extends Parent {
  static VALID_PROPERTIES = {
    aggregation: remoteAggregation => {
      const { DEFAULT_REMOTE_AGGREGATION: DEFAULT } = CRCellPreset;

      return (remoteAggregation && remoteAggregation.toUpperCase()) || DEFAULT;
    },
    field: remoteField => {
      const { DEFAULT_REMOTE_FIELD: DEFAULT } = CRCellPreset;

      return remoteField || DEFAULT;
    },
    legendBy: remoteLegendBy => {
      const { DEFAULT_REMOTE_LEGEND_BY: DEFAULT } = CRCellPreset;

      return remoteLegendBy || DEFAULT;
    },
    suffix: remoteSuffix => {
      const { DEFAULT_REMOTE_SUFFIX: DEFAULT } = CRCellPreset;

      return remoteSuffix || DEFAULT;
    },
  };

  static DEFAULT_REMOTE_AGGREGATION = null;
  static DEFAULT_REMOTE_FIELD = null;
  static DEFAULT_REMOTE_LEGEND_BY = 'rois';
  static DEFAULT_REMOTE_SUFFIX = null;

  aggregation;
  field;
  legendBy;
  suffix;

  constructor(id) {
    super(id);

    // binding
    _.bindAll(this, [
      'buildDatasetFromClassName',
      'buildDatasetFromROI',
      'buildDatasetOptions',
      'buildDatasets',
      'buildDatasets_',
      'buildMetrics',
    ]);
  }

  buildMetrics(className, roi) {
    const id = /\{|\}/.test(this.id)
      ? String(this.id)
          .replace('{className}', className)
          .replace('{roi}', roi)
      : `${this.id}-${roi}_${className}${this.suffix ? `-${this.suffix}` : ''}`;

    const field = String(this.field)
      .replace('{className}', className)
      .replace('{roi}', roi);

    return [
      {
        id,
        aggregation: this.aggregation,
        field,
      },
    ];
  }

  buildDatasetOptions(options) {
    const { color } = options;

    return { color, background: { color } };
  }

  buildDatasetFromClassName(className, roi, label, index) {
    const parsedClassName = parseClassName(className, label);

    className = parsedClassName.className;
    label = parsedClassName.label;

    const options = {
      color: parsedClassName.color || Parent.PALETTE[index] || undefined,
    };

    return {
      metrics: this.buildMetrics(className, roi),

      label,
      options: this.buildDatasetOptions(options),
    };
  }

  buildDatasetFromROI(roi, className, label, index) {
    const parsedROI = parseROI(roi, label);

    roi = parsedROI.roi;
    label = parsedROI.label;

    const options = {
      color: parsedROI.color || Parent.PALETTE[index] || undefined,
    };

    return {
      metrics: this.buildMetrics(className, roi),

      label,
      options: this.buildDatasetOptions(options),
    };
  }

  buildDatasets_(array, pivot, callback) {
    if (array.length > 1)
      return _.map(array, (item, index) =>
        callback(item, pivot, undefined, index)
      );

    if (array.length === 1)
      return [callback(array[0], pivot, this.singleLegendLabel, 0)];

    return [];
  }

  buildDatasets(classNames, rois, legendBy) {
    const { buildDatasets_ } = this;

    return legendBy === 'rois'
      ? buildDatasets_(rois, classNames[0], this.buildDatasetFromROI)
      : buildDatasets_(classNames, rois[0], this.buildDatasetFromClassName);
  }
}

export default CRCellPreset;
