import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import store from '@/store';

const CONFIGURATION = Object.freeze({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URI,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_SENDER_ID,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_DOMAIN,
});

export const app = firebase.initializeApp(CONFIGURATION);
export const gProvider = new firebase.auth.GoogleAuthProvider();
export const db = app.firestore();
export const storage = app.storage();
export const functions = app.functions();
export const performance = firebase.performance();

db.enablePersistence({ synchronizeTabs: true }).catch(() => {});

firebase.auth().onAuthStateChanged(user => {
  store.commit('authentication/set-user', { user });
  if (user) store.commit('authentication/sign-in-success');
  else store.commit('authentication/sign-in-failure');

  store.commit('ready');
});
