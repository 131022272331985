import CellPreset from './../CellPreset';

export class Raw extends CellPreset {
  constructor(id) {
    super(id);

    this.metricId = `${this.id.toLowerCase()}`;

    // binding
    _.bindAll(this, ['buildDatasetFromDataset', 'buildMetricFromMetric']);
  }

  buildMetricFromMetric(metric) {
    const id = metric.id || this.metricId;

    return { ...metric, id };
  }

  buildDatasetFromDataset(dataset) {
    return {
      ...dataset,

      metrics: dataset.metrics.map(this.buildMetricFromMetric),
    };
  }

  build({ title, datasets }) {
    const type = 'BarChart';

    return {
      title,

      data_address_parameters: {
        datasets: datasets.map(dataset =>
          this.buildDatasetFromDataset(dataset)
        ),
      },

      type,
    };
  }
}

export default Raw;
