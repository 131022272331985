import Preset from './../Preset';

export class SocialDistancing extends Preset {
  constructor(id) {
    super(id);
  }

  build() {
    return {
      areas: {
        'by-date': {
          title: {
            es: '¿Cuántas interacciones inseguras han ocurrido?',
            en: 'How many unsafe interactions have occurred?',
          },

          areas: [
            {
              data_address_parameters: {
                datasets: [
                  {
                    metrics: [
                      {
                        id: 'odd-kitkat-base-secure-metric',
                        aggregation: 'SUM',
                        field: 'object_count',
                      },
                    ],

                    label: {
                      en: 'Safe',
                      es: 'Seguro',
                    },
                    options: {
                      color: 'green',
                    },
                  },
                  {
                    key: 'unsafe',

                    metrics: [
                      {
                        id: 'odd-kitkat-base-insecure-metric',
                        aggregation: 'SUM',
                        field: 'object_count',
                      },
                    ],

                    label: {
                      en: 'Unsafe',
                      es: 'Inseguro',
                    },
                    options: {
                      color: 'red',
                    },
                  },
                ],
              },

              type: 'BarChart',
              type_parameters: {
                stacked: true,
              },

              kpis: {
                datasets_keys: 'unsafe',
                aggregation: 'sum',

                label: {
                  es: 'Interacciones inseguras',
                  en: 'Unsafe interactions',
                },
                icon: 'person',
              },
            },
          ],
        },
        'by-hour': {
          title: {
            es: '¿A qué hora se producen las interacciones inseguras?',
            en: 'What time do unsafe interactions occur?',
          },

          data_address_parameters: {
            datasets: [
              {
                metrics: [
                  {
                    id: 'odd-kitkat-base-secure-metric',
                    aggregation: 'SUM',
                    field: 'object_count',
                  },
                ],

                label: {
                  en: 'Safe',
                  es: 'Seguro',
                },
                options: {
                  color: 'green',
                },
              },
              {
                key: 'unsafe',

                metrics: [
                  {
                    id: 'odd-kitkat-base-insecure-metric',
                    aggregation: 'SUM',
                    field: 'object_count',
                  },
                ],

                label: {
                  en: 'Unsafe',
                  es: 'Inseguro',
                },
                options: {
                  color: 'red',
                },
              },
            ],

            groupBy: true,
          },

          type: 'BarChart',
          type_parameters: {
            stacked: true,
            unit: 'hour',
          },
        },
      },
      areas_templates: {
        xs: "'by-date' 'by-hour'",
      },
    };
  }
}

export default SocialDistancing;
