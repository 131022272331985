<template>
  <div class="menu-unread-notifications">
    <div :class="highlighterClassName">
      <slot name="highlighted" />
    </div>

    <slot :unreadNotifications="unreadNotifications" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MenuUnreadNotifications',
  computed: {
    ...mapGetters('collections', { notifications: 'getAllNotifications' }),

    unreadNotifications() {
      return _.filter(this.notifications, { read: false });
    },

    highlighterClassName() {
      const className = 'menu-unread-notifications__highlighter';

      return [
        className,
        {
          [`${className}--on`]:
            this.unreadNotifications.length > 0 && this.$slots.highlighted,
        },
      ];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$offset: -1px
$size: 10px

.menu-unread-notifications__highlighter--on
  position: relative

  &::after
    content: ''

    line-height: $size

    border-radius: $b-radius-circle

    +p-absolute-top-right(#{$z-index-base + 1}, $offset)
    +size($size)

// color
.menu-unread-notifications__highlighter--on
  &::after
    background-color: var(--c-red)
</style>
