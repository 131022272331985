import Raw from './Raw';

export class SocialDistancingRaw extends Raw {
  constructor(id) {
    super(id);
  }

  build({ title }) {
    const type = 'BarChart';
    const datasets = [
      {
        metrics: [
          {
            id: 'odd-kitkat-base-secure-metric',
            aggregation: 'SUM',
            field: 'object_count',
          },
        ],

        label: {
          en: 'Safe',
          es: 'Seguro',
        },
        options: {
          color: 'green',
        },
      },
      {
        metrics: [
          {
            id: 'odd-kitkat-base-insecure-metric',
            aggregation: 'SUM',
            field: 'object_count',
          },
        ],

        label: {
          en: 'Unsafe',
          es: 'Inseguro',
        },
        options: {
          color: 'red',
        },
      },
    ];

    return {
      title,

      data_address_parameters: {
        datasets: datasets.map(dataset =>
          this.buildDatasetFromDataset(dataset)
        ),
      },

      type,
      type_parameters: {
        stacked: true,
      },
    };
  }
}

export default SocialDistancingRaw;
