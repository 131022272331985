import PCancelable from 'p-cancelable';
import API from '@/API';

type Handler = (notification: INotification) => any;

export const NOTIFICATION_STATUS = Object.freeze({
  pending: 'pending',

  failure: 'failure',
  success: 'success',
});

class NotificationListener {
  status?: INotification['status'];

  handlers: {
    onStatusChange?: Handler;
  } = {};

  constructor(notificationId: Id) {
    const subscription = API.notification$(notificationId).subscribe(
      (notification: INotification) => {
        const { status } = notification;

        if (this.status !== status) {
          this.status = status;

          const { onStatusChange } = this.handlers;
          if (onStatusChange) onStatusChange(notification);

          if (NotificationListener.isLastStatus(status))
            subscription.unsubscribe();
        }
      }
    );
  }

  onStatusChange(handler: Handler) {
    this.handlers.onStatusChange = handler;
  }

  static isLastStatus(status: INotification['status']) {
    return (
      status === NOTIFICATION_STATUS.success ||
      status === NOTIFICATION_STATUS.failure
    );
  }
}

export class NotificationPromise extends PCancelable<{ uri: string }> {
  constructor(notificationId: string) {
    super((resolve: any, reject: any) =>
      new NotificationListener(notificationId).onStatusChange(response => {
        if (response.status === NOTIFICATION_STATUS.success) resolve(response);
        else if (response.status === NOTIFICATION_STATUS.failure)
          reject(response);
      })
    );
  }
}

export default NotificationPromise;
