import CellPreset from './../CellPreset';

import { VARIANTS } from './constants';

export class Counter extends CellPreset {
  static VALID_PROPERTIES = [
    'kpiLabel',
    'legendLabel',
    'metricsIds',
    'singleLegendLabel',
    'variant',
  ];

  static DEFAULT_VARIANT = 'person';

  kpiLabel;
  metricsIds;
  singleLegendLabel;
  legendLabel;

  constructor(id) {
    super(id);

    this.setup({ variant: Counter.DEFAULT_VARIANT });

    // binding
    _.bindAll(this, ['buildDatasetsFromMetricsIds', 'setup']);
  }

  setup(payload) {
    const { kpiLabel, metricsIds, singleLegendLabel, variant } = payload;

    if (variant)
      Object.assign(this, _.pick(VARIANTS[variant], Counter.VALID_PROPERTIES));

    if (kpiLabel) this.kpiLabel = kpiLabel;
    if (metricsIds) this.metricsIds = metricsIds;
    if (singleLegendLabel) this.singleLegendLabel = singleLegendLabel;
  }

  buildDatasetsFromMetricsIds(metricsIds) {
    let inMetricId, outMetricId;

    if (_.isPlainObject(metricsIds)) {
      inMetricId = metricsIds.in;
      outMetricId = metricsIds.out;
    } else if (_.isArray(metricsIds)) {
      inMetricId = metricsIds[0];
      outMetricId = metricsIds[1];
    } else if (_.isString(metricsIds)) inMetricId = outMetricId = metricsIds;

    return {
      in: {
        metrics: [
          {
            id: inMetricId,
            aggregation: 'SUM',
            field: 'conteo-in',
          },
        ],

        label: this.legendLabel.in,
        options: { color: 'green' },
      },
      out: {
        metrics: [
          {
            id: outMetricId,
            aggregation: 'SUM',
            field: 'conteo-out',
          },
        ],

        label: this.legendLabel.out,
        options: { color: 'red' },
      },
    };
  }

  buildKPIs() {
    return { aggregation: 'sum', label: this.kpiLabel };
  }

  build(remotePayload) {
    const payload = Counter.parseRemotePayload(remotePayload);

    this.setup(payload);

    const type = 'BarChart';
    const kpis = this.buildKPIs();

    return {
      data_address_parameters: {
        datasets: this.buildDatasetsFromMetricsIds(this.metricsIds),
      },

      type,
      type_parameters: { stacked: true },

      kpis,
    };
  }

  static parseRemotePayload(remotePayload) {
    const variant = VARIANTS[remotePayload.variant || Counter.DEFAULT_VARIANT];
    const variantPayload = variant ? variant.payload : undefined;

    remotePayload = _.merge(
      variantPayload,
      _.mapKeys(remotePayload, (value, key) => _.camelCase(key))
    );

    const ours = _.pick(remotePayload, Counter.VALID_PROPERTIES);

    return _.merge({}, ours);
  }
}

export default Counter;
