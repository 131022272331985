import CRPreset from './../CRPreset';

export class CRContainerPreset extends CRPreset {
  static VALID_PROPERTIES = ['title'];

  title;

  constructor(id) {
    super(id);

    // binding
    _.bindAll(this, ['buildAreas']);
  }
}

export default CRContainerPreset;
