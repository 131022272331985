<template>
  <div class="double-range-tooltip"><slot /></div>
</template>

<script>
import Tooltip from '@/components/ui/Tooltip/Tooltip';

export default {
  name: 'DoubleRangeTooltip',
  mixins: [Tooltip],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
