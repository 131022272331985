import FormOverlay from './FormOverlay.vue';

export default {
  components: { FormOverlay },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    form: { handler: 'syncUp', deep: true },
    stateForm: { handler: 'syncDown', deep: true },
  },
  created() {
    this.syncDown();
  },
};
