// BEGIN - className -----------------------------------------------------------
export const isNotEnrichedClassName = className =>
  _.isString(className) && className.length > 0;
export const isEnrichedClassName = className =>
  _.isPlainObject(className) && isNotEnrichedClassName(className.value);

export const isClassName = className =>
  isNotEnrichedClassName(className) || isEnrichedClassName(className);

export const parseClassName = (className, fLabel) =>
  isEnrichedClassName(className)
    ? {
        className: className.value,
        label: !_.isUndefined(fLabel) ? fLabel : className.label,

        ..._.pick(className, ['color']),
      }
    : {
        className,
        label: !_.isUndefined(fLabel) ? fLabel : className,
      };
// END - className -------------------------------------------------------------

// BEGIN - roi -----------------------------------------------------------------
export const isNotEnrichedROI = roi => _.isString(roi) && roi.length > 0;
export const isEnrichedROI = roi =>
  _.isPlainObject(roi) && isNotEnrichedROI(roi.value);

export const isROI = roi => isNotEnrichedROI(roi) || isEnrichedROI(roi);

export const parseROI = (roi, fLabel) =>
  isEnrichedROI(roi)
    ? {
        roi: roi.value,
        label: !_.isUndefined(fLabel) ? fLabel : roi.label,

        ..._.pick(roi, ['color']),
      }
    : {
        roi,
        label: !_.isUndefined(fLabel) ? fLabel : roi,
      };
// END - roi -------------------------------------------------------------------

// START - is legend by --------------------------------------------------------
export const isLegendBy = legendBy =>
  legendBy === 'classNames' || legendBy === 'rois';
// END - is legend by ----------------------------------------------------------
