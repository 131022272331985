import createPopper from './createPopper';

const DEFAULT_OPTIONS = Object.freeze({
  offset: () => [0, 10],
  padding: 5,
  placement: 'bottom',
});

export default {
  name: 'Tooltip',
  props: {
    target: { type: [HTMLElement, Object], default: undefined },
    boundary: { type: HTMLElement, default: undefined },

    forceDisplay: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    offset: { type: Array, default: DEFAULT_OPTIONS.offset },
    padding: { type: Number, default: DEFAULT_OPTIONS.padding },
    placement: { type: String, default: DEFAULT_OPTIONS.placement },
  },
  data() {
    return { popper: null };
  },
  computed: {
    options() {
      return {
        offset: this.offset,
        padding: this.padding,
        placement: this.placement,
      };
    },

    display() {
      return this.forceDisplay || !this.disabled;
    },
  },
  mounted() {
    this.$nextTick(this.setup);
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    callPopper(method, payload) {
      if (this.popper) this.popper[method](payload);
    },

    setup() {
      this.teardown();

      this.popper = createPopper(
        this.target,
        this.$el,
        this.boundary,
        this.options
      );
    },
    teardown() {
      this.callPopper('destroy');
    },

    update() {
      this.callPopper('update');
    },
  },
};
