<template functional>
  <div class="left-drawer" :class="{ 'left-drawer--open': props.isOpen }">
    <transition name="fade-normal">
      <div
        v-if="!props.hideOverlay && props.isOpen"
        class="left-drawer__overlay"
        @click="listeners.close"
      />
    </transition>

    <div class="left-drawer__body">
      <div class="d-flex-r d-flex-a-c pv-3 ph-4">
        <slot name="header" />

        <div class="spacer" />

        <EButton navigation md @click="listeners.close">menu-close</EButton>
      </div>

      <div><slot /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftDrawer',
  props: {
    isOpen: { type: Boolean, required: true },

    hideOverlay: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$body-selector: '.left-drawer__body > div:nth-child(2)'
$header-selector: '.left-drawer__body > div:nth-child(1)'

$max-width: 384px
$width: 85%

$overlay-opacity: .66

.left-drawer__overlay
  opacity: $overlay-opacity

  +p-absolute-top-left($z-index-left-drawer, 0)
  +size(100%)

.left-drawer__body
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr

  max-width: $max-width
  width: $width
  height: 100%

  box-shadow: -3px 3px 6px #00000033 // FIX

  transform: translateX(100%)

  transition: transform $duration-normal $ease-in-out-quint

  +p-absolute($z-index-left-drawer)
  +shell-right(0)

#{$header-selector}
  border-bottom-style: solid
  border-bottom-width: 1px

#{$header-selector}
  border-bottom-color: var(--c-gray-1)

#{$body-selector}
  overflow-y: auto

+media-down(md)
  .left-drawer__body
    max-width: 100%
    width: 100%

// - open
.left-drawer--open > .left-drawer__body
  transform: translateX(0%)

// color
.left-drawer__overlay
  background-color: var(--c-black)

.left-drawer__body
  background-color: var(--c-background)
</style>
