import CRContainerPreset from './CRContainerPreset';

import { SUMMARY_AREA_KEY, SUMMARY_TITLE } from './constants';

export class IndustrialSE extends CRContainerPreset {
  buildHoursArea(className, rois) {
    return {
      key: 'hours',

      preset: 'HH',
      preset_parameters: {
        aggregation: 'HH',
        field: className,

        class_name: className,
        rois,
      },
    };
  }

  buildWorkDayArea(className, rois) {
    return {
      key: 'work-day',

      preset: 'WorkDaySE',
      preset_parameters: {
        aggregation: 'WORKDAY',
        field: className,

        class_name: className,
        rois,
      },
    };
  }

  buildAreas(className, rois) {
    const areas = _(rois)
      .keyBy('value')
      .mapValues(roi => ({
        title: roi.label,
        areas: [
          this.buildHoursArea(className, roi),
          this.buildWorkDayArea(className, roi),
        ],
      }))
      .value();
    const areasTemplates = Object.keys(areas)
      .map(areaKey => `'${areaKey}'`)
      .join(' ');

    return { areas, areasTemplates };
  }

  build(remotePayload) {
    const payload = this.parseRemotePayload(remotePayload);

    this.setup(payload);

    const className = this.classNames[0];
    const { areas, areasTemplates } = this.buildAreas(className, this.rois);

    return {
      title: this.title,

      areas: {
        [SUMMARY_AREA_KEY]: {
          title: SUMMARY_TITLE,

          areas: [
            this.buildHoursArea(className, this.rois),
            this.buildWorkDayArea(className, this.rois),
          ],
        },

        ...areas,
      },
      areas_templates: `'${SUMMARY_AREA_KEY}' ${areasTemplates}`,
    };
  }
}

export default IndustrialSE;
