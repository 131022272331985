const isCSSColor = color =>
  Boolean(color) && Boolean(color.match(/^(#|var\(--|(rgb|hsl)a?\()/));

const parseColor = color => (isCSSColor(color) ? color : `var(--c-${color})`);

export default {
  props: {
    color: { type: String, default: undefined },
    textColor: { type: String, default: undefined },
    activeColor: { type: String, default: undefined },
    activeTextColor: { type: String, default: undefined },
  },
  computed: {
    withColorStyle() {
      return this.$options.buildStyle(this.$props);
    },
  },

  buildStyle(props) {
    return Object.assign(
      {},
      props.color && { '--color': parseColor(props.color) },
      props.activeColor && {
        '--active-color': parseColor(props.activeColor),
      },
      props.textColor && { '--text-color': parseColor(props.textColor) },
      props.activeTextColor && {
        '--active-text-color': parseColor(props.activeTextColor),
      }
    );
  },
};
