<template>
  <div v-if="ready" class="v-spacing-2 ph-2">
    <div
      v-for="{ icon, text } in summary"
      :key="text"
      class="d-flex-r d-flex-a-c h-spacing-3"
    >
      <icon>{{ icon }}</icon> <t sm medium>{{ text }}</t>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { asVideoTime } from '@/components/the-big-ones/Player/Controls/filters';

export default {
  computed: {
    ...mapState('download', {
      service: state => state.payload.service,
      dateRange: state => state.payload.dateRange,
      playerState: state => state.payload.playerState,

      type: state => state.video.payload.type,

      date: state => state.video.payload.date,
      timeRange: state =>
        _.mapValues(state.video.payload.timeRange, minutes =>
          moment.duration(minutes, 'minutes')
        ),

      quality: state => state.video.payload.quality,
      playbackSpeed: state => state.video.payload.playbackSpeed,
    }),
    ...mapGetters('collections', ['getProjectById']),

    project() {
      const { service } = this;

      return !_.isNil(service) ? this.getProjectById(service.projectId) : null;
    },

    time() {
      const { dateRange, timeRange } = this;
      const { asDate, asTime } = this.$time.format;

      return this.type === 0
        ? `${asDate(dateRange.from)} / ${asDate(dateRange.to)}`
        : `${asDate(this.date)} / ${asTime(timeRange[0])} - ${asTime(
            timeRange[1]
          )}`;
    },

    metadata() {
      const duration =
        this.type === 0
          ? this.playerState.duration
          : this.timeRange[1].asMinutes() - this.timeRange[0].asMinutes();

      return `
        ${asVideoTime(duration)},
        ${this.quality}p,
        ${this.playbackSpeed}x
      `;
    },

    summary() {
      return [
        { icon: 'map-marker', text: this.project.name },
        { icon: 'video', text: this.service.name },
        { icon: 'calendar', text: this.time },
        { icon: 'info', text: this.metadata },
      ];
    },

    ready() {
      return !_.isNil(this.project);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
