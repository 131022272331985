import {
  buildBooleanPropsFromSuffixes,
  buildClassNameFromProperty,
  getSuffixesFromTokens,
} from './helpers';

const PROPERTIES = Object.freeze({
  display: {
    prefix: {
      inline: 'd-inline',
      block: 'd-block',
      ['inline-block']: 'd-inline-block',
    },
    suffixes: ['inline', 'block', 'inline-block'],
    override: true,
  },
  fontFamily: {
    prefix: 'f-family-',
    suffixes: getSuffixesFromTokens(/f_family_(.*)/g),
    override: true,
  },
  fontSize: {
    prefix: 'f-size-',
    suffixes: getSuffixesFromTokens(/f_size_(.*)/g),
    override: true,
  },
  fontStyle: {
    prefix: {
      b: 'f-style-bold',
      i: 'f-style-italic',
      u: 'f-style-underline',
    },
    suffixes: ['b', 'i', 'u'],
  },
  fontWeight: {
    prefix: 'f-weight-',
    suffixes: getSuffixesFromTokens(/f_weight_(.*)/g),
    override: true,
  },
  textAlign: {
    prefix: 't-align-',
    suffixes: ['left', 'center', 'right'],
    override: true,
  },
  textTransform: {
    prefix: 't-transform-',
    suffixes: ['lowercase', 'capitalize', 'uppercase'],
    override: true,
  },
});

export default {
  props: {
    ...buildBooleanPropsFromSuffixes(PROPERTIES.display.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.fontFamily.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.fontSize.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.fontStyle.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.fontWeight.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.textAlign.suffixes),
    ...buildBooleanPropsFromSuffixes(PROPERTIES.textTransform.suffixes),

    noWrap: { type: Boolean, default: false },
  },
  computed: {
    withTextStyle() {
      return this.$options.buildStyle(this.$props);
    },
    withTextClasses() {
      return this.$options.buildClassName(this.$props);
    },
  },

  buildStyle(props) {
    return Object.assign({}, props.noWrap && { 'white-space': 'nowrap' });
  },

  buildClassName(props) {
    return Object.keys(PROPERTIES).map(property =>
      buildClassNameFromProperty(props, property, PROPERTIES)
    );
  },
};
