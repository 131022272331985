var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LeftDrawer',{staticClass:"download-drawer",attrs:{"is-open":_vm.isOpen},on:{"close":_vm.close},scopedSlots:_vm._u([(_vm.ready)?{key:"header",fn:function(){return [_c('t',{attrs:{"semibold":""}},[_vm._v(_vm._s(_vm.$t(((_vm.$options.path) + ".title"))))])]},proxy:true}:null,(_vm.ready)?{key:"default",fn:function(){return [_c('div',{staticClass:"download-drawer__body"},[_c('Tabs',{staticClass:"download-drawer__header",model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}},_vm._l((_vm.options),function(ref){
var icon = ref.icon;
var text = ref.text;
return _c('Tab',{key:text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('icon',{staticClass:"mr-2",attrs:{"color":isActive ? 'primary' : 'gray-2'}},[_vm._v(" "+_vm._s(icon)+" ")]),_c('t',{attrs:{"color":isActive ? 'primary' : 'gray-2',"semibold":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true)})}),1),_c('TabsBodies',{staticClass:"download-drawer__body-body",model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}},_vm._l((_vm.options),function(ref){
var text = ref.text;
var component = ref.component;
return _c('TabBody',{key:text},[(_vm.isOpen)?_c(component,{tag:"component",on:{"retry":_vm.onRetry,"cancel":_vm.onCancel}}):_vm._e()],1)}),1),_c('div',{staticClass:"download-drawer__footer pv-4 ph-3"},[(_vm.option === 0)?[_c('DownloadDrawerVideoSummary'),_c('EButton',{staticClass:"fluid mt-4",attrs:{"filled":"","lg":"","disabled":!_vm.canDownloadVideo || _vm.status.video === 'pending'},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.path) + ".action.generate")))+" ")])]:[_c('DownloadDrawerVideoImageSummary'),_c('EButton',{staticClass:"fluid mt-4",attrs:{"filled":"","lg":"","disabled":!_vm.canDownloadVideoImage || _vm.status.videoImage === 'pending'},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.path) + ".action.download")))+" ")])]],2)],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }