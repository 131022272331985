// START - process -------------------------------------------------------------
declare global {
  type Process =
    | 'default'
    | 'heatmap'
    | 'original'
    | 'timelapse'
    | 'withoutDetections';
}

export const REMOTE_PROCESS_TO_PROCESS_MAPPING: Record<
  RemoteProcess,
  Process
> = Object.freeze({
  DEFAULT: 'timelapse',

  HEATMAP: 'heatmap',
  VIDEOGEN_NO_MARKS: 'withoutDetections',
  VIDEO_FULL: 'original',
  VIDEO_GENERATION: 'timelapse',
});

export const asProcess = (remoteProcess: RemoteProcess) =>
  REMOTE_PROCESS_TO_PROCESS_MAPPING[remoteProcess];
// END - process ---------------------------------------------------------------

// START - remote process ------------------------------------------------------
declare global {
  export type RemoteProcess =
    | 'DEFAULT'
    | 'HEATMAP'
    | 'VIDEOGEN_NO_MARKS'
    | 'VIDEO_FULL'
    | 'VIDEO_GENERATION';
}

export const PROCESS_TO_REMOTE_PROCESS_MAPPING: Record<
  Process,
  RemoteProcess
> = Object.freeze({
  default: 'VIDEO_GENERATION',

  heatmap: 'HEATMAP',
  original: 'VIDEO_FULL',
  timelapse: 'VIDEO_GENERATION',
  withoutDetections: 'VIDEOGEN_NO_MARKS',
});

export const asRemoteProcess = (process: Process) =>
  PROCESS_TO_REMOTE_PROCESS_MAPPING[process];
// END - remote process --------------------------------------------------------

// FIX: remove alias
export const PROCESSES = PROCESS_TO_REMOTE_PROCESS_MAPPING;
