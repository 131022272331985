import { DEFAULT_CENTER } from '@/store/modules/map/helpers';

export const buildAveragePosition = positions => {
  if (positions.length === 0) return DEFAULT_CENTER;
  if (positions.length === 1) return positions[0];

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (const position of positions) {
    const lat = (position.lat * Math.PI) / 180;
    const lng = (position.lng * Math.PI) / 180;

    x += Math.cos(lat) * Math.cos(lng);
    y += Math.cos(lat) * Math.sin(lng);
    z += Math.sin(lat);
  }

  const total = positions.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLongitude = Math.atan2(y, x);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI,
  };
};

export const buildNullPosition = () => ({ lat: null, lng: null });

export const isValidPosition = position => {
  if (!position) return false;

  const { lat, lng } = position;

  return _.isNumber(lat) && _.isNumber(lng);
};
